import {useEffect, useState} from "react";
import classes from "./Progressbars.module.css"

export const SmallProgressbar = (props) => {

    const [width, setWidth] = useState("0%")
    const [transition, setTransition] = useState("none")

    const animate = () => {
        setTransition("none")
        setWidth("0%")
        setTimeout(() => {
            setTransition(".3s ease")
            setWidth(`${props.value}%`)
        }, props.delay)
    }

    useEffect(() => {
        animate()
    }, [props.shouldAnimate])

    return (
        <div className={classes.smallProgressbar}>
            <div className={classes.smallProgressbarValue} style={{width: width, backgroundColor: props.color, transition: transition}}></div>
        </div>
    )
}