import {useEffect, useState} from "react";
import {Switch} from "./Switch";
import classes from "./Switch.module.css"

export const LabeledSwitch = (props) => {

    const [isChecked, setIsChecked] = useState(props.isChecked);

    useEffect(() => {
        setIsChecked(props.isChecked)
    }, [props.isChecked]);

    return (
        <div className={classes.labeledSwitchContainer}
             onClick={() => {
                 setIsChecked(!isChecked)
                 props.onChange()
             }}
             style={{backgroundColor: isChecked ? "rgba(253, 174, 71, 0.2)" : "rgba(80, 87, 96, 0.3)"}}>
            <span style={{color: isChecked ? "#FDAE47" : "#FFFFFF", transition: ".3s"}}>
                {props.title}
            </span>
            <Switch
                onChange={() => {
                    setIsChecked(!isChecked)
                    props.onChange()
                }}
                isChecked={isChecked}/>
        </div>
    )
}