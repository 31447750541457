import React, {memo, useContext, useEffect, useState} from 'react';
import classes from "./SupervisorDashboards.module.css"
import {FilterContext, UiContext, UserContext} from "../../../../context";
import SortingSelect from "../../select/SortingSelect";
import {useSelector} from "react-redux";
import {selectUserData} from "../../../../features/auth/authSlice";
import ListConversionBars from "../../progressbars/ListConversionBars";
import {useQuery} from "@apollo/client";
import {CORE_GET_COURSES_SUMMARY} from "../../../../graphql/courses";
import {formatDate} from "../../../../utils/helpers";
import {CORE_GET_MODULES_SUMMARY} from "../../../../graphql/modules";
import {CORE_GET_MACHINES_SUMMARY} from "../../../../graphql/machines";
import {Center} from "../../Center";
import {RatingDashboard} from "./RatingDashboard";

const TopInfo = () => {

    const {ui, setUi} = useContext(UiContext)
    const {filters} = useContext(FilterContext)
    const {user, setUser} = useContext(UserContext)
    const [sort, setSort] = useState("completed");

    const [data, setData] = useState({"courses": [], "modules": [], "machines": []});
    const [viewingData, setViewingData] = useState([]);


    const ratingSortingFields = [
        {id: 0, title: "Топ сотрудников", default: true, onClick: () => setUi({...ui, mode: "employees"})},
        user.role === "admin" && {id: 1, title: "Топ филиалов", default: false, onClick: () => setUi({...ui, mode: "branches"})},
    ]

    const dynamicSortingFields = [
        {id: 0, title: "По сумме", default: false, field: "sum"},
        {id: 1, title: "По пройденным", default: false, field: "completed"},
        {id: 2, title: "По не пройденным", default: false, field: "uncompleted"},
    ]

    const handleSortChange = (sort) => {
        setSort(sort)
    }

    const courses = useQuery(CORE_GET_COURSES_SUMMARY, {
        variables: {
            dateBegin: formatDate(filters.dateRange.beginDate),
            dateEnd: formatDate(filters.dateRange.endDate),
            branches: filters.branches.map(branch => branch.id)
        },
        skip: ui.units !== "courses",
        onCompleted: (d) => {
            if (d !== undefined) {
                setData(prevData => ({...prevData, "courses": d.courseSummary}))
            }
        },
    });

    const modules = useQuery(CORE_GET_MODULES_SUMMARY, {
        variables: {
            dateBegin: formatDate(filters.dateRange.beginDate),
            dateEnd: formatDate(filters.dateRange.endDate),
            branches: filters.branches.map(branch => branch.id),
            courses: filters.courses && filters.courses.map(course => parseInt(course.id))
        },
        skip: ui.units !== "modules",
        onCompleted: (d) => {
            if (d !== undefined) {
                setData(prevData => ({...prevData, "modules": d.moduleSummary}))
            }
        },
    });

    const machines = useQuery(CORE_GET_MACHINES_SUMMARY, {
        variables: {
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
            dateBegin: formatDate(filters.dateRange.beginDate),
            dateEnd: formatDate(filters.dateRange.endDate),
            branches: filters.branches.map(branch => branch.id)
        },
        skip: ui.units !== "machines",
        onCompleted: (d) => {
            if (d !== undefined) {
                setData(prevData => ({...prevData, "machines": d.machineSummary}))
            }
        },
    });

    useEffect(() => {
        if (ui.units === "courses") {
            setViewingData(data["courses"])
        } else if (ui.units === "modules") {
            setViewingData(data["modules"])
        } else if (ui.units === "machines") {
            setViewingData(data["machines"])
        }
    }, [data, ui.units]);

    if (courses.error) return <Center>{`Courses error! ${courses.error}`}</Center>;
    if (modules.error) return <Center>{`Modules error! ${modules.error}`}</Center>;
    if (machines.error) return <Center>{`Machines error! ${machines.error}`}</Center>;

    return (
        <div className={classes.infoContainer} style={{height: ui.bottomInfoFolded ? "97%" : `calc(50% - 6px)`}}>
            <div className={classes.opacityContainer} style={{width: "100%", height: "100%", overflow: "hidden"}}>
                <div className={"p-3 h-100 w-100"}>

                    {/*Поля сортировки*/}
                    <div style={{height: "5%"}}>
                        <SortingSelect sortingFields={ui.dashboardType === "rating" ? ratingSortingFields : dynamicSortingFields} handleSortChange={handleSortChange}/>
                    </div>

                    {/*Тело*/}
                    <div className="mt-3" style={{overflowY: "auto", overflowX: "hidden", height: "95%"}}>
                        {
                            <Center>
                                {ui.dashboardType === "dynamic" ?
                                    <ListConversionBars
                                        conversions={viewingData}
                                        selectedSort={sort}
                                        loading={ui.units == "modules" && modules.loading || ui.units == "courses" && courses.loading || ui.units == "machines" && machines.loading}
                                    />
                                    :
                                    <RatingDashboard/>}
                            </Center>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

};

export default memo(TopInfo);