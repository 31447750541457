import React, {useContext, useEffect, useState} from 'react';
import classes from "./Progressbars.module.css"
import {useAnimation} from "../../../hooks/useAnimation";
import {FilterContext, UiContext} from "../../../context";
import {useNumberAnimation} from "../../../hooks/useNumberAnimation";
import login from "../../../pages/Login";

const ConversionBar = (props) => {

    const {ui} = useContext(UiContext)
    const {scale} = useAnimation(0);
    const {filters} = useContext(FilterContext);
    const getPercentage = val => (val / props.maxSum) * 100 + "%";

    const isActive = () => {
        if (ui.units === "courses")
            return (filters.selectedCourse && filters.selectedCourse.id === props.id)
        else if (ui.units === "modules")
            return (filters.selectedModule && filters.selectedModule.id === props.id)
        else if (ui.units === "machines")
            return (filters.selectedMachine && filters.selectedMachine.id === props.id)
    }


    const [completedWidth, setCompletedWidth] = useState("0")
    const [uncompletedWidth, setUncompletedWidth] = useState("0")

    useEffect(() => {
        setCompletedWidth("0")
        setUncompletedWidth("0")
        setTimeout(() => {
            setCompletedWidth(getPercentage(props.completed))
            setUncompletedWidth(getPercentage(props.uncompleted))
        }, 1)

    }, [props.conversions, props.completed, props.uncompleted, ui.units])

    return (
        <div
            className={
                isActive() && filters.showGreen === true ? [classes.conversionBarContainer, classes.active, classes.good].join(" ")
                    :
                    isActive() && filters.showGreen === false ? [classes.conversionBarContainer, classes.active, classes.bad].join(" ")
                        : classes.conversionBarContainer
            }
            style={{opacity: scale, transition: `.12s `}}>
            <span className="col-3">{props.title}</span>
            <div className="col h-100">
                <div className={"d-flex position-relative"}>
                    {props.completed !== 0 &&
                        <div
                            onClick={() => props.onSelectBar(props.conversion, true)}
                            className={[classes.goodConversionBar, classes.conversionBar].join(" ")}
                            style={{width: completedWidth, borderRadius: filters.showUnfinished ? "10px 0 0 10px" : "10px"}}>{props.completed}</div>}
                    {props.uncompleted !== 0 &&
                        <div
                            onClick={() => { props.onSelectBar(props.conversion, false)}
                        }
                            className={[classes.badConversionBar, classes.conversionBar].join(" ")}
                            style={{width: uncompletedWidth, left: completedWidth, opacity: filters.showUnfinished ? 1 : 0}}>{props.uncompleted}</div>}
                </div>
            </div>
        </div>
    );
};

export default ConversionBar;