import React from 'react';
import classes from "./Tables.module.css";

const MachineCoefficientProgress = ({progress}) => {

    return (
        <div className={classes.machineProgressContainer} style={{
            backgroundColor: progress.completed ? "#8CC06D55" : "transparent",
        }}>
            <span className={classes.machinesValueX}>{progress.detail && "×"}</span>
            <span
                className={classes.machinesCoefficientValue}
                style={{color: progress.completed ? "#8CC06D" : "#9C9FA2"}}
            >
                {progress.detail}
            </span>
        </div>
    );
};

export default React.memo(MachineCoefficientProgress);