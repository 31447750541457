import classes from "./Lists.module.css";
import {LeaderboardItem} from "../cards/LeaderboardItem";
import EmployeesView from "../dataview/EmployeesView";
import {useContext} from "react";
import {UiContext} from "../../../context";

export const LeaderboardItemsList = ({leaderboards}) => {

    const {ui} = useContext(UiContext);

    // Сортировка элементов по полю 'place' в порядке 2, 1, 3
    const sortedLeaderboards = leaderboards.sort((a, b) => {
        const order = [2, 1, 3];
        return order.indexOf(a.place) - order.indexOf(b.place);
    });

    return (
        <div className={classes.leaderboard}>
            {sortedLeaderboards.map((leaderboard) => {
                return <LeaderboardItem key={leaderboard.place} {...leaderboard}
                                        title={ui.mode === "employees" ? leaderboard.employee?.lastname + " " + leaderboard.employee?.firstname : leaderboard.branch.name}
                                        delay={leaderboard.place * 300}
                                        item={ui.mode !== "employees" &&
                                            <EmployeesView branchSize={leaderboard.branchSize} branch={leaderboard.branch} unitsCompleted={leaderboard.value} unitsTotal={leaderboard.totalValue} showTitle={false}
                                                           showPlace={false}/>
                                        }
                />
            })}
        </div>
    );
};
