import React, {memo, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../hooks/useFetch";
import Loader from "../../components/UI/loader/Loader";
import {fetchAdminData, fetchSupervisorData} from "../../features/_helpers_/commands";
import {selectCategories, selectCourses, selectModules} from "../../features/educationUnits/unitsSlice";
import {selectCoursesCompletions, selectModulesCompletions} from "../../features/completions/completionsSlice";
import {selectBranches, selectUsers} from "../../features/users/usersSlice";
import SupervisorTable from "../../components/UI/table/SupervisorTable";
import {Center} from "../../components/UI/Center";
import {UserContext} from "../../context";


const SupervisorUnitsProgress = () => {

    const {user} = useContext(UserContext)

    //категории
    const categories = useSelector(selectCategories)

    //модули, отображаемые в таблице
    const modules = useSelector(selectModules)
    const modulesCompletions = useSelector(selectModulesCompletions)

    //курсы, отображаемые в таблице
    const courses = useSelector(selectCourses)
    const coursesCompletions = useSelector(selectCoursesCompletions)

    // const [brands, setBrands] = useState([])
    const [types, setTypes] = useState([])
    const [models, setModels] = useState([])

    const users = useSelector(selectUsers)

    const branches = useSelector(selectBranches)

    const dispatch = useDispatch()

    const [fetchData, isLoading] = useFetch(async () => {
        user.role === 'supervisor'
            ? await fetchSupervisorData(dispatch)
            : await fetchAdminData(dispatch)
    })

    useEffect(() => {
        fetchData()
    }, [])

    const data = {
        users: {users: users, branches: branches},
        machines: {models: models, types: types},
        completions: {modules: modulesCompletions, courses: coursesCompletions},
        units: {modules: modules, courses: courses, categories: categories}
    }

    return (
        <div className={"text-light d-flex flex-column justify-content-center align-items-center"} style={{height: "90%"}}>
            <div className={"w-100 h-100 overflow-hidden"}>
                {
                    isLoading
                        ? <Center><Loader/></Center>
                        : <SupervisorTable data={data}/>
                }
            </div>
        </div>

    );
};

export default memo(SupervisorUnitsProgress);