import React, {useContext, useEffect, useState} from 'react';
import VerticalProgressbar from "../progressbars/VerticalProgressbar";
import {FilterContext, UiContext} from "../../../context";

const ListVerticalProgressbars = (props) => {
    // const delayEach = 2000 / props.bars.length
    const delayEach = 200

    const {ui} = useContext(UiContext)
    const {filters, setFilters} = useContext(FilterContext);
    const [selectedBar, setSelectedBar] = useState(props.bars.length !== 0 ? props.bars[0] : null);

    function findMinAndMaxValues(data) {
        let minValue = Infinity
        let maxValue = -Infinity

        for (let i = 0; i < data.length; i++) {
            const value = data[i].value;
            if (value < minValue) {
                minValue = value;
            }
            if (value > maxValue) {
                maxValue = value;
            }
        }

        return {minValue, maxValue};
    }

    const {minValue, maxValue} = findMinAndMaxValues(props.bars);

    useEffect(() => {
        setFilters(prev => ({...prev, selectedBar: selectedBar}))
    }, [selectedBar]);

    const headerClassname = `w-100 d-flex justify-content-center h6 ${filters.showGreen && filters.showGreen === true ? "text-success" : "text-danger"}`

    return (
        props.bars.length !== 0 ?
            <div className={"w-100 h-100 d-flex flex-column"}>
                {filters.selectedCourse !== null && <div className={headerClassname}>{ui.units === "courses" && filters.selectedCourse.name}</div>}
                {filters.selectedModule !== null && <div className={headerClassname}>{ui.units === "modules" && filters.selectedModule.name}</div>}
                {filters.selectedMachine !== null && <div className={headerClassname}>{ui.units === "machines" && filters.selectedMachine.name}</div>}
                {filters.showGreen && <div className={"d-flex flex-row justify-content-center flex-grow-1"}>
                    {props.bars.map((bar, index) => <VerticalProgressbar
                        id={bar.id}
                        key={bar.id}
                        onClick={() => setSelectedBar(bar)}
                        value={bar.value}
                        totalValue={maxValue}
                        delay={(index + 1) * delayEach}
                        label={bar.label}
                        color={bar.value === maxValue ? "#FDAE47" : bar.value === minValue ? "#DD5757" : "#8CC06D"}
                    />)}
                </div>}
            </div> : ""
    );
};

export default ListVerticalProgressbars;