import React, {useEffect, useState} from 'react';
import {CORE_GET_COURSES_EMPLOYEES_DETAIL} from '../graphql/courses'
import {useQuery} from "@apollo/client";


const TestGraphql = () => {
    const { loading, error, data } = useQuery(CORE_GET_COURSES_EMPLOYEES_DETAIL, {
        variables: {
            course: 3,
            dateBegin: "2022-12-01",
            dateEnd: "2023-01-01",
            // branches: [31, 53, 72]
        },
        fetchPolicy: 'cache-and-network',
    });

    if (loading) return null;
    if (error) return `Error! ${error}`;

    return (
        <div>
            <ul>
                {data.courseEmployees.map(emp =>
                    <li key={emp.lastname} style={{color: 'white', fontSize: '20px'}}>{emp.id}: {emp.firstname} {emp.lastname}</li>
                )}
            </ul>
        </div>
    );
};

export default TestGraphql;