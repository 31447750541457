import React, {useEffect, useState} from 'react';
import classes from "./Cards.module.css"
import 'react-tooltip/dist/react-tooltip.css'
import {RateSelect} from "../select/RateSelect";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {getProgressbarColor, getRatePercent} from "../../../utils/helpers";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {EmployeeModuleCompletion} from "./EmployeeModuleCompletion";
import {EmployeeCoursesCompletion} from "../accordion/EmployeeCoursesCompletion";

const MachineCard = ({machine, delay}) => {


    const [opacity, setOpacity] = useState(0);

    let rate = machine.rates.find(r => r.rate === machine.currentRate)

    //выбранный рейт (изначально сравнивается с текущим)
    const [selectedRate, setSelectedRate] = useState(rate !== undefined ? rate : {rate: machine.currentRate});

    //отображаемый модуль для выбранного рейта
    const [moduleDisplaying, setModuleDisplaying] = useState(machine.rates.find(r => r.rate === machine.currentRate)?.moduleCompletion);

    //курсы для выбранного рейта
    const [coursesDisplaying, setCoursesDisplaying] = useState(machine.rates.find(r => r.rate === machine.currentRate)?.coursesCompletions);

    //процент для отображения на странице
    const [ratePercent, setRatePercent] = useState(getRatePercent(machine.rates, selectedRate))
    const [color, setColor] = useState(getProgressbarColor(ratePercent + 30, false))
    const [isOpened, setIsOpened] = useState(false)
    const [showCurrentRate, setShowCurrentRate] = useState(machine.currentRate !== selectedRate?.rate);


    useEffect(() => {
        setCoursesDisplaying(selectedRate?.coursesCompletions);
        setShowCurrentRate(machine.currentRate !== selectedRate?.rate)
        // Используйте функции обратного вызова для установки состояния ratePercent и prevColor
        setRatePercent(prevPercent => {
            const newRatePercent = getRatePercent(machine.rates, selectedRate);
            setColor(prevColor => getProgressbarColor(newRatePercent + 30, false));
            setModuleDisplaying(prevModule => selectedRate?.moduleCompletion);
            return newRatePercent;
        });
    }, [selectedRate]);


    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, delay)
    }, []);

    function getCoursesLength() {
        switch (coursesDisplaying?.length) {
            case 0 || undefined:
                return '';
                break;
            case 1:
                return ', 1 курс';
                break;
            case 2 || 3 || 4:
                return `, ${coursesDisplaying?.length} курса`;
                break;
            default:
                return `, ${coursesDisplaying?.length} курсов`;
                break;
        }
    }

    return (
        <motion.div  transition={{
            opacity: { ease: "linear" },
            layout: { duration: 0.3 }
        }} layout className={classes.machineCard} style={{opacity: opacity}} onClick={() => setIsOpened(!isOpened)}>
            <div className={classes.machineTitle}>
                {/*<span className={classes.machineProducer}>{machine.machineType.producer.name}</span>*/}
                <span className={classes.machineType}>{machine.machineType.name}</span>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
                <RateSelect
                    ratesId={machine.id}
                    rates={machine.rates.map((rate, index) => { return {...rate, id: index}})}
                    currentRateValue={machine.currentRate}
                    onChange={setSelectedRate}
                />
            </div>
            {/*<Speedometer rate={selectedRate}></Speedometer>*/}
            <div className={classes.speedometerContainer}>
                <CircularProgressbar
                    value={ratePercent + 10}
                    circleRatio={.5}
                    className={classes.speedometer}
                    counterClockwise={false}
                    style={{boxShadow: `0 0 100px ${color}`}}
                    strokeWidth={6}
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0.75,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'round',
                        textColor: color,
                        textSize: '16px',
                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,
                        // Colors
                        pathColor: color,
                        trailColor: '#7A8490',
                        backgroundColor: '#7A8490',
                    })}
                />
                <span
                    style={{bottom: showCurrentRate ? "1rem" : "0"}}
                    className={classes.speedometerValue}>
                    x
                    {selectedRate ? selectedRate.rate.toString().slice(0, 2) : 1}
                    <span
                        style={{color: color}}
                        className={classes.speedometerCoefficient}>
                        {selectedRate ? selectedRate.rate.toString().slice(2) : ""}
                    </span>
                </span>
                <span
                    style={{opacity: showCurrentRate ? 1 : 0}}
                    className={classes.currentValue}>
                        Текущая ставка: x{machine.currentRate}
                    </span>
            </div>

            <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "0"}} onClick={(e) => e.stopPropagation()}>{
                <button className={classes.machineCardFoldButton} onClick={() => setIsOpened(!isOpened)}>
                    <FontAwesomeIcon icon={isOpened ? faChevronUp : faChevronDown}/>
                    <span>{isOpened ? "Свернуть" : selectedRate === undefined ? `Для отображения выберите ставку` : `1 модуль${getCoursesLength()}`}</span>
                </button>}
                {
                    isOpened &&
                    <motion.div className={classes.machineCardUnits}>
                        {selectedRate !== undefined ?
                            <EmployeeModuleCompletion moduleCompletion={moduleDisplaying}
                            /> : <span>Выберите ставку</span>
                        }
                        {coursesDisplaying && <EmployeeCoursesCompletion
                            coursesCompletions={coursesDisplaying} machine={machine}
                        />}
                    </motion.div>
                }</div>
        </motion.div>

    );
};

export default MachineCard;