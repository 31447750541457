import React, {useEffect, useState} from 'react';
import classes from "./Tables.module.css";

const CourseProgress = ({courseCompletion}) => {

    const [opacity, setOpacity] = useState("0")

    useEffect(() => {
        setOpacity("1")
    }, [])

    const getClass = () => {
        if (courseCompletion === undefined) return classes.emptyAward;
        else if (courseCompletion.completed === true) return classes.award;
        else if (courseCompletion.completed === false) return classes.awardInProgress;
        else return classes.emptyAward;
    }

    return (
        <div className={[getClass(), classes.courseProgress].join(" ")} style={{opacity: opacity}}>
            {courseCompletion.detail !== 0 && <span>
                    {courseCompletion.detail && Intl.NumberFormat("ru", {style: "currency", currency: "RUB", signDisplay: "exceptZero", maximumFractionDigits: 0}).format(parseInt(courseCompletion.detail))}
                </span>}
        </div>
    );
};

export default React.memo(CourseProgress);