import Login from "../pages/Login";
import EmployeeMachinesProgress from "../pages/employee/EmployeeMachinesProgress";
import EmployeeUnitsProgress from "../pages/employee/EmployeeUnitsProgress";
import SupervisorUnitsProgress from "../pages/supervisor/SupervisorUnitsProgress";
import SupervisorDashboards from "../pages/supervisor/SupervisorDashboards";
import TestGraphql from "../pages/TestGraphql";

export const employeeRoutes = [
    {path: '/machines', component: EmployeeMachinesProgress, exact: true},
    {path: '/categories', component: EmployeeUnitsProgress, exact: true}
]

export const supervisorRoutes = [
    {path: '/units', component: SupervisorUnitsProgress, exact: true},
    {path: '/dashboards', component: SupervisorDashboards, exact: true},
    {path: '/testgraph', component: TestGraphql, exact: true}
]

export const publicRoutes = [
    {path: '/login', component: Login, exact: true},
    {path: '/testgraph', component: TestGraphql, exact: true}
]
