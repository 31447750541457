import React, {useEffect, useState} from 'react';
import classes from "./Accordion.module.css"
import {faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion"

const Accordion = ({ header, body, handleExpand, className, isExpandable, isExpanded, style, borderRadius }) => {
    const [expanded, setExpanded] = useState(isExpanded);

    const [position, setPosition] = useState("absolute");

    const handleToggle = () => {
        setExpanded(!expanded);
        if (handleExpand) handleExpand()
    };

    useEffect(() => {
        setExpanded(isExpanded)
        // handleToggle()
    }, [isExpanded]);

    useEffect(() => {
        if (expanded) setPosition("relative")
        else
            setTimeout(()=>{
                setPosition("absolute")
            }, 150)
    }, [expanded]);
    return (
        <motion.div layout={"position"} className={[classes.accordion, className].join(" ")} style={style && style}>
            <motion.div className={classes.accordionHeader} onClick={handleToggle}>
                <div className={classes.accordionTitle}>
                    {header}
                </div>
                {isExpandable && <button className={classes.accordionToggleBtn} onClick={handleToggle}>
                    {expanded ? 'Скрыть' : 'Развернуть'}
                    <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className={"ms-2"} color={"#FDAE47"}/>
                </button>}
            </motion.div>
            <motion.div style={{position: `${position}`}} className={`${classes.accordionBody} ${expanded ? classes.expanded : ''}`}>
                {body}
            </motion.div>
        </motion.div>
    );
};

export default Accordion;
