import React, {useContext, useEffect, useState} from 'react';
import classes from "./Employee.module.css"
import ListSelect from "../../components/UI/select/ListSelect";
import MachineCard from "../../components/UI/cards/MachineCard";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../../components/UI/loader/Loader";
import {useQuery} from "@apollo/client";
import {GET_MACHINE_COMPLETIONS_DETAIL, GET_MACHINE_TYPES} from "../../graphql/machines";
import {FilterContext, UserContext} from "../../context";
import {Center} from "../../components/UI/Center";
import {MachinesModal} from "../../components/UI/modal/MachinesModal";
import {getTypes} from "../../utils/helpers";
import {Button} from "antd";
import buttons from "../../components/UI/button/Buttons.module.css";

const EmployeeMachinesProgress = () => {

    const {filters, setFilters} = useContext(FilterContext);
    const [filtersState, setFiltersState] = useState(filters);
    const [hasChanges, setHasChanges] = useState(false);

    const {user} = useContext(UserContext)

    //состояние отображения модалки и хэндлеры для неё
    const [showFilterModal, setShowFilterModal] = useState(false);
    const handleShow = () => setShowFilterModal(true);

    const handleTypesChange = (types) => {
        setHasChanges(true)
        setFiltersState(prevState => ({...filtersState, selectedTypes: types}))
    }

    // const handleProducersChange = (brands) => {
    //     setHasChanges(true)
    //     setFiltersState(prevState => ({...filtersState, selectedBrands: brands}))
    // }

    const machinesCompletions = useQuery(GET_MACHINE_COMPLETIONS_DETAIL, {
        variables: {
            employee: user.id,
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
            // producers: filters.selectedBrands?.map(producer => parseInt(producer.id)),
        },
        onCompleted: (d) => {
        },
        fetchPolicy: 'network-only',
    });

    const handleMachinesChange = (newState) => {
        setFiltersState(prev => newState)
    }

    const applyFilters = () => {
        setShowFilterModal(false)
        setHasChanges(false)
        setFilters(prev => filtersState)
    }

    const [types, setTypes] = useState([])
    const [pairs, setPairs] = useState([]);

    const data = useQuery(GET_MACHINE_TYPES, {
        fetchPolicy: 'network-only',
        onCompleted: (d) => {
            setTypes(d.machineSummary)
        }
    })

    return (
        <div className={"d-flex flex-wrap position-absolute h-90 w-100 justify-content-center"}
             style={{flex: "1 1 auto"}}>
            <div className="row h-100 w-100 p-0 px-1">
                {/*Фильтры*/}
                <div className="col-3 flex-column h-100 p-0 m-0 d-none d-lg-flex gap-3">
                    <Button
                        block
                        onClick={applyFilters}
                        className={hasChanges ? [buttons.applyFiltersBtn, buttons.active].join(" ") : buttons.applyFiltersBtn}
                        type={"primary"}>
                        Применить
                    </Button>
                    <div className={classes.containerWrapper} style={{height: "60%"}}>
                        <div className={classes.machinesTypeFilter__container}>
                            <ListSelect
                                onItemsChange={handleTypesChange}
                                searchPlaceholderText={"Поиск по типу техники..."}
                                items={types ?? []}
                            />
                        </div>
                    </div>
                    {/*<div className={classes.containerWrapper} style={{height: "30%"}}>*/}
                    {/*    <div className={classes.machinesBrandFilter__container}>*/}
                    {/*        {pairs.loading ? <Loader/> :*/}
                    {/*            <ListSelect*/}
                    {/*                onItemsChange={handleProducersChange}*/}
                    {/*                searchPlaceholderText={"Поиск по производителю..."}*/}
                    {/*                items={brands === undefined ? [] : brands}/>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*Контент*/}
                <div className={[classes.content, "col-lg col-12 h-100 p-0 mx-0 ms-lg-2"].join(" ")}>
                    <div className={[classes.containerWrapper, "ps-0"].join(" ")}>
                        <div className={classes.machinesContent__container}>
                            <div
                                className="mx-2 row w-100 justify-content-lg-end justify-content-between align-items-center py-1 px-2 position-sticky top-0"
                                style={{
                                    zIndex: 15,
                                    borderRadius: "10px",
                                    background: "#505760",
                                    height: "46px"
                                    // backdropFilter: "blur(10px)"
                                }}>
                                <button onClick={handleShow} className={"btn text-light col-auto d-lg-none d-block"}>
                                    <FontAwesomeIcon icon={faList}/></button>
                                <p className={"lead pe-2 m-0 text-end col-auto h-sm-6"}>
                                    Машины: {machinesCompletions.loading || machinesCompletions.data === undefined ? 0 : machinesCompletions.data?.employeeMachinesCompletions.length}
                                </p>
                            </div>
                            {machinesCompletions.loading
                                ? <Center><Loader/></Center>
                                : machinesCompletions.error
                                    ? <Center>{machinesCompletions.error.toString()}</Center>
                                    : machinesCompletions.data?.employeeMachinesCompletions.length === 0 ?
                                        <Center>
                                            {filters.selectedTypes !== null && filters.selectedTypes?.length !== 0 && (filters.selectedTypes?.map((b, index) => index > 0 ? " " + b.name.toLowerCase() : " " + b.name)) + ": "}
                                            <span className={"text-secondary"}>Нет доступных машин</span>
                                        </Center>
                                        : machinesCompletions.data.employeeMachinesCompletions.map((machine, index) => {
                                                return <div
                                                    key={`machine-${index}`}
                                                    className={"col-xxl-4 col-md-6 col-12 p-2"}
                                                    style={{transition: `.3s ease ${index < 20 && (index + 1) * 0.1}s`}}>
                                                    <MachineCard
                                                        machineCardId={index}
                                                        machine={machine}
                                                        delay={index * 50}
                                                    />
                                                </div>
                                            }
                                        )}
                        </div>
                    </div>
                </div>

            </div>

            <MachinesModal modalActive={showFilterModal} setModalActive={setShowFilterModal}
                           onChange={handleMachinesChange} onConfirm={applyFilters}/> :
        </div>
    );
};

export default EmployeeMachinesProgress;