import React, {useEffect, useState} from 'react';
import './../../../../App.css'
import classes from "./Styles.module.css"

const CourseProgress = ({course, ...props}) => {

    const [value, setValue] = useState(0)

    useEffect(() => {
        setValue(0)
        setTimeout(() => {
            setValue(course.grade)
        }, 300)
    }, [window])

    return (
        <div {...props} className={props.selected ? "row px-0 mt-2 align-items-center course-progress bg-hover" : "row  mt-2 align-items-center course-progress bg-transparent"} style={{height: "3rem"}}>
            <div className="col-auto">
                <span className="col-auto h4 bold" style={{color: course.completed ? '#8CC06D' : '#505760'}}>
                    + {course.award.toLocaleString("de")} ₽
                </span>
            </div>
            <div className="col">
                <div className={classes.progressbarContainer}>
                    <div
                        className={classes.progressbar}
                        style={{
                            background: course.completed ? '#8CC06D' : '#7A8490',
                            width: value + '%'
                        }}>
                    </div>
                    <div
                        className={classes.courseText}>{course.course_name}</div>
                </div>
            </div>

        </div>
    );
};

export default CourseProgress;