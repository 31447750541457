import {useEffect, useState} from "react";
import {RateButton} from "../button/RateButton";
import classes from "./Select.module.css"
import {getProgressbarColor, getRatePercent} from "../../../utils/helpers";

export const RateSelect = ({ratesId, currentRateValue, rates, onChange}) => {

    const [selectedRate, setSelectedRate] = useState(rates.find(r => r.rate === currentRateValue));

    useEffect(() => {
        onChange(selectedRate)
    }, [selectedRate]);

    return (
        <div className={classes.rateSelect__container}>
            {rates?.sort((a, b) => a.rate - b.rate).map(rate =>
                <RateButton
                    key={`${ratesId}-${rate.id}-btn`}
                    color={getProgressbarColor(getRatePercent(rates, rate) + 30, false)}
                    isActive={rate.id === selectedRate?.id}
                    isDefault={rate.rate === currentRateValue}
                    rate={rate}
                    onClick={setSelectedRate}/>
            )}
        </div>
    )
}