import React, {useContext, useEffect, useState} from 'react';
import {HorizontalSelect} from "../../../select/HorizontalSelect";
import Input from "../../../input/Input";
import classes from "./../SupervisorDashboards.module.css"
import {LabeledSwitch} from "../../../switch/LabeledSwitch";
import {FilterContext} from "../../../../../context";

const AttemptsFilters = ({onChange}) => {

    const {filters} = useContext(FilterContext);

    const [filtersActive, setFiltersActive] = useState(false);
    const [filtersState, setFiltersState] = useState(filters);

    const enableFilterValues = [
        {id: 0, title: "Все", value: false},
        {id: 1, title: "Фильтр", value: true},
    ]

    const typesValues = [
        {id: 0, title: "Время"},
        {id: 1, title: "Попытки"},
    ]

    const compareValues = [
        {id: 0, title: "Больше"},
        {id: 1, title: "Меньше"},
    ]

    const handleTypeChange = (value) => {
        setFiltersState(prev => ({...prev, type: value}))
    };

    const handleCompareChange = (value) => {
        setFiltersState(prev => ({...prev, compare: value}))
    };

    const handleValueChange = (value) => {
        setFiltersState(prev => ({...prev, value: parseInt(value)}))
    };

    const handleEnableFilters = (value) => {
        if (value !== undefined) setFiltersActive(enableFilterValues[value].value)
    }

    const handleOpen = () => {
        setFiltersState(prev => ({...prev, isOpen: !filtersState.isOpen}))
    }

    const handleCompleted = () => {
        setFiltersState(prev => ({...prev, showOnlyCompleted: !filtersState.showOnlyCompleted}))
    }

    useEffect(() => {
        onChange(filtersState)
    }, [filtersState]);

    useEffect(() => {
        setFiltersState(prev => ({...prev, enableAttemptsFilter: filtersActive}))
    }, [filtersActive]);

    return (
        <>
            <div className={classes.opacityContainer} style={{height: filtersActive ? "192px" : "58px", transition: ".3s"}}>
                <div className={filtersActive ? "pt-2 px-2" : "p-2"}>
                    <HorizontalSelect
                        active={true}
                        items={enableFilterValues}
                        onChange={handleEnableFilters}
                    />
                </div>

                {
                    <div style={{transition: ".3s", opacity: `${filtersActive ? 1 : 0}`}}>
                        <div className={"pt-2 px-2"}>
                            <HorizontalSelect
                                active={filtersActive}
                                items={typesValues}
                                onChange={handleTypeChange}
                            />
                        </div>
                        <div className={"pt-2 px-2"}>
                            <HorizontalSelect
                                active={filtersActive}
                                items={compareValues}
                                onChange={handleCompareChange}
                            />
                        </div>
                        <div className={"py-2 px-2"}>
                            <Input
                                type={"number"}
                                placeholder={
                                    filtersState.type === 1 && filtersState.compare === 0 ? "Попыток больше" :
                                        filtersState.type === 1 && filtersState.compare === 1 ? "Попыток меньше" :
                                            filtersState.type === 0 && filtersState.compare === 0 ? "Времени дольше" :
                                                "Времени короче"
                                }
                                onChange={handleValueChange}/>
                        </div>
                    </div>}
            </div>
            <div className={classes.opacityContainer}>
                <LabeledSwitch title={"Открыть все"} isChecked={false} onChange={handleOpen}/>
            </div>
            <div className={classes.opacityContainer}>
                <LabeledSwitch title={"Только пройденные"} isChecked={false} onChange={handleCompleted}/>
            </div>
        </>
    );
};

export default AttemptsFilters;
