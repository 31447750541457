import {faUser, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from "./Cards.module.css"
import {Select} from "antd";
import {rolesOptions} from "../../../utils/constants";
import {UserContext} from "../../../context";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";

export const EmployeeNavbarCard = () => {

    const {user, setUser} = useContext(UserContext)
    const options = rolesOptions.filter(ro => user.groups?.includes(ro.value))
    const navigate = useNavigate()

    return (
        <div className={classes.employeeCard}>
            <div className="col-auto">
                <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "2rem"}}/>
            </div>
            <div className="col ms-4 d-flex flex-column justify-content-center">
                <span className={"bold"}>{user.name} {user.branches && <small>({user.branches.map(ub => ub.name).join(', ')})</small>}</span>
                <select
                    value={user.role}
                    disabled={options.length === 1}
                    onChange={role => {
                        setUser(prev => ({...prev, role: role.target.value}))
                        if (role === "admin" || role === "supervisor") navigate("./units")
                    }}
                    className={classes.roleSelector}
                >
                    {options.map(option => <option key={`role-option-${option.value}`} value={option.value}>{option.label}</option>)}
                </select>
            </div>
        </div>
    )
}