import React from 'react';
import CategoryItem from "./CategoryItem";
import {TotalProgress} from "./UI/dashboard/employee/TotalProgress";


const CategoryList = ({categories}) => {
    return (
            <div className='row d-flex pb-0 px-2'>
                <TotalProgress/>
                {categories.map(category =>
                    <CategoryItem category={category} key={category.id}/>
                )}
            </div>
    );
};

export default CategoryList;