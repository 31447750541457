import {getPercent} from "../../../utils/helpers";
import classes from "./Tables.module.css"
import {RatingTableRowEmployee} from "./RatingTableRowEmployee";
import {Center} from "../Center";

export const EmployeesLeaderboardTable = ({employees}) => {

    return (
        employees.length !== 0 ?
            <div className={classes.ratingTable}>
                <div className={classes.ratingTableHeader}>
                    <div className={classes.ratingTablePlace}>Место</div>
                    <div className={classes.ratingTableTitle}>Сотрудник</div>
                    <div className={classes.ratingTableTitle}>Филиал</div>
                    <div className={classes.ratingTableValue}>Пройдено</div>
                    <div className={classes.ratingTableValue}>Не пройдено</div>
                    <div className={classes.ratingTablePercents}>Прогресс</div>
                </div>
                <div className={classes.ratingTableBody}>
                    {employees.map((employee, index) => {
                        return <RatingTableRowEmployee key={`${index}-RatingTableRowEmployee-${employee.id}`} employee={employee} place={index + 1} delay={index * 20} width={getPercent(employee.value, employee.totalValue)}/>
                    })}
                </div>
            </div>
            :
            <Center><span className={"text-secondary"}>Сотрудников не найдено</span></Center>
    )
}