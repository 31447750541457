import {useEffect, useState} from "react";
import classes from "./Switch.module.css"

export const Switch = (props) => {

    const [isChecked, setIsChecked] = useState(props.isChecked ? props.isChecked : false);


    useEffect(() => {
        setIsChecked(props.isChecked)
    }, [props.isChecked]);


    return (
        <div className={isChecked ? [classes.checkedSwitchLane, classes.switchLane].join(" ") : classes.switchLane} onClick={() => {
            props.onChange(!isChecked)
            setIsChecked(!isChecked)
        }}>
            <div className={classes.switchCircle}></div>
        </div>
    )
}