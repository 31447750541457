import { useState, useEffect } from 'react';

export function useNumberAnimation(initialValue, value, animationTime) {
    const [number, setNumber] = useState(initialValue);

    useEffect(() => {
        const difference = Math.abs(value - initialValue);
        const delay = Math.floor(animationTime / difference);
        let timerId;
        if (number < value) {
            timerId = setInterval(() => {
                setNumber((prevNumber) => prevNumber + 1);
            }, delay);
        }
        return () => clearInterval(timerId);
    }, [animationTime, initialValue, number, value]);

    return number;
}
