import React, {useContext, useState} from 'react';
import EmployeeIcon from "./EmployeeIcon";
import {useNumberAnimation} from "../../../hooks/useNumberAnimation";
import classes from "./Dataview.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMedal} from "@fortawesome/free-solid-svg-icons";
import {FilterContext, UiContext} from "../../../context";
import login from "../../../pages/Login";

const EmployeesView = ({branchSize, unitsCompleted, unitsTotal, branch, showTitle, place, showPlace}) => {


    const delayOne = 1000 / branchSize
    const number = useNumberAnimation(0, unitsCompleted, 1);
    const {filters, setFilters} = useContext(FilterContext);
    const {ui, setUi} = useContext(UiContext)

    let trainedEmployeesCount, untrainedEmployeesCount;
    if (unitsTotal === 0 || branchSize === 0) {
        trainedEmployeesCount = 0;
        untrainedEmployeesCount = 0;
        // return null;
    } else {
        trainedEmployeesCount = Math.round(branchSize * (unitsCompleted / unitsTotal));
        untrainedEmployeesCount = branchSize - trainedEmployeesCount
    }
    if (isNaN(trainedEmployeesCount)) trainedEmployeesCount = 0
    if (isNaN(untrainedEmployeesCount)) untrainedEmployeesCount = 0
    const trainedEmployees = Array(trainedEmployeesCount).fill('trained');
    const untrainedEmployees = Array(untrainedEmployeesCount).fill('untrained');

    return (
        <div className={classes.employeeView}>
            <div className={"w-100 h-100 d-flex flex-wrap position-relative align-items-center justify-content-center"}>
                {showTitle &&
                    <div className={"container d-flex align-items-center justify-content-center flex-column"}>
                        {showPlace &&
                            <div className={classes.place}>
                                <FontAwesomeIcon icon={faMedal} className={classes.icon}/>
                                <span>{place}.</span>
                            </div>}
                        <div className="row bold h4 mb-0 align-items-center">
                            {branch.name}
                        </div>
                        <div className="row text-yellow">{number}/{unitsTotal}</div>
                    </div>}

                <div className="d-flex w-100">
                    <div className={"d-flex flex-wrap"} style={{gap: '.15rem'}}>
                        {trainedEmployees.map((employee, i) => {
                                return <div key={`trained-employee-${i}`} className={"d-flex align-items-start justify-content-start"}>
                                    <EmployeeIcon
                                        color={
                                            filters.selectedBranch === branch.id ? "#8CC06D" : "#d9d9d9"
                                        }
                                        delay={delayOne * i}
                                    />
                                </div>
                            }
                        )}
                        {untrainedEmployees.map((employee, i) => {
                                return <div key={`untrained-employee-${i}`} className={"d-flex align-items-start gap-1"}>
                                    <EmployeeIcon
                                        color={
                                            filters.selectedBranch === branch.id ? "#DD5757" : "rgba(255, 255, 255, 0.2)"
                                        }
                                        delay={delayOne * i}
                                    />
                                </div>
                            }
                        )}
                    </div>
                </div>
                {/*<span className={classes.employeeView__percents}>{((employees / totalEmployees) * 100).toFixed(0)}%</span>*/}
            </div>
        </div>
    );
};

export default EmployeesView;