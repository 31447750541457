import React from 'react';
import './../../../../App.css'
import {faChevronRight, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getProgressbarColor} from "../../../../utils/helpers";
import classes from "./Styles.module.css"
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {motion} from "framer-motion"
import {Tooltip} from "antd";


const ModuleProgress = ({tooltipKey, module, shouldHighlight}) => {


    const color = getProgressbarColor(module.grade, module.completed)

    const getBackground = () => {
        if (shouldHighlight) {
            if (module.completed) return "rgba(140, 192, 109, 0.30)"
            else return "rgba(221, 87, 87, 0.30)"
        }
        return null;
    }

    return (
        <div className={"col-xxl-3 col-lg-4 col-md-3 col-6 p-lg-2 p-1 align-items-center justify-content-center"}>

            <Tooltip title={shouldHighlight && "Модуль обязателен для завершения курса"}>
                <div className={"module-progress__container d-flex w-100 justify-content-center align-items-center flex-column"}
                     style={{backgroundColor: getBackground()}}>
                    {/*<CircularProgressbar  value={module.grade} completed={module.completed} circleWidth={window.innerWidth < 401 ? 100 : 250}/>*/}
                    <div className={classes.circularProgressbarContainer}>
                        <CircularProgressbar
                            className={classes.speedometer}
                            value={module.grade}
                            counterClockwise={false}
                            circleRatio={.5}
                            strokeWidth={6}
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.75,
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'round',
                                textColor: color,
                                textSize: '16px',
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,
                                // Colors
                                pathColor: color,
                                trailColor: '#7A8490',
                                backgroundColor: '#7A8490',
                            })}
                        />
                        <span className={classes.circularProgressbarValue}>
                            {`${parseFloat(module.grade).toFixed(0)}%`}
                        </span>
                    </div>
                    <span className="h6 text-light px-3 h-sm-6">{module.module_name}</span>
                    <button className={"href-button"} style={{backgroundColor: getProgressbarColor(module.grade, module.completed)}} onClick={() => {
                        window.open("http://school.tmbk.local/mod/" + module.modname + "/view.php?id=" + module.module)
                    }}>
                        Открыть
                        <FontAwesomeIcon icon={faChevronRight} className={"ms-2"}/>
                    </button>

                </div>
            </Tooltip>

        </div>
    );
};

export default ModuleProgress;