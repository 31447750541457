import React, {useEffect, useState} from 'react';
import classes from "../cards/Cards.module.css";

const LinearProgressbar = (props) => {


    const [width, setWidth] = useState("0%")
    const [opacity, setOpacity] = useState(0)

    useEffect(()=> {
        // props.completed ? setWidth( "100%") : setWidth(`${props.value}%`)
        setWidth(`${props.value}%`)
        setOpacity(".4")
    }, [])

    return (
        <div
            className={classes.moduleProgressbar__value}
             style={{
                 opacity: opacity,
            transition: `.5s ease ${(props.delay + 1) * 0.1}s`,
            width: width,
            background: `linear-gradient(90deg, rgba(80, 87, 96, 0.0), ${props.color})`
        }}/>
    );
};

export default LinearProgressbar;