import {useContext, useEffect, useState} from "react";
import {FilterContext, UiContext} from "../../../context";
import {useQuery} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {ListRatingEmployeeUnits} from "../lists/ListRatingEmployeeUnits";
import {GET_EMPLOYEE_MODULES_COMPLETIONS} from "../../../graphql/modules";
import {GET_EMPLOYEE_COURSES_COMPLETIONS} from "../../../graphql/courses";
import {GET_EMPLOYEE_MACHINES_COMPLETIONS} from "../../../graphql/machines";

export const EmployeeRatingDetails = () => {
    const {filters} = useContext(FilterContext);
    const {ui} = useContext(UiContext)

    const [data, setData] = useState({"modules": null, "courses": null, "machines": null});

    const employeeModulesCompletions = useQuery(GET_EMPLOYEE_MODULES_COMPLETIONS, {
        variables: {
            employee: filters.selectedEmployee && parseInt(filters.selectedEmployee.id),
            courses: filters.courses && filters.courses.map(course => course.id)
        },
    })

    const employeeCoursesCompletions = useQuery(GET_EMPLOYEE_COURSES_COMPLETIONS, {
        variables: {
            employee: filters.selectedEmployee && parseInt(filters.selectedEmployee.id),
            courses: filters.courses && filters.courses.map(course => course.id)
        },
    })

    const employeeMachinesCompletions = useQuery(GET_EMPLOYEE_MACHINES_COMPLETIONS, {
        variables: {
            employee: filters.selectedEmployee && parseInt(filters.selectedEmployee.id),
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
            // producers: filters.selectedBrands?.map(brand => parseInt(brand.id)),
        },
    })

    useEffect(() => {
        if (!employeeModulesCompletions.loading && employeeModulesCompletions.data) {
            setData(prevData => ({...prevData, "modules": employeeModulesCompletions.data.employeeModulesCompletions}))
        }
    }, [employeeModulesCompletions.data])

    useEffect(() => {
        if (!employeeCoursesCompletions.loading && employeeCoursesCompletions.data) {
            setData(prevData => ({...prevData, "courses": employeeCoursesCompletions.data.employeeCoursesCompletions}))
        }
    }, [employeeCoursesCompletions.data])

    useEffect(() => {
        if (!employeeMachinesCompletions.loading && employeeMachinesCompletions.data) {
            setData(prevData => ({...prevData, "machines": employeeMachinesCompletions.data.employeeMachinesCompletions}))
        }
    }, [employeeMachinesCompletions.data])

    return (
        !filters.selectedEmployee ? <div>Выберите сотрудника</div> :
            <div className={"d-flex flex-column w-100 h-100 py-4"}>
                <div className={"position-sticky d-flex flex-column justify-content-center"} style={{height: "20%"}}>
                    <div className="row d-flex justify-content-center w-100 h5 bold text-white m-0 p-0">{filters.selectedEmployee.lastname} {filters.selectedEmployee.firstname}</div>
                    <div className="text-center justify-content-center w-100 h6 m-0 p-0"><FontAwesomeIcon className={"me-2"} icon={faLocationDot}/>{filters.selectedEmployee.branches.map(b => b.name).join(', ')}</div>
                </div>
                <div className={"row w-100"}  style={{height: "75%"}}>
                    <div className="col h-100 text-center px-3">
                        {data[ui.units] && <ListRatingEmployeeUnits units={data[ui.units].filter(mc => mc.completed)} completed={true}/>}
                    </div>
                    <div className="col h-100  text-center px-3">
                        {data[ui.units] && <ListRatingEmployeeUnits units={data[ui.units].filter(mc => !mc.completed)} completed={false}/>}
                    </div>
                </div>
            </div>
    )
}