import React, {useContext, useEffect} from 'react';
import classes from "../dashboard/supervisor/SupervisorDashboards.module.css"
import {FilterContext, UiContext} from "../../../context";
import excavator from "../../../images/Excavator.svg"

import {Tooltip} from "antd";
import {getShortInfo} from "../../../utils/helpers";

const MachinesSelect = () => {

    const {ui, setUi} = useContext(UiContext)
    const {filters} = useContext(FilterContext);

    return (
        <div className={classes.machinesSelectContainer}>

            <div className={classes.optionContainer}>
                <h5 className={classes.optionTitle}>Типы:</h5>
                <span className={classes.optionDescription}>{
                    !filters.selectedTypes || (filters.selectedTypes?.length === 0)
                        ? "Все типы"
                        : <Tooltip title={getShortInfo(filters.selectedTypes)}>{getShortInfo(filters.selectedTypes)}</Tooltip>
                }</span>
            </div>

            {/*<div className={classes.optionContainer}>*/}
            {/*    <h5 className={classes.optionTitle}>Производители:</h5>*/}
            {/*    <span className={classes.optionDescription}>{*/}
            {/*        !filters.selectedBrands || (filters.selectedBrands?.length === 0)*/}
            {/*            ? "Все производители"*/}
            {/*            : <Tooltip title={getShortInfo(filters.selectedBrands)}>{getShortInfo(filters.selectedBrands)}</Tooltip>*/}
            {/*    }</span>*/}
            {/*</div>*/}

            <button
                className={classes.selectBtn}
                onClick={() => setUi({...ui, showMachinesModal: true})}
            >
                <img src={excavator} alt="" className={"me-2"}/> Выбрать
            </button>

        </div>
    );
};

export default MachinesSelect;