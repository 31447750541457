import './App.css';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import Navbar from "./components/UI/navbar/Navbar";
import {selectLoggedIn, selectUserData} from "./features/auth/authSlice";
import {useSelector} from "react-redux";
import React from "react";
import {FilterProvider, UiProvider, UserProvider} from "./context";
import {ConfigProvider} from "antd";
import {theme} from "./utils/constants";

const App = () => {
    const isLoggedIn = useSelector(selectLoggedIn)

    return (
        <UiProvider>
            <FilterProvider>
                <UserProvider>
                    <div className="App moving-background">
                        <BrowserRouter>
                            {isLoggedIn && <Navbar/>}
                            <ConfigProvider theme={theme}>
                                <AppRouter/>
                            </ConfigProvider>
                        </BrowserRouter>
                    </div>
                </UserProvider>
            </FilterProvider>
        </UiProvider>
    );
};

export default App;
