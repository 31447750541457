import {UiContext, UserContext} from "../../../../context";
import {useContext, useEffect, useState} from "react";
import classes from "./Styles.module.css"
import {AnimatedProgressbar} from "../../progressbars/AnimatedProgressbar";
import button from "bootstrap/js/src/button";
import {getPercent, getProgressbarColor} from "../../../../utils/helpers";
import {useQuery} from "@apollo/client";
import {GET_EMPLOYEE_COURSES_COMPLETIONS} from "../../../../graphql/courses";
import {useSelector} from "react-redux";
import {selectUserData} from "../../../../features/auth/authSlice";
import {GET_EMPLOYEE_MODULES_COMPLETIONS} from "../../../../graphql/modules";
import {GET_EMPLOYEE_MACHINES_COMPLETIONS} from "../../../../graphql/machines";
import {Modal} from "antd";

export const TotalProgress = () => {

    const {user} = useContext(UserContext)

    const maxCoursesAward = 28000

    const options = {
        variables: {
            employee: user.id,
        },
    }

    const [progresses, setProgresses] = useState([
        {
            units: "courses",
            percent: 0,
            value: 0,
            totalValue: 0,
            label: "Курсы",
            subLabel: "курсам",
            measurement: `₽`
        },
        // {
        //     units: "modules",
        //     percent: 0,
        //     value: 0,
        //     totalValue: 0,
        //     label: "Модули",
        //     subLabel: "модулям",
        //     measurement: "ед."
        // },
        // {
        //     units: "machines",
        //     percent: 0,
        //     value: 0,
        //     totalValue: 0,
        //     label: "Техника",
        //     subLabel: "технике",
        //     measurement: "ед."
        // },
    ])

    // const [activeProgressUnit, setActiveProgressUnit] = useState(progresses[0])
    const [detailModalOpened, setDetailModalOpened] = useState(false)
    // const [modalProgress, setModalProgress] = useState(progresses[0])

    const courses = useQuery(GET_EMPLOYEE_COURSES_COMPLETIONS, options)

    // const modules = useQuery(GET_EMPLOYEE_MODULES_COMPLETIONS, options)
    //
    // const machines = useQuery(GET_EMPLOYEE_MACHINES_COMPLETIONS, options)

    // useEffect(() => {
    //     setActiveProgressUnit(progresses.find(p => p.units === ui.units))
    // }, [ui.units]);

    useEffect(() => { //прогресс сотрудника по курсам
        setProgresses(prev =>
            prev.map(progress => {
                if (progress.units === "courses") {
                    const awardSum = Math.min(
                            maxCoursesAward,
                            courses
                                ?.data
                                ?.employeeCoursesCompletions
                                .reduce((sum, completion) => sum += completion.completed && parseInt(completion.course.award), 0) ?? 0)
                    return {
                        ...progress,
                        completions: courses?.data?.employeeCoursesCompletions,
                        percent: getPercent(awardSum, maxCoursesAward),
                        value: awardSum,
                        totalValue: maxCoursesAward,
                    }
                } else return progress
            }))
    }, [courses])


    // useEffect(() => { //прогресс сотрудника по модулям
    //     setProgresses(prev =>
    //         prev.map(progress => {
    //             if (progress.units === "modules") {
    //                 const completedSum = modules?.data?.employeeModulesCompletions.reduce((sum, completion) => sum += completion.completed && 1, 0)
    //                 return {
    //                     ...progress,
    //                     completions: modules?.data?.employeeModulesCompletions,
    //                     percent: getPercent(completedSum, modules?.data?.employeeModulesCompletions.length),
    //                     value: completedSum,
    //                     totalValue: modules?.data?.employeeModulesCompletions.length,
    //                 }
    //             } else return progress
    //         }))
    // }, [modules])
    //
    // useEffect(() => { //прогресс сотрудника по машинам
    //     setProgresses(prev =>
    //         prev.map(progress => {
    //             if (progress.units === "machines") {
    //                 const completedSum = machines?.data?.employeeMachinesCompletions.reduce((sum, completion) => sum += completion.completed && 1, 0)
    //                 return {
    //                     ...progress,
    //                     completions: machines?.data?.employeeMachinesCompletions,
    //                     percent: getPercent(completedSum, machines?.data?.employeeMachinesCompletions.length),
    //                     value: completedSum,
    //                     totalValue: machines?.data?.employeeMachinesCompletions.length
    //                 }
    //             } else return progress
    //         }))
    // }, [machines])

    return (
        <div className={classes.wrapper}>
            {progresses.map((progress, index) =>
                <div key={`progress-${index}`} className={classes.totalProgressContainer}>
                    <div className={"row w-100 justify-content-between"}>
                        <div className={"col"}><h5 className={"text-white"}>Общий прогресс по <span
                            style={{color: getProgressbarColor(progress.percent, false)}}>{progress.subLabel}</span>:
                        </h5></div>
                        <div className={"col-auto p-0"}>
                            <span className={"h5 text-white"}>{progress.value}</span>
                            <span className={"h5 text-secondary"}>/{progress.totalValue}</span>
                            <span className={"h6 text-secondary"}> {progress.measurement}</span>
                            {progress.units === "courses" && <span className={"h3 m-0 text-danger"}> *</span>}
                            {/*<button*/}
                            {/*onClick={() => {*/}
                            {/*    setModalProgress(progress)*/}
                            {/*    setDetailModalOpened(true)*/}
                            {/*}}*/}
                            {/*>?</button>*/}
                        </div>
                    </div>
                    <div className={classes.progressWrapper}>
                        <AnimatedProgressbar progress={progress.percent} index={index}/>
                    </div>
                    {progress.units === "courses" &&
                        <span className={"text-secondary"}>* максимально возможная сумма премии</span>}
                </div>
            )}
            <Modal
                opened={detailModalOpened}
                onCancel={() => setDetailModalOpened(false)}
            >
                {/*<ProgressDetails completions={modalProgress.completions} units={modalProgress.units}/>*/}
            </Modal>

        </div>
    )
}

const ProgressDetails = ({completions, units}) => {
    return <div>
        {completions?.map(completion => {
            return <div className="row">
                <div className="col">
                    {units === "courses"
                        ? completion.course.name
                        : units === "modules"
                            ? completion.module.name
                            : completion.machine_model_name}
                </div>
                <div className="col">
                    <span
                        className={completion.completed ? "text-success" : "text-danger"}>{completion.completed ? "пройден" : "не пройден"}</span>
                </div>

            </div>
        })
        }
    </div>
}