import {faChartColumn, faMoneyBill1, faTruck} from "@fortawesome/free-solid-svg-icons";

export const MODULES = "modules"
export const COURSES = "courses"
export const MACHINES = "machines"

export const pedestalColors = ["", "#FDAE47", "#C0C0C0", "#B4773B",]

export const theme = {
    token: {
        fontFamily: "Poppins",
        colorPrimary: "var(--primary)",
        colorPrimaryHover: "var(--primary)",
        borderRadius: ".75rem",
        colorText: "#ffffff",
    },
    components: {
        Button: {
            colorPrimary: "var(--primary)",
        },
        Modal: {
            contentBg: "var(--dark-bg)",
            headerBg: "var(--dark-bg)",
            titleColor: "white"
        },
        Select: {
            optionSelectedColor: "#ffffff",
            selectorBg: "#ff0000"
        }
    },
}

export const rolesOptions = [
    {value: "admin", label: "Админ"},
    {value: "supervisor", label: "Руководитель"},
    {value: "engineer", label: "Инженер"},
    {value: "manager", label: "Менеджер"},
]

export const supervisorUnitsOptions = [
    {units: 'courses', title: 'По курсам', icon: faMoneyBill1},
    {units: 'modules', title: 'По модулям', icon: faChartColumn},
    {units: 'machines', title: 'По технике', icon: faTruck},
]