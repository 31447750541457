import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './features/store'
import {Provider} from "react-redux";
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client'
import {authLink, httpLink} from './graphql/auth_middleware'
import dotenv from "react-dotenv";
import 'react-tooltip/dist/react-tooltip.css'


const root = ReactDOM.createRoot(document.getElementById('root'));

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    uri: dotenv.API_URL,
    cache: new InMemoryCache(),
})

root.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    </Provider>
);

