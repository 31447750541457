import classes from "./Tables.module.css";
import React, {useContext, useEffect, useState} from "react";
import {FilterContext, UiContext} from "../../../context";
import {getPercent, getProgressbarColor} from "../../../utils/helpers";
import {pedestalColors} from "../../../utils/constants";
import dataViewClasses from "../dataview/Dataview.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMedal} from "@fortawesome/free-solid-svg-icons";

export const RatingTableRowEmployee = ({employee, delay, width, place}) => {

    const {filters, setFilters} = useContext(FilterContext);
    const {ui, setUi} = useContext(UiContext)

    const [progressbarWidth, setProgressbarWidth] = useState("0%");
    const [opacity, setOpacity] = useState(0);
    const [transform, setTransform] = useState(50)

    const calculatedColor = getProgressbarColor(getPercent(employee.value, employee.totalValue), false)
    const calculatedBackground = `linear-gradient(90deg, #3D434B 10%, ${calculatedColor})`

    const anim = () => {
        setOpacity(0)
        setTransform(50)
        setTimeout(() => {
            setOpacity(1)
            setTransform(0)
        }, delay)
    }

    useEffect(() => {
        anim()
    }, [ui.units]);

    useEffect(() => {
        anim()
    }, []);

    useEffect(() => {
        setTimeout(() => {
                setProgressbarWidth(width + "%");
            }, delay
        )
    }, [width]);

    return (
        <div
            className={filters.selectedEmployee && parseInt(employee.employee.id) === parseInt(filters.selectedEmployee.id) ? [classes.ratingRow, classes.active].join(" ") : classes.ratingRow}
            style={{opacity: employee.value !== 0 ? opacity : 0.3, transform: `translateY(${transform}px)`}}
            onClick={() => {
                if (filters.selectedEmployee && parseInt(employee.employee.id) === parseInt(filters.selectedEmployee.id)) {
                    // setFilters({...filters, selectedEmployee: null})
                    setUi({...ui, bottomInfoFolded: true})
                } else {
                    setFilters(prev => ({...prev, selectedEmployee: employee.employee}))
                    setUi({...ui, bottomInfoFolded: false})
                }
            }}
        >
            <div className={classes.ratingTableProgressbar} style={{width: progressbarWidth, background: calculatedBackground}}/>
            <div className={classes.ratingTablePlace} style={{color: place < pedestalColors.length ? pedestalColors[place] : "#7A8490"}}>
                <div className={dataViewClasses.place}>
                    {place <= 3 && <FontAwesomeIcon icon={faMedal} className={dataViewClasses.icon}/>}
                    <span>{place}.</span>
                </div>
            </div>
            <span className={classes.ratingTableTitle}>{employee.employee.lastname} {employee.employee.firstname}</span>
            <span className={classes.ratingTableTitle}>{employee.employee.branches.map(b => b.name).join(', ')}</span>
            <span className={classes.ratingTableValue} style={{color: calculatedColor}}>{employee.value}</span>
            <span className={classes.ratingTableValue}>{employee.totalValue - employee.value}</span>
            <span className={classes.ratingTablePercents} style={{color: calculatedColor}}>{getPercent(employee.value, employee.totalValue).toFixed(1)} %</span>
        </div>
    )
}