import React, {useContext, useEffect, useState} from 'react';
import DatePicker from "./DatePicker";
import classes from "./DatePickers.module.css"
import OutlineButton from "../button/OutlineButton";
import {UiContext} from "../../../context";

const DateRangePicker = (props) => {


    const {ui, setUi} = useContext(UiContext)
    const [beginDate, setBeginDate] = useState(props.dateRange.beginDate);
    const [endDate, setEndDate] = useState(props.dateRange.endDate);

    const handleBeginDateChange = (date) => {
        setBeginDate(date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    return (
        <div className={'d-flex flex-column align-items-center justify-content-between gap-2'}>
            <div className={"row align-items-center w-100 p-0"}>
                <div className="col-6 d-flex justify-content-center align-items-center p-0">
                    <DatePicker
                        date={beginDate}
                        dateType={"start"}
                        subDate={endDate}
                        handleDateChange={handleBeginDateChange}/>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center p-0">
                    <DatePicker
                        date={endDate}
                        dateType={"end"}
                        subDate={beginDate}
                        handleDateChange={handleEndDateChange}/>
                </div>
            </div>
            <button
                className={classes.bottomActionConfirm}
                onClick={() => {
                    props.onChange({beginDate: new Date(beginDate), endDate: new Date(endDate)})
                    setUi(prevUi => ({...ui, showDatepickerModal: false}))
                }}>
                Применить
            </button>
        </div>
    );
};

export default DateRangePicker;