import React from 'react';
import classes from './Buttons.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NavButton = (props) => {

    return (
        <button {...props} className={props.active ? [classes.navigation__btn, classes.active].join(" ") : classes.navigation__btn} style={{}} onClick={() => props.action && props.action()}>
            <div>
                {props.icon && <FontAwesomeIcon icon={props.icon} className={"me-2"}></FontAwesomeIcon>}
            </div>
            <p className="p-0 m-0 h5">{props.button.title}</p>
        </button>
    );
};

export default NavButton;