import React, {useState} from 'react';
import AuthService from "../features/auth/auth.service";
import {useDispatch} from 'react-redux';
import {loginUser} from '../features/auth/authSlice'
import {saveTokens} from "../features/_helpers_/commands";
import {USER_DATA_KEY, USER_LOGGED_IN_KEY} from "../features/_helpers_/constants";
import ForwardButton from "../components/UI/button/ForwardButton";
import LoginInput from "../components/UI/input/LoginInput";
import {Form, message} from 'antd';


const Login = () => {
    const [authData, setAuthData] = useState({login: '', password: ''})
    const dispatch = useDispatch()

    const login = async event => {
        if (authData.login.length === 0) message.error('Введите логин')
        if (authData.password.length === 0) message.error('Введите пароль')
        else AuthService.login(authData.login, authData.password)
            .then(data => {
                saveTokens(data['access'], data['refresh']);
                localStorage.setItem(USER_DATA_KEY, JSON.stringify(data['user']));
                localStorage.setItem(USER_LOGGED_IN_KEY, "true");
                dispatch(loginUser(data['user']));
            })
            //а действительно ли он неверный?
            .catch((e) => {
                    message.error('Неверный логин или пароль')
                }
            );
    }


    return (
        <div className={"d-flex justify-content-center text-white align-items-center container-100 flex-column"}>
            <h1 className={"text-light bold"}>Добро пожаловать!</h1>
            <div className={"col-3 mt-4 justify-content-center"}>
                <Form
                    onFinishFailed={(errorInfo) => {
                        message.error('Вы не ввели логин или пароль')
                    }}
                    onFinish={login}
                    layout={"vertical"}
                    className={"d-flex flex-column align-items-center"}
                >
                    <LoginInput
                        label={"Логин"}
                        id={"login"}
                        type='text'
                        value={authData.login}
                        onChange={e => setAuthData({...authData, login: e.target.value})}
                        placeholder='Введите логин'/>
                    <LoginInput
                        type='password'
                        label={"Пароль"}
                        id="password"
                        value={authData.password}
                        onChange={e => setAuthData({...authData, password: e.target.value})}
                        placeholder='Введите пароль'
                    />
                    <div className="col-6 mt-5">
                        <ForwardButton className={"mt-3"} title={"Войти"}/>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default Login;