import classes from "./Cards.module.css";
import {getPercent} from "../../../utils/helpers";
import {useContext, useEffect, useState} from "react";
import {FilterContext} from "../../../context";
import {Tooltip} from "antd";

export const RatingBranchUnitCard = ({
                                         unitCardId,
                                         unitName,
                                         completedEmployees,
                                         uncompletedEmployees,
                                         delay,
                                     }) => {
    const [goodWidth, setGoodWidth] = useState(0);
    const [badWidth, setBadWidth] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const {filters} = useContext(FilterContext);

    useEffect(() => {
        // Функция обновления состояния с использованием предыдущего состояния
        const updateState = () => {
            setOpacity(1);
            setGoodWidth((prevState) =>
                getPercent(
                    completedEmployees.length,
                    completedEmployees.length + uncompletedEmployees.length
                )
            );
            setBadWidth((prevState) =>
                getPercent(
                    uncompletedEmployees.length,
                    completedEmployees.length + uncompletedEmployees.length
                )
            );
        };

        const timeoutId = setTimeout(updateState, 0);

        // Очистка таймера при размонтировании компонента
        return () => clearTimeout(timeoutId);
    }, [filters.selectedBranch, completedEmployees, uncompletedEmployees, delay]);

    const listCompletedEmployees = completedEmployees.map((employee, index) => (
        <p className={"text-success"} key={`${index}. completedEmployee-${employee.id}`}>
            {`${index + 1}. ${employee.employee.lastname} ${employee.employee.firstname}`}
        </p>
    ))

    const listUncompletedEmployees = uncompletedEmployees.map((employee, index) => (
        <p className={"text-danger"} key={`${index}. uncompletedEmployee-${employee.id}`}>
            {`${index + 1}. ${employee.lastname} ${employee.firstname}`}
        </p>
    ))

    return (
        <div
            className={classes.ratingBranchUnitCard__container}
            style={{opacity: opacity}}
        >
            <div className={classes.ratingBranchUnitCard__title}>{unitName}</div>
            <div className={classes.ratingBranchUnitCard__progress}>
                {completedEmployees.length !== 0 && (
                    <Tooltip title={listCompletedEmployees}>
                        <div className={classes.ratingBranchUnitCard__progressGood} style={{width: `${goodWidth}%`}}>
                            <span>{completedEmployees.length}</span>
                        </div>
                    </Tooltip>
                )}
                {uncompletedEmployees.length !== 0 && (
                    <Tooltip title={listUncompletedEmployees}>
                        <div className={classes.ratingBranchUnitCard__progressBad} style={{width: `${badWidth}%`}}>
                            <span>{uncompletedEmployees.length}</span>
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
