import React, {useEffect, useState} from 'react';
import classes from "./Tables.module.css";
import {getProgressbarColor} from "../../../utils/helpers";

const ModuleProgress = ({completion, shouldAnimate}) => {


    const [width, setWidth] = useState("0%")
    // const color = getProgressbarColor(completion.detail, completion !== undefined ? completion.completed : false)
    const color = completion !== undefined && completion.completed === true ? "#8CC06D" : "#DD5757"

    useEffect(() => {
        if (shouldAnimate) setWidth(completion.detail + "%")
    }, [])

    return (
            completion.completed !== null && <div className={classes.moduleProgressbar}>
                <div
                    className={classes.moduleProgressbarValue}
                    style={{
                        background: `linear-gradient(90deg, rgba(122, 132, 144, 0.0), ${color} 50%)`,
                        width: shouldAnimate ? width : completion.detail + "%"
                    }}
                >
                </div>
                <span className={classes.moduleProgressbarTitle} style={{
                    backgroundColor: Math.round(completion.detail) !== 0 ? "#282D32" : color,
                }}>
                {Math.round(completion.detail)}%
            </span>
            </div>
    );
};

export default React.memo(ModuleProgress);