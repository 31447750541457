import classes from "./Buttons.module.css"

export const RateButton = ({color, rate, isActive, isDefault, onClick}) => {

    const getClasses = () => {
        let classesArray = [classes.rateSelectBtn]
        if (isActive) classesArray.push(classes.active)
        if (isDefault) classesArray.push(classes.default)
        return classesArray.join(" ")
    }

    return (
        <>
            <button
                className={getClasses()}
                onClick={() => onClick(rate)}
                style={{borderColor: isDefault && !isActive ? color : "#7A8490", color: isDefault && !isActive || !isActive ? color : "#fff", backgroundColor: isActive && color, opacity: isDefault ? 1 : 0.5}}
            >
                <span>{rate.rate}</span>
                {isDefault && <span style={{fontSize: "0.625rem"}}>(сейчас)</span>}
            </button>
        </>
    )
}