import React, {useContext, useEffect, useState} from 'react';
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from "./Buttons.module.css"
import {FilterContext} from "../../../context";

const OrderButton = () => {

    const {filters, setFilters} = useContext(FilterContext)
    const [rotate, setRotate] = useState(0)

    useEffect(() => {
        if (filters.order === "desc") setRotate(0)
        else setRotate(180)
    }, [filters.order])

    const handleClick = () => {
        if (filters.order === "asc") setFilters(prev => ({...prev, order: "desc"}))
        else setFilters(prev => ({...prev, order: "asc"}))
    }

    return (
        <button className={classes.orderButton} onClick={() => handleClick()}>
            <FontAwesomeIcon icon={faAngleUp} style={{transform: `rotate(${rotate}deg)`, transition: ".3s"}}/>
        </button>
    );
};

export default OrderButton;