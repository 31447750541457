import { useState, useEffect } from 'react';

export const useTypewriter = (text) => {
    const [displayText, setDisplayText] = useState('');
    const [blink, setBlink] = useState(true);

    useEffect(() => {
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            setDisplayText(text.substring(0, currentIndex));
            currentIndex++;
            if (currentIndex > text.length) {
                clearInterval(intervalId);
                setBlink(true);
            }
        }, 100);
        return () => clearInterval(intervalId);
    }, [text]);

    useEffect(() => {
        const blinkIntervalId = setInterval(() => {
            setBlink((prev) => !prev);
        }, 500);
        return () => clearInterval(blinkIntervalId);
    }, []);

    return `${displayText}${blink ? '|' : ' '}`;
};
