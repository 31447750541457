import React, {useContext, useEffect} from 'react';
import {Route, useNavigate} from "react-router-dom";
import {Navigate, Routes} from 'react-router';
import {employeeRoutes, publicRoutes, supervisorRoutes} from "../router/routes";
import {selectLoggedIn, selectUserData} from '../features/auth/authSlice'
import {useSelector} from "react-redux";
import {UserContext} from "../context";

const AppRouter = () => {

    const isLoggedIn = useSelector(selectLoggedIn)
    const {user, setUser} = useContext(UserContext)

    let navigate = useNavigate()

    useEffect(() => {
        if (user.role === "supervisor" || user.role === "admin") {
            navigate("./units")
        }
    }, [user.role])

    return (
        isLoggedIn
            ? <Routes>
                {['admin', 'supervisor'].includes(user.role)
                    ? supervisorRoutes.concat(employeeRoutes).map(route =>
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<route.component/>}
                            exact={route.exact}
                        />)
                    : employeeRoutes.map(route =>
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<route.component/>}
                            exact={route.exact}
                        />)
                }
                <Route path='*' element={<Navigate to='/categories'/>}/>
            </Routes>
            : <Routes>
                {publicRoutes.map(route =>
                    <Route
                        key={route.path}
                        path={route.path}
                        element={<route.component/>}
                        exact={route.exact}
                    />)}
                <Route path='*' element={<Navigate to='/login'/>}/>
            </Routes>
    );
};

export default AppRouter;