import React, {useEffect, useState} from 'react';
import {faPerson} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EmployeeIcon = (props) => {

    const [color, setColor] = useState("rgba(255,255,255,0.4)")


    useEffect(() => {
        setColor(props.color)
    }, [props.color]);

    useEffect(() => {
        setTimeout(() => {
            setColor(props.color)
        }, props.delay)
    }, [])

    return (
        <FontAwesomeIcon icon={faPerson} style={{
            color: color, fontSize: "1.5em", transition: ".3s", width: "12px", height: "28.3px"
        }}/>
    );
};

export default EmployeeIcon;