import classes from "../lists/Lists.module.css";
import React, {useEffect, useState} from "react";

export const EmployeeListCard = ({employee, completion, index}) => {


    const [scale, setScale] = useState(0);

    useEffect(() => {
        if (index < 20) setTimeout(() => {
            setScale(1)
        }, index * 50 + 50)
        else setScale(1)
    }, []);
    return (
        <div className={classes.employee} key={employee.id} style={{
            // transform: `scale(${scale})`,
            opacity: scale
        }}>
            <div className="col-1 text-secondary">{index + 1}.</div>
            <div className={"col"}>{employee.lastname} {employee.firstname}</div>
            <div className={"col text-secondary"}>{employee.branches.map(eb => eb.name).join(', ')}</div>
            <div className={"col-2 text-center pe-3 bold text-green"}>{completion !== undefined && completion}</div>
        </div>
    )
}