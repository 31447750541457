import DateRangePicker from "../select/DateRangePicker";
import MyModal from "./MyModal";
import React, {useContext, useEffect, useState} from "react";
import {FilterContext} from "../../../context";
import {Modal} from "antd";

export const DatepickerModal = ({active, setActive, onChange}) => {

    const {filters} = useContext(FilterContext);
    const [filtersState, setFiltersState] = useState(filters);

    const handleCustomRangeChange = (dateRange) => {
        setFiltersState(prev => ({...prev, dateRange: dateRange}))
    }

    useEffect(() => {
        onChange(filtersState)
    }, [filtersState]);

    return (
        <Modal
            centered
            width={1000}
            open={active}
            footer={null}
            onCancel={() => setActive(false)}
            title={<span className={"h4"}>Выбрать диапазон</span>}
        >
            <DateRangePicker
                dateRange={filters.dateRange}
                onChange={handleCustomRangeChange}
            />
        </Modal>
    )
}