import {useQuery} from "@apollo/client";
import {Center} from "../../Center";
import Loader from "../../loader/Loader";
import {useContext, useEffect, useState} from "react";
import {LeaderboardItemsList} from "../../lists/LeaderboardItemsList";
import {
    convertRatingToBranchesLeaderboards,
    convertRatingToEmployeesLeaderboards,
    sortRating
} from "../../../../utils/helpers";
import {FilterContext, UiContext} from "../../../../context";
import {GET_BRANCHES_RATING_BY_MODULES, GET_EMPLOYEES_RATING_BY_MODULES} from "../../../../graphql/modules";
import {GET_BRANCHES_RATING_BY_MACHINES, GET_EMPLOYEES_RATING_BY_MACHINES} from "../../../../graphql/machines";
import {GET_BRANCHES_RATING_BY_COURSES, GET_EMPLOYEES_RATING_BY_COURSES} from "../../../../graphql/courses";
import {EmployeesLeaderboardTable} from "../../table/EmployeesLeaderboardTable";
import {BranchesLeaderboardTable} from "../../table/BranchesLeaderboardTable";

export const RatingDashboard = () => {

    const {filters} = useContext(FilterContext);
    const {ui} = useContext(UiContext);

    const [rating, setRating] = useState({
        modules: {employees: null, branches: null},
        courses: {employees: null, branches: null},
        machines: {employees: null, branches: null},
    });

    const [sortedBranchesRating, setSortedBranchesRating] = useState([]);
    const [sortedEmployeesRating, setSortedEmployeesRating] = useState([]);

    const setData = () => {
        let branchesRatingData = null;
        let employeesRatingData = null;


        if (ui.units === "modules") {
            branchesRatingData = rating.modules.branches
            employeesRatingData = rating.modules.employees
        } else if (ui.units === "courses") {
            branchesRatingData = rating.courses.branches
            employeesRatingData = rating.courses.employees
        } else if (ui.units === "machines") {
            branchesRatingData = rating.machines.branches
            employeesRatingData = rating.machines.employees
        }

        if (branchesRatingData) {
            const newRating = convertRatingToBranchesLeaderboards(sortRating(branchesRatingData))
            setSortedBranchesRating(newRating);
        }

        if (employeesRatingData) {
            const newRating = convertRatingToEmployeesLeaderboards(sortRating(employeesRatingData)).filter(e => e.totalValue !== 0)
            setSortedEmployeesRating(newRating);
        }
    }

    //BRANCHES
    //modules
    const modulesBranchesRating = useQuery(GET_BRANCHES_RATING_BY_MODULES, {
        variables: {
            branches: filters.branches && filters.branches.map(branch => branch.id),
            courses: filters.courses && filters.courses.map(course => course.id)
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setRating({...rating, modules: {...rating.modules, branches: d.ratingBranchesByModules}})
    });
    //courses
    const coursesBranchesRating = useQuery(GET_BRANCHES_RATING_BY_COURSES, {
        variables: {
            branches: filters.branches && filters.branches.map(branch => branch.id),
            courses: filters.courses && filters.courses.map(course => course.id)
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setRating({...rating, courses: {...rating.courses, branches: d.ratingBranchesByCourses}})
    });
    //machines
    const machinesBranchesRating = useQuery(GET_BRANCHES_RATING_BY_MACHINES, {
        variables: {
            branches: filters.branches && filters.branches.map(branch => branch.id),
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setRating({...rating, machines: {...rating.machines, branches: d.ratingBranchesByMachines}})
    });
    //EMPLOYEES
    //modules

    const modulesEmployeesRating = useQuery(GET_EMPLOYEES_RATING_BY_MODULES, {
        variables: {
            branches: filters.branches.map(branch => branch.id),
            courses: filters.courses && filters.courses.map(course => course.id)
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setRating({...rating, modules: {...rating.modules, employees: d.ratingEmployeesByModules}})
    });
    //courses
    const coursesEmployeesRating = useQuery(GET_EMPLOYEES_RATING_BY_COURSES, {
        variables: {
            branches: filters.branches && filters.branches.map(branch => branch.id),
            courses: filters.courses && filters.courses.map(course => course.id)
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setRating({...rating, courses: {...rating.courses, employees: d.ratingEmployeesByCourses}})
    });

    //machines
    const machinesEmployeesRating = useQuery(GET_EMPLOYEES_RATING_BY_MACHINES, {
        variables: {
            branches: filters.branches && filters.branches.map(branch => branch.id),
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setRating({
            ...rating,
            machines: {...rating.machines, employees: d.ratingEmployeesByMachines}
        })
    });

    useEffect(() => {
        setData()
    }, [modulesBranchesRating, coursesBranchesRating, machinesBranchesRating, modulesEmployeesRating, coursesEmployeesRating, machinesEmployeesRating, ui.units]);

    const isLoading = () => {
        if (ui.units === "courses" && (coursesBranchesRating.loading || coursesEmployeesRating.loading)) return true
        else if (ui.units === "modules" && (modulesBranchesRating.loading || modulesEmployeesRating.loading)) return true
        else if (ui.units === "machines" && (machinesBranchesRating.loading || machinesEmployeesRating.loading)) return true
        else return false
    };

    return (
        <Center>
            {!Object.values(rating).every(item => item !== null) || isLoading() ? <Loader/> :
                ui.mode === "employees"
                    ? <div className={"d-flex flex-column w-100 h-100 pt-5"}>
                        <div className={"w-100 pt-3"}>
                            <LeaderboardItemsList leaderboards={sortedEmployeesRating.slice(0, 3)}/>
                            <EmployeesLeaderboardTable employees={sortedEmployeesRating}/>
                        </div>
                    </div>
                    : <div className={"d-flex flex-column w-100 h-100 pt-5"}>
                        <div className={"w-100 pt-3"}>
                            <LeaderboardItemsList leaderboards={sortedBranchesRating.slice(0, 3)}/>
                            <BranchesLeaderboardTable branches={sortedBranchesRating}/>
                        </div>
                    </div>
            }
        </Center>
    )
}