import {gql} from '@apollo/client'

// ************************** TABLE-SCREEN FUNCTIONS ************************** //

//Получение шапки
export const CORE_GET_MODULES_TABLE_HEADER = gql`
    query ($categories: [Int] = null) {
        categories (categories: $categories) {
            id,
            name, 
            modules {
                id,
                name
            }           
        }
    }
`

// Получение прохождений:

export const CORE_GET_MODULES_TABLE_CONTENT = gql`
    query ($categories: [Int] = null) {
        modulesTable (categories: $categories) {
            employee,
            branch,
            completions {
                completed,
                detail
            }
        }
    }
`

// ************************** DYNAMIC-SCREEN FUNCTIONS ************************** //

export const CORE_GET_MODULES_SUMMARY = gql`
    query ($branches: [Int] = null, $dateBegin: Date = null, $dateEnd: Date = null, $courses: [Int] = null) {
        moduleSummary(courses: $courses) {
            id,
            name, 
            course {
                id, 
                name
            },
            completedCount(branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd),
            uncompletedCount(branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd)
        }
    }
`

export const CORE_GET_MODULES_EMPLOYEES_DETAIL = gql`
    query ($branches: [Int] = null, $module: Int, $dateBegin: Date = null, $dateEnd: Date = null) {
        moduleEmployees (module: $module, dateBegin: $dateBegin, dateEnd: $dateEnd, branches: $branches) {
            id,
            firstname, 
            lastname,
            branches {
                name
            }
        }
    }
`

export const CORE_GET_MONTH_PROGRESS_BY_MODULE = gql`
    query ($module: Int = null, $branches: [Int] = null, $dateBegin: Date = null, $dateEnd: Date = null) {
        moduleSummary (module: $module) {
            id,
            name, 
            monthlyProgress (branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd) {
                month,
                count
            },
            uncompletedEmployees (branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd) {
                id,
                firstname,
                lastname,
                branches {
                    id,
                    name
                }
            }
        }
    }
`

// ************************** RATING-SCREEN FUNCTIONS ************************** //

export const GET_BRANCHES_RATING_BY_MODULES = gql`query ($branches: [Int] = null, $courses: [Int] = null) {
    ratingBranchesByModules(branches: $branches, courses: $courses) {
        branch {
            id,
            name
        },
        employeesCount,
        completedCount,
        uncompletedCount
    }
}`

export const GET_BRANCH_EMPLOYEES_MODULES_COMPLETIONS = gql`query ($branches: [Int] = null, $courses: [Int] = null) {
    branchEmployeesModulesCompletions (branches: $branches, courses: $courses) {     
        name,
        completedEmployees (branches: $branches) {
            employee {
                firstname,
                lastname
            },
            dateCompleted
        },
        uncompletedEmployees (branches: $branches) {
            firstname,
            lastname
        }
    }
}`

export const GET_EMPLOYEES_RATING_BY_MODULES = gql`query ($branches: [Int] = null, $courses: [Int] = null) {
    ratingEmployeesByModules(branches: $branches, courses: $courses) {
        employee {
            id,
            firstname,
            lastname,
            branches {
                name
            }
        },
        completedCount,
        uncompletedCount
    }
}`

export const GET_EMPLOYEE_MODULES_COMPLETIONS = gql`query ($employee: Int, $courses: [Int] = null) {
    employeeModulesCompletions(employee: $employee, courses: $courses) {
        module {
            name
        },
        grade,
        completed, 
        dateCompleted
    }
}`