import React, {useContext, useState} from 'react';
import classes from "./Buttons.module.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {UiContext} from "../../../context";

const DropdownButton = ({...props}) => {


    const {ui, setUi} = useContext(UiContext)
    const [selectedOption, setSelectedOption] = useState(props.options.find(o => o.units === ui.units))
    const [isOptionsShowing, setIsOptionsShowing] = useState(false)

    return (
        <div className={classes.dropdownButton}
             onMouseEnter={() => {
                 setIsOptionsShowing(true)
             }}
             onMouseLeave={() => {
                 setIsOptionsShowing(false)
             }}>
            <div>
                {/*Отображение выбранного варианта*/}
                <button
                    onClick={() => {
                        setIsOptionsShowing(false)
                        let curIndex = 0;
                        props.options.forEach((option, index) => {
                            if (option === selectedOption) curIndex = index;
                        })
                        curIndex === props.options.length - 1 ? curIndex = 0 : curIndex++
                        setSelectedOption(props.options[curIndex])
                        setUi({...ui, units: props.options[curIndex].units})
                        // dispatch(changeSupervisorUnitsShowing(props.options[curIndex].showing))
                    }}
                    style={{
                        transition: ".3s",
                        borderRadius: isOptionsShowing ? "20px 0 0 0" : "20px 0 0 20px"
                    }}
                    className={[classes.dropdownButtonTitle, "h5"].join(" ")}>
                    <FontAwesomeIcon icon={selectedOption.icon} className={"me-2"}/>
                    {selectedOption.title}
                </button>

                {/*Кнопка переключения видимости вариантов выбора*/}
                <button
                    style={{
                        transition: ".3s",
                        borderRadius: isOptionsShowing ? "0 20px 0 0" : "0 20px 20px 0"
                    }}
                    className={[classes.dropdownButtonArrow, "h5"].join(" ")}>


                <FontAwesomeIcon icon={faCaretDown} style={{transform: `rotate(${!isOptionsShowing ? 360 : 180}deg)`, transition: ".3s"}}/>
            </button>
        </div>


    {/*Список опций*/
    }
    <div className={classes.optionsList} style={{
        transition: ".3s",
        pointerEvents: isOptionsShowing ? "all" : "none",
        opacity: isOptionsShowing ? "1" : "0",
    }}>
        {props.options.map((option, index) =>
            option.units != ui.units &&
            <button
                key={option.title}
                className={[classes.optionButton, "h5"].join(" ")}
                onClick={() => {
                    setSelectedOption(option)
                    setIsOptionsShowing(false)
                    setUi({...ui, units: option.units})
                }}>
                <FontAwesomeIcon icon={option.icon} className={"me-2"}/>
                {option.title}
            </button>
        )}
    </div>

</div>
)
    ;
};

export default DropdownButton;