import React, {memo, useContext, useEffect, useState} from 'react';
import Filter from "../../components/UI/dashboard/supervisor/filters/Filter";
import TopInfo from "../../components/UI/dashboard/supervisor/TopInfo";
import BottomInfo from "../../components/UI/dashboard/supervisor/BottomInfo";
import classes from "../Pages.module.css"
import {FilterContext, FilterProvider, UiContext, UserContext} from "../../context";
import AttemptsList from "../../components/UI/lists/AttemptsList";
import {MachinesModal} from "../../components/UI/modal/MachinesModal";
import {DatepickerModal} from "../../components/UI/modal/DatepickerModal";
import {useDispatch, useSelector} from "react-redux";
import {selectBranches, selectUsers} from "../../features/users/usersSlice";
import {fetchAdminData, fetchSupervisorData} from "../../features/_helpers_/commands";
import {set_types} from "../../features/machines/machinesSlice";
import MachinesService from "../../features/machines/machines.service";
import {selectUserData} from "../../features/auth/authSlice";
import {selectCategories, selectCourses, selectModules} from "../../features/educationUnits/unitsSlice";
import {selectCoursesCompletions, selectModulesCompletions} from "../../features/completions/completionsSlice";
import {useFetch} from "../../hooks/useFetch";

const SupervisorDashboards = () => {

    const {ui, setUi} = useContext(UiContext)
    const {filters} = useContext(FilterContext)
    const {user} = useContext(UserContext)
    const [filtersState, setFiltersState] = useState(filters);

    //категории
    const categories = useSelector(selectCategories)

    //модули, отображаемые в таблице
    const modules = useSelector(selectModules)
    const modulesCompletions = useSelector(selectModulesCompletions)

    //курсы, отображаемые в таблице
    const courses = useSelector(selectCourses)
    const coursesCompletions = useSelector(selectCoursesCompletions)

    // const [brands, setBrands] = useState([])
    const [types, setTypes] = useState([])
    const [models, setModels] = useState([])
    const [hasChanges, setHasChanges] = useState(false)

    const dispatch = useDispatch()
    const users = useSelector(selectUsers)

    const [fetchData, isLoading] = useFetch(async () => {
        user.role === 'supervisor'
            ? await fetchSupervisorData(dispatch)
            : await fetchAdminData(dispatch)

        // let data = await MachinesService.getAllProducers()
        // dispatch(set_producers(data))
        // setBrands(data)

        let data = await MachinesService.getAllMachineTypes()
        dispatch(set_types(data))
        setTypes(data)

    })
    const handleMachinesModalClose = (value) => {
        setUi(prev => ({...prev, showMachinesModal: value}))
    }



    const handleFiltersStateChange = (newState) => {
        setFiltersState(prevState => ({...prevState, ...newState}))
        setHasChanges(true)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <FilterProvider>
            <div className={"text-light d-flex flex-column justify-content-center align-items-center"} style={{height: "90%"}}>
                <MachinesModal
                    modalActive={ui.showMachinesModal}
                    onChange={handleFiltersStateChange}
                    setModalActive={handleMachinesModalClose}/>
                <div className={"w-100 h-100 p-3"}>
                    {<div className={classes.dashboardContainer}>
                        <Filter
                            hasChanges={hasChanges}
                            parentFilters={filtersState}
                            setHasChanges={setHasChanges}
                            onChange={handleFiltersStateChange}/>
                        <div className={classes.info}>
                            {ui.dashboardType !== "attempts"
                                ? <>
                                    <TopInfo/>
                                    <BottomInfo/>
                                </>
                                : <AttemptsList/>
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
        </FilterProvider>
    );
};

export default memo(SupervisorDashboards);