import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {CORE_GET_COURSES_EMPLOYEES_DETAIL} from "../../../graphql/courses";
import {FilterContext, UiContext} from "../../../context";
import {getNextMonthFirstDay} from "../../../utils/helpers";
import classes from "./Lists.module.css"
import {CORE_GET_MODULES_EMPLOYEES_DETAIL} from "../../../graphql/modules";
import {useSelector} from "react-redux";
import {selectCoursesCompletions, selectModulesCompletions} from "../../../features/completions/completionsSlice";
import {CORE_GET_MACHINES_EMPLOYEES_DETAIL} from "../../../graphql/machines";
import Loader from "../loader/Loader";
import {Center} from "../Center";
import {EmployeeListCard} from "../cards/EmployeeListCard";

const EmployeesList = ({employees, showCustomEmployees}) => {

    const {filters} = useContext(FilterContext);
    const {ui} = useContext(UiContext)

    const [data, setData] = useState({"courses": [], "modules": [], "machines": []});

    const courses = useQuery(CORE_GET_COURSES_EMPLOYEES_DETAIL, {
        variables: {
            course: filters.selectedCourse && parseInt(filters.selectedCourse.id),
            dateBegin: filters.selectedBar && (filters.selectedBar.date),
            dateEnd: filters.selectedBar && (getNextMonthFirstDay(filters.selectedBar.date)),
            branches: filters.branches.map(branch => branch.id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (d) => {
            setData({...data, "courses": d.courseEmployees})
        }
    });

    const modules = useQuery(CORE_GET_MODULES_EMPLOYEES_DETAIL, {
        variables: {
            module: filters.selectedModule && parseInt(filters.selectedModule.id),
            dateBegin: filters.selectedBar && (filters.selectedBar.date),
            dateEnd: filters.selectedBar && (getNextMonthFirstDay(filters.selectedBar.date)),
            branches: filters.branches.map(branch => branch.id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (d) => {
            setData({...data, "modules": d.moduleEmployees})
        }
    });

    const machines= useQuery(CORE_GET_MACHINES_EMPLOYEES_DETAIL, {
        variables: {
            machineType: filters.selectedMachine && parseInt(filters.selectedMachine.id),
            dateBegin: filters.selectedBar  && (filters.selectedBar.date),
            dateEnd: filters.selectedBar && (getNextMonthFirstDay(filters.selectedBar.date)),
            branches: filters.branches.map(branch => branch.id)
        },
        fetchPolicy: 'no-cache',
        onCompleted: (d) => {
            if (d !== undefined) setData({...data, "machines": d.machineEmployees})
        }
    });

    const coursesCompletions = useSelector(selectCoursesCompletions)
    const modulesCompletions = useSelector(selectModulesCompletions)

    function getCompletion(employee) {
        let completion = ""
        if (ui.units === "modules" && filters.selectedModule) {
            const completionTmp = modulesCompletions.filter(mc => {
                return mc.employee === parseInt(employee.id) && mc.module === parseInt(filters.selectedModule.id)})[0]
            if (completionTmp !== undefined) {
                completion = Math.floor(completionTmp.grade)
            } else {
            }
        } else if (ui.units === "courses") {
            const completionTmp = coursesCompletions.filter(cc => cc.employee === parseInt(employee.id) && cc.course === parseInt(filters.selectedCourse.id))[0]
            if (completionTmp !== undefined) {
                completion = Math.floor(completionTmp.grade)
            }
        } else {
            return ""
        }
        return completion
    }

    if (courses.loading || machines.loading || modules.loading) return <Center><Loader/></Center>
    return (
        <div className={classes.employeeListContainer}>
            <div className={"w-100 h-100 d-flex flex-column justify-content-start"}>
                {
                    data[ui.units] !== undefined && data[ui.units] && !showCustomEmployees ?
                        data[ui.units].map((employee, index) => {
                            const completion = getCompletion(employee)
                            return <EmployeeListCard key={`employee-item-${employee.id}`} employee={employee} completion={ui.units !== "machines" && completion} index={index}/>
                        }
                        ) : employees !== undefined && employees.map((employee, index) => <EmployeeListCard key={`employee-item-${employee.id}`} employee={employee} index={index}/>)
                        // <Center><span className={"text-secondary"}>Данных не найдено</span></Center>
                    }
            </div>
        </div>
    );
};

export default EmployeesList;
