import React from 'react';
import classes from './Loader.module.css'
import ReactLoading from "react-loading"

const Loader = (props) => {

    // possible types
        // type={"blank"}
        // type={"balls"}
        // type={"bars"}
        // type={"bubbles"}
        // type={"cubes"}
        // type={"cylon"}
        // type={"spin"}
        // type={"spinningBubbles"} |
        // type={"spokes"}


    return (
        <ReactLoading
            type={props.type ? props.type : "spinningBubbles"}
            color={"#ffffff"}
            height={props.size ? props.size : 75}
            width={props.size ? props.size : 75}
        />
    );
};

export default Loader;