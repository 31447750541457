import classes from "./Progressbars.module.css"
import {addOpacityToColor, getProgressbarColor} from "../../../utils/helpers";
import {useEffect, useState} from "react";

export const AnimatedProgressbar = ({progress, index}) => {

    const [width, setWidth] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setWidth(prev => progress)
        }, 10)
    }, [progress]);

    return (
        <div className={classes.animatedProgressbarContainer}>
            <div
                className={classes.progressBarValue}
                style={{
                    width: `${width}%`,
                    background: addOpacityToColor(getProgressbarColor(progress, false), 128)
                }}
            >
                <div
                    className={classes.progressbarAnimation}
                    style={{
                        background: `linear-gradient(90deg, #FDAE4700, ${getProgressbarColor(progress, false)}, #FDAE4700)`,
                        animationDelay: `${index}s`
                    }}

                >
                </div>
            </div>
            <div className={classes.value}>
                {(progress.toFixed(1))}%
            </div>
        </div>
    )
}