import jwt_decode from "jwt-decode"
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, API_URL} from './constants'
import UnitsService from "../educationUnits/units.service";
import {set_categories, set_courses, set_modules} from "../educationUnits/unitsSlice";
import UsersService from "../users/users.service";
import {set_branches, set_users} from "../users/usersSlice";
import CompletionsService from "../completions/completions.service";
import {set_courses_completions, set_modules_completions} from "../completions/completionsSlice";

export const saveTokens = (accessToken, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

export const isTokenExpired = (token) => {
    return Date.now() >= jwt_decode(token)['exp'] * 1000;
}

 async function refreshTokens(refreshToken) {
     const response = await fetch(API_URL + '/token/refresh/', {
         method: 'POST',
         headers: {
             'Content-Type': 'application/json',
         },
         body: JSON.stringify({
             refresh: refreshToken,
         }),
     })
     if (response.status === 200) {
         return Promise.resolve(response.json())
     } else {
         return Promise.reject()
     }
 }

export async function checkToken() {
    const loginUrl = '/login'
    let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (accessToken == null)
        return window.location.replace(loginUrl);

    if (isTokenExpired(accessToken)) {
        try {
            const data = await refreshTokens(localStorage.getItem(REFRESH_TOKEN_KEY))
            saveTokens(data['access'], data['refresh'])

        } catch (error) {
            localStorage.clear()
            return window.location.replace(loginUrl);
        }
    }
}

export async function fetchWithAuth(url, options) {
    await checkToken()

    if (!options.headers)
        options.headers = {}

    options.headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN_KEY)

    return fetch(url, options)
}

export async function fetchSupervisorData(dispatch) {
    const categories = await UnitsService.getAllCategories()
    dispatch(set_categories(categories))

    const courses = await UnitsService.getAllCourses()
    dispatch(set_courses(courses))

    const modules = await UnitsService.getAllModules();
    dispatch(set_modules(modules));

    const branches = await UsersService.getBranches();
    dispatch(set_branches(branches));
}

export async function fetchAdminData(dispatch) {
    let categoriesData = await UnitsService.getAllCategories();
    dispatch(set_categories(categoriesData));

    let coursesData = await UnitsService.getAllCourses();
    dispatch(set_courses(coursesData));

    let modulesData = await UnitsService.getAllModules();
    dispatch(set_modules(modulesData));

    let branchesData = await UsersService.getBranches();
    dispatch(set_branches(branchesData));
}
