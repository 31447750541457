import Accordion from "./Accordion";
import {EmployeeModuleCompletion} from "../cards/EmployeeModuleCompletion";

export const EmployeeCoursesCompletion = ({coursesCompletions, machine}) => {
    return (coursesCompletions.map(course_completion => {
                return <Accordion
                    key={`${machine.id}-employee-${course_completion.id}-completion`}
                    isExpanded={false}
                    isExpandable={true}
                    header={`Курс: ${course_completion.course.name}`}
                    body={
                        course_completion.requiredModules?.map(module_completion =>
                            <EmployeeModuleCompletion
                                moduleCompletion={module_completion}
                            />)
                    }
                />
            }
        )
    )
}