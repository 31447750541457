import classes from "./Lists.module.css";
import {RatingBranchUnitCard} from "../cards/RatingBranchUnitCard";

export const ListRatingBranchUnits = ({units}) => {
    return (
        <div className={classes.listRatingUnitsContainer}>
            {units.map((unitEntry, index) => {
                    return <div className={"col-12 col-xxl-3 col-md-4 p-3"}
                                key={`${index}. RatingBranchUnitCard__container`}>
                        <RatingBranchUnitCard
                            unitCardId={index}
                            delay={index * 50}
                            unitName={unitEntry.name}
                            completedEmployees={unitEntry.completedEmployees}
                            uncompletedEmployees={unitEntry.uncompletedEmployees}
                        />
                    </div>
                }
            )}
        </div>
    )
        ;
}