import React, {useContext, useEffect, useState} from 'react';
import {FilterContext, UiContext} from "../../../../../context";
import classes from "./../SupervisorDashboards.module.css"
import ListSelect from "../../../select/ListSelect";
import {useSelector} from "react-redux";
import {selectCourses} from "../../../../../features/educationUnits/unitsSlice";
import MachinesSelect from "../../../select/MachinesSelect";

const RatingFilters = ({onChange, parenFilters}) => {

    const {ui} = useContext(UiContext);
    const courses = useSelector(selectCourses)

    const {filters} = useContext(FilterContext);
    const [filtersState, setFiltersState] = useState(filters);

    const handleCoursesChange = (courses) => {
        setFiltersState(prevState => ({...filtersState, courses: courses}));
    }

    const handleFiltersStateChange = (newState) => {
      setFiltersState(prevState => ({...prevState, ...newState}))
    }

    useEffect(() => {
        onChange(filtersState)
    }, [filtersState]);

    return (
        ui.units === "machines" ?
            <div className={classes.opacityContainer}>
                <MachinesSelect onChange={handleFiltersStateChange}/>
            </div> :
            <div className={classes.opacityContainer} style={{height: "40%"}}>
                <ListSelect
                    searchPlaceholderText={"Поиск по курсам..."}
                    items={courses}
                    onItemsChange={handleCoursesChange}
                />
            </div>


    );
};

export default RatingFilters;