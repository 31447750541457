import React, {useEffect, useState} from 'react';
import BackButton from "../../button/BackButton";
import './../../../../App.css'
import {selectCoursesCompletions, selectModulesCompletions} from '../../../../features/completions/completionsSlice'
import CourseProgress from "./CourseProgress";
import ModuleProgress from "./ModuleProgress";
import {useSelector} from "react-redux";
import {isArray} from "@apollo/client/utilities";
import Marquee from "react-fast-marquee";
import {motion} from "framer-motion"


const ExpandCategoryCard = ({divRef, collapseCard, category}) => {

    const coursesCompletions = useSelector(selectCoursesCompletions)
    const modulesCompletions = useSelector(selectModulesCompletions)
    const [selectedCourse, setSelectedCourse] = useState({})
    const [currentModules, setCurrentModules] = useState([])

    useEffect(() => {
        selectedCourse !== undefined && isArray(modulesCompletions) &&
        setCurrentModules(modulesCompletions.filter(
            module => module.course === selectedCourse.course
        ))
    }, [selectedCourse, modulesCompletions])

    useEffect(() => {
        if (isArray(coursesCompletions)) setSelectedCourse(coursesCompletions.filter(course => course.category === category.id)[0])
    }, [coursesCompletions])

    function getTotalAward(category) {
        let total = 0
        if (isArray(coursesCompletions)) coursesCompletions.map(course => {
            if (course.completed && course.category == category.id) total += course.award
        })
        if (isNaN(total)) return 0
        return total
    }

    return (
        <div ref={divRef} className='expandedCard px-3' style={{transition: ".3s"}}>
            {isArray(coursesCompletions) && coursesCompletions.filter(course => course.category === category.id).length === 0 ?
                <div className={"text-light w-100"}>
                    <div className="row align-items-center sticky-top px-0 pt-3 pe-3">
                        <BackButton onClick={() => collapseCard()}/>

                        <span className="col-auto h3 ms-2 m-0">
                                {category.name}</span>
                    </div>
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <span className="lead">(нет доступных курсов)</span>
                    </div>

                </div> :
                <div className="d-flex flex-column w-100">
                    {/*Заголовок, кнопка выйти*/}
                    <div className="row align-items-center sticky-top px-0 pt-3 justify-content-between gap-1">
                        <div className="col-1"><BackButton onClick={() => collapseCard()}/></div>
                        <div className="col">
                                <span className="d-flex d-lg-none w-100 h3 h-sm-3 ms-2 m-0 text-white">
                                    <Marquee style={{overflow: "hidden"}}>{category.name}&nbsp;</Marquee>
                                </span>
                            <span className="d-none d-lg-flex w-100 h3 h-sm-3 ms-2 m-0 text-white">
                                    {category.name}
                                </span>
                        </div>
                        <div className="col d-flex justify-content-end align-items-center" style={{color: '#8cc06d'}}>
                            <span className="totalReward px-1 d-flex align-items-center justify-content-center h4 m-0 h-sm-6 bold">+{getTotalAward(category).toLocaleString("de")} ₽</span>
                        </div>
                    </div>
                    {/*Тело карточки*/}
                    {<div className="row mt-lg-2">

                        {/*Курсы*/}
                        <div className='col-12 col-lg-6 expandedCard__courses px-3 px-lg-4 d-flex flex-column'>
                            {isArray(coursesCompletions) &&
                                coursesCompletions.filter(course => course.category === category.id).map(course =>
                                    <CourseProgress
                                        key={course.id}
                                        course={course}
                                        onClick={
                                            () => {
                                                if (selectedCourse === course) setSelectedCourse({});
                                                else setSelectedCourse(course)
                                            }}
                                        selected={course === selectedCourse}/>
                                )}
                        </div>

                        {/*Модули*/
                            selectedCourse === {} ?
                                <div className={"col-lg-6 col-12 d-flex align-items-center justify-content-center"}>
                                    <span className={"text-secondary"}>
                                        Выберите курс
                                    </span>
                                </div>
                                :
                                currentModules.length !== 0 ?
                                    <div className='col-lg-6 col-12 px-3 px-lg-4 h-100 mt-3 mt-lg-0'>
                                        <div className="expandedCard__activities row justify-content-center">
                                            <div className={"d-flex w-100 justify-content-between bg-header-linear pt-2 sticky-top px-3 position-sticky"} style={{left: "0", zIndex: 10}}>
                                                <div className="col" >
                                                    <h4 className={"text-light mb-0 align-items-start h-sm-6 px-0 bold"} >
                                                        {selectedCourse.course_name}
                                                    </h4>
                                                </div>
                                                <div className="col-auto d-flex justify-content-end align-items-baseline">
                                                    <span className={"h6 align-items-center mb-0 text-center"}
                                                          style={{color: selectedCourse.completed ? "#8CC06D" : "#DD5757"}}
                                                          title={Math.round(selectedCourse.grade) + "%"}>
                                                        {selectedCourse.completed ? "Пройден" : "Не пройден"}
                                                    </span>
                                                </div>

                                            </div>
                                            <div className={"d-flex row align-items-start justify-content-between flex-lg-wrap flex-nowrap h-50 pb-3"}>
                                                {currentModules?.sort((a, b) => b.modname.localeCompare(a.modname)).map(module => <ModuleProgress key={module.id} tooltipKey={module.id} module={module}
                                                                                                                                                  shouldHighlight={selectedCourse?.course_condition === "all_modules" || module.modname === "scorm"}/>)}
                                            </div>
                                        </div>

                                    </div> : <div className={"col-lg-6 col-12 d-flex align-items-center justify-content-center"}><span className={"text-secondary"}>Модулей не найдено</span></div>
                        }
                    </div>}

                </div>

            }
        </div>);
};

export default ExpandCategoryCard;