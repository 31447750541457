import React, {useContext, useEffect, useState} from 'react';
import classes from "./Progressbars.module.css"
import {useVerticalScaleAnimation} from "../../../hooks/useVerticalScaleAnimation";
import {useNumberAnimation} from "../../../hooks/useNumberAnimation";
import {FilterContext} from "../../../context";

const VerticalProgressbar = (props) => {

    const {filters, setFilters} = useContext(FilterContext);
    const value = useNumberAnimation(0, props.value, 300 + props.delay);

    const [height, setHeight] = useState(0);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, props.delay - 100)
        setTimeout(() => {
            setHeight(`${(parseInt(props.value) / parseInt(props.totalValue)) * 100}%`)
        }, props.delay)
    }, [props.value])

    return (
        <div
            onClick={() => props.onClick()}
            className={(filters.selectedBar && props.id === filters.selectedBar.id) ? [classes.verticalProgressbar__wrapper, classes.active].join(" ") : classes.verticalProgressbar__wrapper}
            style={{opacity: opacity, transition: ".3s"}}
        >
            <div className={classes.verticalProgressbar__background}>
                <div
                    className={classes.verticalProgressbar__bar}
                    style={{
                        height: height,
                        background: `linear-gradient(180deg, ${props.color} 0%, rgba(79, 87, 96, 0) 90%)`
                    }}>
                    <span className={classes.verticalProgressbar__value}>{value}</span>
                </div>
                <span className={classes.verticalProgressbar__label}>{props.label}</span>
            </div>
        </div>
    );
};

export default VerticalProgressbar;