import {API_URL} from "../_helpers_/constants";
import {fetchWithAuth} from "../_helpers_/commands";

export default class MachinesService {

    // static async getAllProducers() {
    //
    //     return fetchWithAuth(API_URL + '/producer/', {
    //         method: 'GET',
    //     })
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 const data = response.json()
    //                 return Promise.resolve(data);
    //             }
    //             return Promise.reject();
    //         });
    //
    // }

    static async getAllMachineTypes() {
        return fetchWithAuth(API_URL + '/machine_type/', {
            method: 'GET',
        })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.json()
                    return Promise.resolve(data);
                }
                return Promise.reject();
            });
    }


}