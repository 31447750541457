import React, {useEffect, useState} from 'react';
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from "./Buttons.module.css"

const FoldButton = (props) => {

    const [rotate, setRotate] = useState(props.direction ? 0 : 180)

    const handleClick = () => {
        props.onClick()
    }


    useEffect(() => {
        setRotate(props.direction ? 0 : 180)
    }, [props.direction]);

    return (
        <button {...props} className={classes.foldButton} onClick={() => handleClick()}>
            <FontAwesomeIcon icon={faAngleUp} style={{transform: `rotate(${rotate}deg)`, transition: ".2s .3s"}}/>
            <p className={"ms-2 mb-0"}>
            {rotate === 180 ? "Свернуть" : "Развернуть"}
            </p>
        </button>
    );
};

export default FoldButton;