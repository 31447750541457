import { useState, useEffect } from "react";

export function useAnimation(initialScale) {
    const [scale, setScale] = useState(initialScale);

    useEffect(() => {
        setScale(1);
    }, []);

    const resetScale = () => {
        // const delay = Math.random() * 1000;
        setTimeout(() => {
            setScale(initialScale);
        }, 1);
    };

    return { scale, resetScale };
}
