import React, {useContext, useEffect, useState} from 'react';
import classes from "../SupervisorDashboards.module.css";
import {HorizontalSelect} from "../../../select/HorizontalSelect";
import Input from "../../../input/Input";
import {LabeledSwitch} from "../../../switch/LabeledSwitch";
import OutlineButton from "../../../button/OutlineButton";
import ListSelect from "../../../select/ListSelect";
import MyModal from "../../../modal/MyModal";
import DateRangePicker from "../../../select/DateRangePicker";
import {FilterContext, UiContext} from "../../../../../context";
import MachinesSelect from "../../../select/MachinesSelect";
import {getDateRange, getRangeText} from "../../../../../utils/helpers";
import {useSelector} from "react-redux";
import {selectCourses} from "../../../../../features/educationUnits/unitsSlice";
import {DatepickerModal} from "../../../modal/DatepickerModal";

const DynamicFilters = ({onChange, parenFilters}) => {
    const {ui, setUi} = useContext(UiContext)
    const {filters, setFilters} = useContext(FilterContext);

    const [filtersState, setFiltersState] = useState({dateRange: filters.dateRange});
    const [isCustomRange, setIsCustomRange] = useState(false);
    const [fastRange, setFastRange] = useState({rangeType: 0, rangeSize: 0});

    const courses = useSelector(selectCourses)

    const handleUnfinished = () => {
        setFilters(prev => ({...prev, showUnfinished: !filters.showUnfinished}))
    }

    const rangeTypes = [
        {id: 0, title: "Этот"},
        {id: 1, title: "Прошлый"},
    ]

    const rangeSizes = [
        {id: 0, title: "Месяц"},
        {id: 1, title: "Квартал"},
    ]

    const handleRangeTypeChange = (rangeType) => {
        setIsCustomRange(false)
        setFastRange({...fastRange, rangeType: rangeType})
    }

    const handleRangeSizeChange = (rangeSize) => {
        setIsCustomRange(false)
        setFastRange({...fastRange, rangeSize: rangeSize})
    }

    const handleCoursesChange = (newCourses) => {
        if (newCourses.length === 0) {
            setFiltersState(prev => ({...prev, courses: courses}))
        } else {
            setFiltersState(prev => ({...prev, courses: newCourses}))
        }
    }

    const handleDatepickerModalClose = (value) => {
        setUi(prev => ({...prev, showDatepickerModal: value}))
    }

    const handleCustomRangeChange = (value) => {
        setIsCustomRange(true)
        setFiltersState(prev => ({...prev, dateRange: value.dateRange}))
    }

    useEffect(() => {
        setIsCustomRange(false)
        setFiltersState(prev => ({...prev, dateRange: getDateRange(fastRange.rangeType, fastRange.rangeSize)}))
    }, [fastRange]);

    useEffect(() => {
        if (fastRange.rangeSize === undefined) setFastRange({...fastRange, rangeSize: 0})
    }, [fastRange.rangeSize]);

    useEffect(() => {
        onChange(filtersState)
    }, [filtersState]);

    return (
        <>
            {ui.units === "machines" &&
                <div className={classes.opacityContainer}>
                    <MachinesSelect filters={parenFilters}/>
                </div>
            }

            <DatepickerModal
                active={ui.showDatepickerModal}
                onChange={handleCustomRangeChange}
                setActive={handleDatepickerModalClose}
            />

            <div className={classes.opacityContainer}>
                <div className={"pt-2 px-2"}>
                    <HorizontalSelect
                        items={rangeTypes}
                        active={!isCustomRange}
                        onChange={handleRangeTypeChange}/>
                </div>
                <div className={"pt-2 px-2"}>
                    <HorizontalSelect
                        items={rangeSizes}
                        active={!isCustomRange}
                        onChange={handleRangeSizeChange}/>
                </div>
                <div className={"py-2 px-2"}>
                    <button className={isCustomRange === true ? [classes.otherRangeBtn, classes.active].join(" ") : classes.otherRangeBtn} onClick={() => setUi({...ui, showDatepickerModal: true})}>
                        {getRangeText(filtersState.dateRange)}
                    </button>
                </div>
            </div>

            <div className={classes.opacityContainer}>
                <LabeledSwitch title={"Незавершенные"} isChecked={false} onChange={handleUnfinished}/>
            </div>

            {ui.units === "modules" &&
                <div className={classes.opacityContainer} style={{height: "50%"}}>
                    <ListSelect
                        searchPlaceholderText={"Поиск по курсам..."}
                        items={courses}
                        onItemsChange={handleCoursesChange}
                    />
                </div>
            }



        </>
    );
};

export default DynamicFilters;