import {gql} from '@apollo/client'
import {CORE_GET_MODULES_TABLE_HEADER} from "./modules";

// ************************** TABLE-SCREEN FUNCTIONS ************************** //

//Получение шапки
export const CORE_GET_MACHINES_TABLE_HEADER = gql`
    query ($types: [Int] = null) {
        machineTypes (types: $types) {
            id,
            name
        }
    }
`

// Получение прохождений:
export const CORE_GET_MACHINES_TABLE_CONTENT = gql`
    query ($types: [Int] = null) {
        machinesTable (types: $types) {
            employee,
            branch,
            completions {
                completed,
                detail
            }
        }
    }
`

export const GET_MACHINE_COMPLETIONS = gql`query ($types: [Int] = null, $employee: Int = null, $branch: Int = null) {
        machineSummary(types: $types) {
            id,
            name, 
            completions (employee: $employee, branch: $branch) {
                employee {
                    id,
                    firstname,
                    lastname,
                    branches {
                        id,
                        name
                    }
                },
                completed,
                dateCompleted,
                currentRate
            }
        }
    }`

export const GET_MACHINE_COMPLETIONS_DETAIL = gql`query ($employee: Int, $types: [Int] = null) {
    employeeMachinesCompletions(employee: $employee, types: $types) {
        id,
        machineType {
            name
        },
        completed, 
        dateCompleted,
        currentRate,
        rates {
            rate,
            moduleCompletion {
                id, 
                module {
                    id,
                    name,
                    modname
                },
                grade,
                completed
            },
            coursesCompletions {
                course {
                    name
                },
                grade,
                completed,
                requiredModules {
                    id, 
                    module {
                        id,
                        name,
                        modname
                    },
                    grade,
                    completed
                }
            }
        }
    }
}`


// ************************** DYNAMIC-SCREEN FUNCTIONS ************************** //
export const CORE_GET_MACHINES_SUMMARY = gql`
    query ($branches: [Int] = null, $dateBegin: Date = null, $dateEnd: Date = null, $types: [Int] = null) {
        machineSummary(types: $types) {
            id,
            name,
            completedCount(branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd),
            uncompletedCount(branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd)
        }
    }
`

export const CORE_GET_MACHINES_EMPLOYEES_DETAIL = gql`
    query ($branches: [Int] = null, $machineType: Int, $dateBegin: Date = null, $dateEnd: Date = null) {
        machineEmployees (machineType: $machineType, dateBegin: $dateBegin, dateEnd: $dateEnd, branches: $branches) {
            id,
            firstname, 
            lastname,
            branches {
                name
            }
        }
    }
`

export const CORE_GET_MONTH_PROGRESS_BY_MACHINE = gql`
    query ($branches: [Int] = null, $dateBegin: Date = null, $dateEnd: Date = null, $types: [Int] = null) {
        machineSummary (types: $types) {
            id,
            name,
            monthlyProgress (branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd) {
                month,
                count
            },
            uncompletedEmployees (branches: $branches, dateBegin: $dateBegin, dateEnd: $dateEnd) {
                id,
                firstname,
                lastname,
                branches {
                    id,
                    name
                }
            }
        }
    }
`

// ************************** RATING-SCREEN FUNCTIONS ************************** //

export const GET_BRANCHES_RATING_BY_MACHINES = gql`query ($branches: [Int] = null, $types: [Int] = null) {
    ratingBranchesByMachines(branches: $branches, types: $types) {
        branch {
            id,
            name
        },
        employeesCount,
        completedCount,
        uncompletedCount
    }
}`

export const GET_BRANCH_EMPLOYEES_MACHINES_COMPLETIONS = gql`query ($branches: [Int] = null, $types: [Int] = null) {
    branchEmployeesMachinesCompletions (branches: $branches, types: $types) {     
        name
        completedEmployees (branches: $branches) {
            employee {
                firstname,
                lastname
            },
            dateCompleted
        },
        uncompletedEmployees (branches: $branches) {
            firstname,
            lastname
        }
    }
}`

export const GET_EMPLOYEES_RATING_BY_MACHINES = gql`query ($branches: [Int] = null, $types: [Int] = null) {
    ratingEmployeesByMachines(branches: $branches, types: $types) {
        employee {
            id,
            firstname,
            lastname,
            branches {
                name
            }
        },
        completedCount,
        uncompletedCount
    }
}`

export const GET_EMPLOYEE_MACHINES_COMPLETIONS = gql`query ($employee: Int, $types: [Int] = null) {
    employeeMachinesCompletions(employee: $employee, types: $types) {
        machineType {
            name
        },
        completed, 
        dateCompleted
    }
}`

export const GET_MACHINE_TYPES = gql`query ($types: [Int] = null) {
    machineSummary (types: $types){
        id,
        name
    }
}`