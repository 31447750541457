import React, {useContext, useEffect, useState} from 'react';
import OrderButton from "../button/OrderButton";
import Input from "../input/Input";
import classes from "./Select.module.css";
import {FilterContext, UiContext} from "../../../context";

const SortingSelect = (props) => {

    const {ui, setUi} = useContext(UiContext)
    const {filters, setFilters} = useContext(FilterContext)
    const [selectedType, setSelectedType] = useState({id: -1});


    useEffect(() => {
        setFilters(prev => ({...prev, field: selectedType.field}))
    }, [selectedType, ui.dashboardType]);

    return (
        <div className={"d-flex w-100 align-items-center"}>
            {ui.dashboardType !== "rating" && <OrderButton color={"yellow"}/>}
            {props.sortingFields.map((field, index) =>
                    field.title && <div key={field.id + field.title} className={(ui.dashboardType !== "rating" || index !== 0) ? "ms-2" : ""}>
                        <button
                            className={selectedType.id !== field.id ? classes.fieldButton : [classes.fieldButton, classes.active].join(" ")}
                            onClick={() => {
                                setSelectedType(prevField => field);
                                if (field.onClick) field.onClick()
                            }}>
                            {field.title}
                        </button>
                    </div>
            )}
            {props.search &&
                <div className={"ms-2"}><Input icon={true} placeholder={props.placeholder ? props.placeholder : "Поиск..."} onChange={props.onSearch}/></div>
            }
        </div>
    );
};

export default SortingSelect;