import React, {useContext, useEffect, useState} from 'react';
import ConversionBar from "./ConversionBar";
import {FilterContext, UiContext} from "../../../context";
import {getRangeText} from "../../../utils/helpers";
import {Center} from "../Center";
import Loader from "../loader/Loader";


const ListConversionBars = ({conversions, selectedSort}) => {

    const {filters, setFilters} = useContext(FilterContext);
    const {ui, setUi} = useContext(UiContext)

    const [sortedConversions, setSortedConversions] = useState(conversions);
    const [selectedConversion, setSelectedConversion] = useState(null);

    const switchSelectedOrder = () => {
        setSortedConversions([...sortedConversions].reverse())
    }

    const getMaxCompletedUncompletedSum = (conversions) => {
        let maxSum = 0;

        for (let i = 0; i < conversions.length; i++) {
            const {completedCount, uncompletedCount} = conversions[i];
            const sum = completedCount + uncompletedCount;
            if (sum > maxSum) {
                maxSum = sum;
            }
        }

        return maxSum;
    };

    const maxSum = getMaxCompletedUncompletedSum(conversions)


    const sortConversions = (field, order) => {
        let sorted;

        if (field === 'completed') {
            sorted = [...sortedConversions].sort((a, b) => {
                return order === 'desc' ? a.completedCount - b.completedCount : b.completedCount - a.completedCount;
            });
        } else if (field === 'uncompleted') {
            sorted = [...sortedConversions].sort((a, b) => {
                return order === 'desc' ? a.uncompletedCount - b.uncompletedCount : b.uncompletedCount - a.uncompletedCount;
            });
        } else {
            sorted = [...sortedConversions].sort((a, b) => {
                const sumA = a.completedCount + a.uncompletedCount;
                const sumB = b.completedCount + b.uncompletedCount;
                return order === 'desc' ? sumA - sumB : sumB - sumA;
            });
        }
        setSortedConversions(sorted);
    };

    const handleBarSelect = (conversionBar, completed) => {
        console.log(conversionBar)
        if (!completed && filters.showUnfinished || completed) {
            setFilters(prev => ({...prev, showGreen: completed}))
            setSelectedConversion(conversionBar);
            setUi({...ui, bottomInfoFolded: false})
        }
    }

    useEffect(() => {
        if (ui.units === "modules") {
            setFilters(prev => ({...prev, selectedModule: selectedConversion}))
        } else if (ui.units === "courses") {
            setFilters(prev => ({...prev, selectedCourse: selectedConversion}))
        } else if (ui.units === "machines") {
            setFilters(prev => ({...prev, selectedMachine: selectedConversion}))
        }
    }, [selectedConversion]);

    useEffect(() => {
        setSortedConversions(conversions)
    }, [conversions])

    useEffect(() => {
        sortConversions(filters.field, filters.order)
    }, [filters.order, filters.field, filters.dateRange, ui.units]);

    useEffect(() => {
        setFilters(prev => ({...prev, order: "asc", field: "completed"}))
    }, []);

    return (
        <div className={"d-flex w-100 h-100 flex-column pb-5"}>
            {
                // loading ? <Center><Loader/></Center> :
                sortedConversions.reduce((acc, current) => acc + current.completedCount, 0) === 0
                    ? <div className={"w-100 d-flex justify-content-center align-items-center h-100 text-secondary"}>
                        Данных за {getRangeText(filters.dateRange)} не найдено
                    </div>
                    : sortedConversions.map((conversion, index) =>
                        conversion.completedCount !== 0 &&
                        <div key={conversion.id} className={"d-flex flex-nowrap flex-column mt-3"}>
                            <ConversionBar
                                id={conversion.id}
                                title={conversion.name}
                                conversion={conversion}
                                completed={conversion.completedCount}
                                uncompleted={conversion.uncompletedCount}
                                maxSum={maxSum}
                                conversions={conversions}
                                delay={100 + index * 100}
                                onSelectBar={handleBarSelect}
                            />
                        </div>
                    )
            }
        </div>
    );
};

export default ListConversionBars;