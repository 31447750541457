import {getProgressbarColor} from "../../../utils/helpers";
import classes from "./Cards.module.css";
import LinearProgressbar from "../progressbars/LinearProgressbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Tooltip} from "antd";

export const EmployeeModuleCompletion = ({moduleCompletion, delay}) => {

    const grade = parseFloat(moduleCompletion?.grade)
    const color = getProgressbarColor(grade, moduleCompletion?.completed)
    return <div
        className={classes.moduleProgressbar__wrapper}>
        <LinearProgressbar value={grade} completed={moduleCompletion?.completed} delay={delay} color={color}/>
        <b style={{color: color}}>{grade}%</b>
        <Tooltip title={moduleCompletion?.module?.name.length > 43 && moduleCompletion?.module?.name}><span
            className={classes.moduleProgressbar__title}
            style={{opacity: !moduleCompletion?.completed ? "0.5" : "1"}}>
                                 {moduleCompletion?.module?.name}
                            </span></Tooltip>

        <button
            className={[classes.moduleLinkBtn, "btn position-absolute"].join(" ")}
            style={{backgroundColor: color, right: 0, zIndex: 20, borderRadius: ".5rem 0 0 .5rem"}}
            onClick={() => {
                window.open("http://school.tmbk.local/mod/" + moduleCompletion?.module?.modname?.toLowerCase() + "/view.php?id=" + moduleCompletion?.module.id)
            }}
        >
            <FontAwesomeIcon color={"#fff"} icon={faChevronRight}/>
        </button>
    </div>
}
