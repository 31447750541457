import React, {createContext, useEffect, useState} from 'react';
import {getDateRange} from "../utils/helpers";
import {useSelector} from "react-redux";
import {selectCategories, selectCourses} from "../features/educationUnits/unitsSlice";
import {selectBranches, selectUsers} from "../features/users/usersSlice";
import {selectLoggedIn, selectUserData} from "../features/auth/authSlice";
import {Center} from "../components/UI/Center";
import {useNavigate} from "react-router-dom";

export const UiContext = createContext();

export const UiProvider = ({children}) => {
    const [ui, setUi] = useState({
        units: 'courses',
        dashboardType: 'dynamic',
        bottomInfoFolded: true,
        showMachinesModal: false,
        showDatepickerModal: false,
        hoveredTd: null,
        animateApplyBtn: true,
        mode: "employees"
    });

    return (
        <UiContext.Provider value={{ui, setUi}}>
            {children}
        </UiContext.Provider>
    );
};

export const FilterContext = createContext();

export const FilterProvider = ({children}) => {

    const user = useSelector(selectUserData)
    const users = useSelector(selectUsers)
    const isLoggedIn = useSelector(selectLoggedIn)

    const categories = useSelector(selectCategories)
    const courses = useSelector(selectCourses)

    let branches = useSelector(selectBranches)
    if (user.groups?.includes("supervisor") && !user.groups?.includes("admin")) {
        branches = user.branches
    }

    const [filters, setFilters] = useState({

        //attemptsFilter
        page: 1,
        type: 0, //тип сравнения (по попыткам или по времени)
        compare: 0, //знак сравнения (больше/меньше)
        value: null, //значение, на которое фильтруются попытки
        isOpen: false, //развёрнуты ли все отображаемые попытки
        enableAttemptsFilter: false,
        showOnlyCompleted: false,
        searchedEmployee: "",

        //ratingFilter
        selectedBranch: null,
        selectedEmployee: null,

        //dynamicFilter
        dateRange: getDateRange(0, 0), //временной промежуток
        showUnfinished: false, //показывать незавершенные или нет
        selectedCourse: null, //выбранный курС
        selectedCourses: courses, //выбранные курсЫ
        selectedModule: null, //выбранный модуль
        selectedBar: null, //выбранный вертикальный бар
        showGreen: null,

        //global
        branches: branches, //выбранные филиалы
        order: "asc", //порядок сортировки
        field: "completed", //поле сортировки

        //table
        selectedTypes: [],
        // selectedBrands: [],
        selectedCategories: categories,
        employeesSort: false,
        branchesSort: false,
        sortBy: null,
        selectedMachine: {
            machineType: null,
            // producer: null,
        }
    });

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            selectedCategories: categories,
        }));
    }, [categories]);

    return (
        <FilterContext.Provider value={{filters, setFilters}}>
            {children}
        </FilterContext.Provider>
    );
};

export const UserContext = createContext();

export const UserProvider = ({children}) => {

    const userData = useSelector(selectUserData)

    const [user, setUser] = useState({
        role: userData.groups?.includes("admin") ? "admin" : userData.groups?.includes("supervisor") ? "supervisor" : "engineer",
        groups: userData.groups,
        name: userData.name,
        branches: userData.branches,
        id: userData.employee?.id
    })

    useEffect(() => {
        setUser(prev => ({
            role: userData.groups?.includes("admin") ? "admin" : userData.groups?.includes("supervisor") ? "supervisor" : "engineer",
            groups: userData.groups,
            name: userData.name,
            branches: userData.branches,
            id: userData.employee?.id
        }))
    }, [userData])

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );

}