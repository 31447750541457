import {useContext, useEffect, useState} from "react";
import {FilterContext, UiContext} from "../../../context";
import {COURSES, MODULES} from "../../../utils/constants";
import classes from "../lists/Lists.module.css";
import {getProgressbarColor} from "../../../utils/helpers";

export const RatingUnitEntry = ({unitEntry, delay}) => {


    const {ui} = useContext(UiContext);
    const {filters} = useContext(FilterContext);


    const [opacity, setOpacity] = useState(0);
    let unit;
    const date = new Date(unitEntry.dateCompleted);

    if (ui.units === MODULES) {
        unit = unitEntry.module;
    } else if (ui.units === COURSES) {
        unit = unitEntry.course;
    } else {
        unit = unitEntry.machineType;
    }


    useEffect(() => {
        setOpacity(0)
        setTimeout(() => setOpacity(1), delay)
    }, [filters.selectedEmployee]);


    return (<div className={classes.unitProgress} style={{transform: `scaleY(${opacity})`}}>
        <div className="col-3 text-start">
            <span>
              {unitEntry.dateCompleted && date.toLocaleString("ru", {
                  year: "numeric", month: "short", day: "numeric",
              })}
            </span>
        </div>
        <div className="col-7 text-start">
            <span className="text-light text-standard text-start">
                {unit.name}
            </span>
        </div>
        <div className="col-2" style={{color: getProgressbarColor(unitEntry.grade, unitEntry.completed)}}>
            <span>{unitEntry.grade && parseFloat(unitEntry.grade).toFixed(1) + " %"}</span>
        </div>
    </div>);
};


