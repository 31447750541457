import React, {useContext, useEffect, useRef, useState} from 'react';
import Attempt from "../accordion/Attempt";
import classes from "./../dashboard/supervisor/SupervisorDashboards.module.css"
import buttons from "./../button/Buttons.module.css"
import {useQuery} from "@apollo/client";
import {GET_ATTEMPTS} from "../../../graphql/attempts";
import {FilterContext, UiContext} from "../../../context";
import {Center} from "../Center";
import Loader from "../loader/Loader";
import {useObserver} from "../../../hooks/useObserver";
import Input from "../input/Input";
import useDebounce from "../../../hooks/useDebounce";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * Компонент, отвечающий за отображение списка попыток прохождения сотрудниками учебных модулей.
 * Включает в себя фильтрацию по количеству/времени попыток, а также поиск по ФИО сотрудника и просмотр попыток сотрудников из конкретных филиалов компании.
 * Список попыток получается при помощи jql запроса GET_ATTEMPTS с соответствующими параметрами
 * @returns {JSX.Element}
 * @constructor
 */
const AttemptsList = () => {

    const {filters} = useContext(FilterContext); //контекст с выбранными значениями в фильтрах
    const lastElement = useRef(); //ссылка на последний элемент на странице для хука useObserver

    const [sortedAttempts, setSortedAttempts] = useState([]); //список попыток, выводимый на странице
    const [allAttemptsOpened, setAllAttemptsOpened] = useState(false); //флаг, отвечающий за открытие всех попыток
    const [attemptsCount, setAttemptsCount] = useState(0); //общее количество попыток
    const [totalPages, setTotalPages] = useState(0); //общее количество страниц с попытками
    const limit = 100; //количество попыток, отображаемое на странице (размер подгружаемой порции)

    const [variables, setVariables] = useState({ //состояние, отвечающее за выбранные параметры поиска
        page: 1, //отображаемая страница
        employeeNameFilter: null, //поиск сотрудника по ФИО
        moreCount: null, //количество попыток, > чем...
        lessCount: null, //количество попыток, < чем...
        moreTime: null, //количество времени, > чем...
        lessTime: null, //количество времени, < чем...
        limit: limit, //размер подгружаемой порции
        branches: filters.branches.map(branch => branch.id) //филиалы
    });

    const attempts = useQuery(GET_ATTEMPTS, {
        variables: variables, fetchPolicy: 'network-only',
        onCompleted: (d) => {//по выполнении запроса
            setAttemptsCount(d.attempts.totalCount); //устанавливаем количество попыток
            setSortedAttempts([...d.attempts.data]); //в конец списка попыток разворачиваем полученный массив
        }
    });

    /**
     * Функция, сбрасывающая отображаемый массив
     */
    const clearSortedAttempts = () => {
        setSortedAttempts([])
    }

    const debouncedSearch = useDebounce(searchEmployee, 700); //отправка запроса на поиск сотрудника после 500мс задержки в наборе ФИО

    /**
     * Функция для поиска сотрудника по ФИО
     * по сути просто устанавливает значение фильтра в соответствие со значением, переданным в query
     * после чего улетает jql запрос из-за изменившегося стейта variables
     * @param query строка для поиска по фио сотрудника
     */
    function searchEmployee(query) {
        // clearSortedAttempts()
        setVariables(prevVariables => ({
            ...prevVariables,
            employeeNameFilter: query.toLowerCase(),
            page: 1
        }))

    }

    /**
     * Хук, обновляющий номер страницы, когда lastElement попадает в область видимости
     * позволяет увеличить номер страницы на 1, если текущий номер страницы меньше общего кол-ва страниц
     */
    // useObserver(lastElement, variables.page < totalPages, attempts.loading, () => {
    //     setVariables(prevVariables => ({
    //         ...prevVariables,
    //         page: prevVariables.page + 1
    //     }))
    //
    // });

    /***
     * Функция, устанавливающая значения параметров поиска в изначальные
     */
    const clearVariables = () => {
        setVariables({
            ...variables, //состояние, отвечающее за выбранные параметры поиска
            page: 1, //отображаемая страница
            // employeeNameFilter: null, //поиск сотрудника по ФИО
            moreCount: null, //количество попыток, > чем...
            lessCount: null, //количество попыток, < чем...
            moreTime: null, //количество времени, > чем...
            lessTime: null, //количество премени, < чем...
            limit: limit, //размер подгружаемой порции
            branches: filters.branches.map(branch => branch.id) //филиалы
        })
    }


    const setPage = (value) => {
        setVariables(prevVariables => ({
            ...prevVariables,
            page: value
        }))
    }

    const changePage = (value) => {
        setVariables(prevVariables => ({
            ...prevVariables,
            page: prevVariables.page + value
        }))
    }

    const debouncedChangePage = useDebounce(changePage, 500)


    /**
     * Функция, которая очищает ВСЕ параметры, а затем применяет только один из четырёх
     * для вывода информации в соответствии с выбранными значениями в фильтрах
     */
    const applyVariables = () => {
        if (filters.value && filters.enableAttemptsFilter) {
            clearVariables()
            const branches = filters.branches.map(b => b.id)
            // clearSortedAttempts()
            if (filters.type === 0) {
                if (filters.compare === 0) {
                    // время больше ${filters.value}
                    clearVariables()
                    setVariables({...variables, moreCount: null, lessCount: null, moreTime: filters.value, lessTime: null, branches: branches})
                } else {
                    // время меньше ${filters.value}
                    clearVariables()
                    setVariables({...variables, moreCount: null, lessCount: null, moreTime: null, lessTime: filters.value, branches: branches});
                }
            } else {
                if (filters.compare === 0) {
                    // попыток больше ${filters.value}
                    clearVariables()
                    setVariables({...variables, moreCount: filters.value, lessCount: null, moreTime: null, lessTime: null, branches: branches})
                } else {
                    // попыток меньше ${filters.value}
                    clearVariables()
                    setVariables({...variables, moreCount: null, lessCount: filters.value, moreTime: null, lessTime: null, branches: branches});
                }
            }
        }
    }


    useEffect(() => {
        if (attemptsCount !== 0) setTotalPages(prevState => (Math.ceil(attemptsCount / limit))); //устанавливаем количество страниц
    }, [attemptsCount]);


    useEffect(() => {
    }, [filters.value]);

    useEffect(() => {
        // clearSortedAttempts()
        clearVariables()
        applyVariables()
        setVariables(prevVariables => ({
            ...prevVariables,
            page: 1
        }))
    }, [filters.type, filters.compare, filters.value, filters.branches]);

    useEffect(() => {
        setAllAttemptsOpened(filters.isOpen)
    }, [filters.isOpen]);

    useEffect(() => {
        if (!filters.enableAttemptsFilter) {
            clearVariables()
        } else {
            if (!isNaN(filters.value)) {
                applyVariables()
            }
        }
    }, [filters.enableAttemptsFilter]);

    return (<div className={classes.attemptsContainer}>
        <div className={classes.opacityContainer} style={{width: "100%", height: "100%", overflow: "hidden"}}>
            <div className={"p-2 h-100 overflow-hidden"}>
                <div style={{height: "5%"}} className={"d-flex justify-content-between align-items-center"}>
                    {/*<SortingSelect sortingFields={sortingFields} search={true} placeholder={"Поиск по сотруднику..."} onSearch={handleSearch}/>*/}
                    <div className={""}><Input icon={true} placeholder={"Поиск по сотруднику..."} onChange={(e) => debouncedSearch(e)}/></div>
                    {attemptsCount !== 0 && <div className="flex-row justify-content-center align-items-center">
                        {/*<input className={"form-control"} type="range" step={1} min={1} max={totalPages} onChange={(e) => debouncedSetPage(e.target.value)}/>*/}
                        {variables.page > 1 &&
                            <button className={[buttons.paginationBtn, buttons.prevPage].join(" ")} onClick={() => changePage(-1)}><FontAwesomeIcon icon={faArrowLeft}/></button>}
                        <span className={"mx-2 h5 text-secondary"}>Страница {variables.page} из {totalPages}</span>
                        {variables.page < totalPages &&
                            <button className={[buttons.paginationBtn, buttons.nextPage].join(" ")} onClick={() => changePage(1)}><FontAwesomeIcon icon={faArrowRight}/></button>}
                    </div>}
                    <div className="col-auto">
                        {attemptsCount !== 0 && <span className={"text-secondary h4"}>Записи: {limit * (variables.page - 1) + 1} - {variables.page < totalPages ? limit * variables.page : attemptsCount} из {attemptsCount}</span>}
                    </div>
                </div>
                <div className="mt-3 pe-3" style={{overflowY: "auto", overflowX: "hidden", height: "93%"}}>
                    {attempts.loading
                        ? <Center><Loader/></Center> :
                        attemptsCount === 0 ?
                            <Center><h5 className={"text-secondary"}>По заданным фильтрам нет записей</h5></Center>
                        : filters.enableAttemptsFilter && (filters.value === null || isNaN(filters.value))
                        ? <Center><span className={"text-secondary"}>Для отображения данных введите<br/> количество в фильтре слева</span></Center>
                        : sortedAttempts.map((attempt, i) => {
                        if (filters.showOnlyCompleted && attempt.completed || !filters.showOnlyCompleted)
                        return <Attempt key={`attempt-${i}-${attempt.employee.id}-${attempt.module.id}`} employeeAttempt={attempt} isExpanded={allAttemptsOpened} delay={i < 24 ? i * 50 : 0}/>

                    })}

                    {/*<div ref={lastElement} className={"d-flex justify-content-center"} style={{height: 20}}>{attemptsCount !== 0 && attemptsCount > sortedAttempts.length && <Loader type={"balls"}/>}</div>*/}
                </div>
            </div>
        </div>
    </div>);
};

export default AttemptsList;