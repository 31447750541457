import classes from "./Select.module.css"
import React, {createRef, useEffect, useRef, useState} from "react";
import login from "../../../pages/Login";

export const HorizontalSelect = (props) => {

    const [selectedItemId, setSelectedItemId] = useState(0);
    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(0);

    const containerRef = useRef(null);
    const refs = useRef([]); // создаем массив refs

    const [selectedItemRef, setSelectedItemRef] = useState(refs.current[0]);

    const handleButtonClick = (ref, itemId) => {
        setSelectedItemRef(prevRef => ref);
        if (props.onChange) props.onChange(itemId)
    };

    useEffect(() => {
        if (selectedItemRef) {
            setLeft(selectedItemRef.current.getBoundingClientRect().x - containerRef.current.getBoundingClientRect().x - 16)
            setWidth(selectedItemRef.current.getBoundingClientRect().width + 32)
        }
    }, [selectedItemRef, window.innerWidth]);

    // заполняем массив refs при рендеринге элементов
    useEffect(() => {
        refs.current = props.items.map((item, index) => refs.current[index] || createRef());
    }, [props.items]);

    useEffect(() => {
        setSelectedItemId(0)
        handleButtonClick(refs.current[0], 0)
    }, [])

    return (
        <div ref={containerRef} className={classes.horizontalSelectContainer}>
            {props.items.map((item, index) =>
            {
                return <button
                key={item.id}
                ref={refs.current[index]} // присваиваем отдельный ref для каждого элемента
                className={item.id === selectedItemId && props.active ? [classes.selectedItem, classes.item].join(" ") : classes.item}
                onClick={() => {
                    setSelectedItemId(() => item.id)
                    handleButtonClick(refs.current[index], item.id)
                }}
                >
            {item.title}
                </button>}
            )}
            <div
                className={classes.activeBg}
                style={{left: left, width: width, backgroundColor: props.active ? "#FDAE47" : "rgb(80, 87, 96)"}}
            ></div>
        </div>
    )
}