import React, {useState} from 'react';
import classes from "./Input.module.css"
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Input = (props) => {


    const [query, setQuery] = useState("");

    return (
        <div className={query === "" ? classes.inputContainer : [classes.inputContainer, classes.active].join(" ")}>
            {props.icon && <FontAwesomeIcon icon={faSearch} className={"me-2"} color={query === "" ? "#d9d9d9" : "#FDAE47"}/>}
            <input value={props.value} type={props.type ? props.type : "text"} className={classes.input} placeholder={props.placeholder} onChange={(e) => {setQuery(e.target.value); props.onChange(e.target.value)}}>
            </input>
        </div>
    );
};

export default Input;