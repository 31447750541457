import {fetchWithAuth} from "../features/_helpers_/commands";
import {API_URL} from "../features/_helpers_/constants";
import {logDOM} from "@testing-library/react";

export const levenshteinDistance = (str1, str2) => {
    const m = str1.length;
    const n = str2.length;
    const dp = Array.from({length: m + 1}, () => Array.from({length: n + 1}, () => 0));

    for (let i = 0; i <= m; i++) {
        dp[i][0] = i;
    }

    for (let j = 0; j <= n; j++) {
        dp[0][j] = j;
    }

    for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]) + 1;
            }
        }
    }

    return dp[m][n];
};

export const getRandomFloat = (min, max) => {
    return Math.random() * (max - min) + min;
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatDate = date => {
    if (date !== null) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else return "1970-01-01"
};

export const formatAttemptDate = (dateString) => {
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('ru-RU', options);
    return `${formattedDate}`;
};

function setTime(date, type) {
    if (type === 'start') {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
    } else {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
    }
    return date;
}

function getQuarterDates(date, type) {

    let quarter = type === 0 ? getQuarter(date) : getQuarter(date) - 1;
    let year = date.getFullYear();
    if (quarter === 0) { //если сейчас первый квартал
        quarter = 4; //то возвращаем последний
        year -= 1; //но прошлого года
    }
    let beginDate, endDate;

    if (quarter === 1) {//I	квартал - 1 января 00:00:00	31 марта 23:59:59
        beginDate = new Date(year, 0, 1);
        endDate = new Date(year, 2, 31, 23, 59, 59, 999);
    } else if (quarter === 2) {//II	квартал -	1 апреля 00:00:00	30 июня 23:59:59
        beginDate = new Date(year, 3, 1);
        endDate = new Date(year, 5, 30, 23, 59, 59, 999);
    } else if (quarter === 3) {//III	квартал -	1 июля 00:00:00	 30 сентября 23:59:59
        beginDate = new Date(year, 6, 1);
        endDate = new Date(year, 8, 30, 23, 59, 59, 999);
    } else { //IV	квартал -	1 октября 00:00:00	31 декабря 23:59:59
        beginDate = new Date(year, 9, 1);
        endDate = new Date(year, 11, 31, 23, 59, 59, 999);
    }
    return {
        beginDate: beginDate,
        endDate: endDate,
    };
}

function getQuarter(date) {
    const month = date.getMonth();
    if (month >= 0 && month <= 2) {
        return 1;
    } else if (month >= 3 && month <= 5) {
        return 2;
    } else if (month >= 6 && month <= 8) {
        return 3;
    } else {
        return 4;
    }
}

export const getDateRange = (rangeType, rangeSize) => {
    let beginDate = new Date();
    let endDate = new Date();

    if (rangeType === 0) { //если выбран "ЭТОТ"
        if (rangeSize === 0) { //если выбран "МЕСЯЦ"
            beginDate = setTime(beginDate, "start")
            beginDate.setDate(1)
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(1);
            endDate.setDate(endDate.getDate() - 1);
            endDate = setTime(endDate, "end")
        } else { //если выбран "КВАРТАЛ"
            const range = getQuarterDates(new Date(), 0)
            beginDate = range.beginDate
            endDate = range.endDate
        }
    } else {//если выбран "ПРОШЛЫЙ"
        if (rangeSize === 0) { //если выбран "МЕСЯЦ"
            beginDate.setDate(1)
            beginDate.setMonth(beginDate.getMonth() - 1)
            beginDate = setTime(beginDate, "start")
            endDate.setDate(1);
            endDate.setDate(endDate.getDate() - 1);
            endDate = setTime(endDate, "end")
        } else { //если выбран "КВАРТАЛ"
            const range = getQuarterDates(new Date(), 1)
            beginDate = range.beginDate
            endDate = range.endDate
        }
    }

    return {beginDate: beginDate, endDate: endDate};
};

export const formatSelectedDate = (dateStr) => {
    const date = new Date(dateStr);
    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    return formattedDate;
}

export const getNextMonthFirstDay = dateStr => {
    const date = new Date(dateStr);
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    const nextMonthFirstDay = date.toISOString().slice(0, 10);
    return nextMonthFirstDay;
};

export const getRangeText = dateRange => {
    const beginDate = dateRange.beginDate;
    const endDate = dateRange.endDate;

    // получаем день, месяц и год начальной и конечной дат
    const beginDay = beginDate.getDate();
    const endDay = endDate.getDate();
    const beginMonth = beginDate.toLocaleString('default', {month: 'short'});
    const endMonth = endDate.toLocaleString('default', {month: 'short'});
    const beginYear = beginDate.getFullYear();
    const endYear = endDate.getFullYear();

    // если диапазон дат в пределах одного месяца, выводим только один раз месяц
    // if (beginDate.getMonth() === endDate.getMonth()) {
    //     // если диапазон дат в пределах одного года, выводим только номера дней и месяц
    //     if (beginYear === endYear) {
    //         return `${beginDay} - ${endDay} ${endMonth}`;
    //     } else { // если диапазон дат в разных годах, выводим номера дней, месяцы и года
    //         return `${beginDay} ${beginMonth} ${beginYear} - ${endDay} ${endMonth} ${endYear}`;
    //     }
    // } else { // если диапазон дат в разных месяцах, выводим оба месяца и номера дней
    //     if (beginYear === endYear) {
    //         return `${beginDay} ${beginMonth} - ${endDay} ${endMonth}`;
    //     } else { // если диапазон дат в разных годах, выводим номера дней, месяцы и года
    //         return `${beginDay} ${beginMonth} ${beginYear} - ${endDay} ${endMonth} ${endYear}`;
    //     }
    // }
    return `${beginDay} ${beginMonth} ${beginYear} - ${endDay} ${endMonth} ${endYear}`;
};

export const getPercent = (value, total) => {
    if (total === 0) return 0;
    return (100 * value) / total;
}

export const sortRating = (ratings) => {
    return ratings.sort((a, b) => getPercent(b.completedCount, b.completedCount + b.uncompletedCount) - getPercent(a.completedCount, a.completedCount + a.uncompletedCount))
}

export const convertRatingToBranchesLeaderboards = (ratings) => {
    let leaderboards = []
    ratings.forEach((rating, index) => {
        leaderboards.push({
            branch: rating.branch,
            value: rating.completedCount,
            totalValue: rating.completedCount + rating.uncompletedCount,
            place: index + 1,
            branchSize: rating.employeesCount
        })
    })
    return leaderboards
}

export const convertRatingToEmployeesLeaderboards = (ratings) => {
    let leaderboards = []
    ratings.forEach((rating, index) => {
        leaderboards.push({
            employee: rating.employee,
            value: rating.completedCount,
            totalValue: rating.completedCount + rating.uncompletedCount,
            place: index + 1
        })
    })
    return leaderboards
}

export const getProgressbarColor = (value, completed) => {
    const color1 = "#8CC06D"; // Цвет близкий к 100%
    const color2 = "#FDAE47"; // Цвет близкий к 50%
    const color3 = "#DD5757"; // Цвет близкий к 0%

    if (completed) return color1

    if (value >= 100) {
        return color1;
    } else if (value <= 0) {
        return color3;
    } else if (value < 50) {
        // Вычисление среднего цвета между color3 и color2
        const percentage = value / 50;
        const r = Math.round(
            parseInt(color3.slice(1, 3), 16) * (1 - percentage) +
            parseInt(color2.slice(1, 3), 16) * percentage
        );
        const g = Math.round(
            parseInt(color3.slice(3, 5), 16) * (1 - percentage) +
            parseInt(color2.slice(3, 5), 16) * percentage
        );
        const b = Math.round(
            parseInt(color3.slice(5, 7), 16) * (1 - percentage) +
            parseInt(color2.slice(5, 7), 16) * percentage
        );
        return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    } else {
        // Вычисление среднего цвета между color2 и color1
        const percentage = (value - 50) / 50;
        const r = Math.round(
            parseInt(color2.slice(1, 3), 16) * (1 - percentage) +
            parseInt(color1.slice(1, 3), 16) * percentage
        );
        const g = Math.round(
            parseInt(color2.slice(3, 5), 16) * (1 - percentage) +
            parseInt(color1.slice(3, 5), 16) * percentage
        );
        const b = Math.round(
            parseInt(color2.slice(5, 7), 16) * (1 - percentage) +
            parseInt(color1.slice(5, 7), 16) * percentage
        );
        return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    }
};

export const addOpacityToColor = (color, opacity) => {
    return color + opacity.toString(16)
}

export const convertArray = (inputArray) => {
    const sortedArray = sortArrayByMonth([...inputArray]); // Create a shallow copy before sorting
    const outputArray = sortedArray
        .filter(obj => obj && obj.month)
        .map((obj, index) => {
            const date = new Date(obj.month);
            const label = capitalizeFirst(`${date.toLocaleString('default', {month: 'short'})} ${date.getFullYear().toString().slice(-2)}`);
            return {id: index, value: obj.count, label, date: obj.month};
        });
    return outputArray;
};


export const sortArrayByMonth = (array) => {
    array.sort((a, b) => {
        if (a.month < b.month) {
            return -1;
        }
        if (a.month > b.month) {
            return 1;
        }
        return 0;
    });
    return array;
};

export const getEmployeeProgressColor = (value) => {
    if (value < 30) return "#DD5757"
    else if (value < 80) return "#FDAE47"
    else return "#8CC06D"
}
export // Function to perform sorting based on a given property and sort order
const sortByProperty = (arr, prop, sortOrder) => {
    const sortedArr = [...arr];
    sortedArr.sort((a, b) => {
        const valA = String(a[prop]);
        const valB = String(b[prop]);

        if (sortOrder) {
            return valA.localeCompare(valB);
        } else {
            return valB.localeCompare(valA);
        }
    });
    return sortedArr;
};

export const locationIs = (location) => {
    return window.location.pathname.split('/').pop() === location
}

// export const getProducers = (d) => {
//     return d.map(ms => ms.producer).filter((item, index, arr) => arr.findIndex(i => i.id === item.id) === index)
// }

export const getTypes = (d) => {
    return d.map(ms => ms.machineType).filter((item, index, arr) => arr.findIndex(i => i.id === item.id) === index)
}

export const getReportName = (units) => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // +1, так как месяцы начинаются с 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
    let unitsName;
    if (units === "courses") unitsName = "курсам"
    if (units === "modules") unitsName = "модулям"
    if (units === "machines") unitsName = "технике"

    return `Отчет по ${unitsName} от ${formattedDateTime}.xlsx`
}

export const getReport = units => {
    fetchWithAuth(API_URL + `/${units.slice(0, -1)}_completions/report`, {
        method: 'GET',
    }).then((response) => {
        if (response.status === 200) {
            // Получение данных из ответа
            return response.blob();
        }
    }).then((blob) => {
        // Создание объекта Blob

        // Создание ссылки для скачивания
        const url = window.URL.createObjectURL(blob);

        // Создание элемента <a> для скачивания
        const a = document.createElement('a');
        a.href = url;


        a.download = getReportName(units); // Задайте имя файла

        // Добавление элемента <a> в DOM и имитация клика
        document.body.appendChild(a);
        a.click();

        // Освобождение ресурсов
        window.URL.revokeObjectURL(url);
    })
};

export function getRatePercent(rates, selectedRate) {
    const maxRate = Math.max(...rates.map(r => parseFloat(r.rate)))
    const minRate = Math.min(...rates.map(r => parseFloat(r.rate)))
    return getPercent(selectedRate?.rate - minRate, maxRate - minRate);
}

export const isMobileView = () => {
    return window.innerWidth < 768;
};


//https://sky.pro/media/sravnenie-obektov-v-javascript/
export const isDeepEqual = (obj1, obj2) => {
    var keys1 = Object.keys({obj1});
    var keys2 = Object.keys({obj2});

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (var key of keys1) {
        if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

export const getShortInfo = (array) => {
    let res = ""
    if (!array) return "все"
    if (array.length !== 0) array.forEach((el, idx) => {
        res += el.name
        if (idx !== array.length - 1) res += ", "
    })
    return res;
}
