import classes from "./Tables.module.css";
import {getReport, getReportName} from "../../../utils/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownLeftAndUpRightToCenter,
    faDownload,
    faFileExcel,
    faFilter,
    faLocationDot,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useEffect, useState} from "react";
import {FilterContext, UiContext} from "../../../context";
import {useQuery} from "@apollo/client";
import {CORE_GET_COURSES_TABLE_HEADER} from "../../../graphql/courses";
import {CORE_GET_MODULES_TABLE_HEADER} from "../../../graphql/modules";
import {CORE_GET_MACHINES_TABLE_HEADER} from "../../../graphql/machines";
import {ConfigProvider, Popconfirm} from "antd";

export const UnitsTableHeader = ({onModalActiveChange, onEmployeeSearch, onBranchSearch}) => {

    const {ui} = useContext(UiContext)
    const {filters, setFilters} = useContext(FilterContext);

    const [headers, setHeaders] = useState(null);

    //шапка с курсами
    const coursesTableHeader = useQuery(CORE_GET_COURSES_TABLE_HEADER, {
        variables: {
            categories: filters.selectedCategories && filters.selectedCategories.map(sc => sc.id),
        }, fetchPolicy: 'network-only',
    })

    //шапка с модулями
    const modulesTableHeader = useQuery(CORE_GET_MODULES_TABLE_HEADER, {
        variables: {
            categories: filters.selectedCategories && filters.selectedCategories.map(sc => sc.id),
        }, fetchPolicy: 'network-only',
    })

    //шапка с машинами
    const machinesTableHeader = useQuery(CORE_GET_MACHINES_TABLE_HEADER, {
        variables: {
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
        }, fetchPolicy: 'network-only',
    })

    const isCategoryEnabled = (category) => {
        return filters.selectedCategories?.map(sc => sc.id).includes(parseInt(category.id))
    }

    const toggleCategory = (category) => {
        if (isCategoryEnabled(category)) {
            setFilters(prev => ({
                ...prev,
                selectedCategories: prev.selectedCategories.filter(c => c.id !== parseInt(category.id))
            }))
        } else {
            setFilters(prev => ({...prev, selectedCategories: [...prev.selectedCategories, category]}))
        }
    }

    // отслеживание изменения шапки по модулям
    useEffect(() => {
        if (ui.units === "modules" && modulesTableHeader.data) {
            let newHeaders = []
            modulesTableHeader.data.categories.map(category => {
                newHeaders.push({
                    id: parseInt(category.id),
                    name: category.name,
                    colspan: category.modules.length,
                    modules: category.modules
                })
            })
            setHeaders(newHeaders)
        }
    }, [modulesTableHeader, ui.units]);

    // отслеживание изменения шапки по курсам
    useEffect(() => {
        if (ui.units === "courses" && coursesTableHeader.data) {
            let newHeaders = []
            coursesTableHeader.data.categories.map(category => {
                newHeaders.push({
                    id: parseInt(category.id),
                    name: category.name,
                    colspan: category.courses.length,
                    courses: category.courses
                })
            })
            setHeaders(newHeaders)
        }
    }, [coursesTableHeader, ui.units]);

    // отслеживание изменения шапки по машинам
    useEffect(() => {
        if (ui.units === "machines") {
            setHeaders([{machines: machinesTableHeader.data?.machineTypes}])
        }
    }, [machinesTableHeader, ui.units]);

    let sum = -1
    return (
        <thead className={["bg-dark", classes.thead].join(" ")}>
        <tr style={{backgroundColor: "rgb(42, 47, 53)"}}>
            <td
                className={classes.td}
                colSpan={2}
                style={{backgroundColor: "rgb(42, 47, 53)", left: "0"}}
            >
                <div className={"d-flex justify-content-end"}>

                    {/*Кнопка для скачивания отчета*/}
                    <ConfigProvider theme={{token: {colorText: 'var(--dark-text)'}}}>
                        < Popconfirm
                            showArrow={false}
                            title={"Скачать файл?"}
                            description={getReportName(ui.units)}
                            okText={"Скачать"}
                            cancelText={"Отмена"}
                            onConfirm={() => getReport(ui.units)}
                        >

                            <button className={"btn text-success"}>
                                <FontAwesomeIcon icon={faFileExcel} style={{fontSize: "1.5em"}}/>
                            </button>
                        </Popconfirm></ConfigProvider>

                    {/*Кнопка для открытия модалки*/}
                    <button className={"btn text-light"} onClick={() => {
                        onModalActiveChange(true)
                    }}>
                        <FontAwesomeIcon icon={faFilter} color={"#FDAE47"} style={{fontSize: "1.5em"}}/>
                    </button>
                </div>
            </td>
            {/*<td style={{background: "linear-gradient(270deg, #2A2F3500, #2A2F35)", position: "sticky", right: 0}}></td>*/}
            {
                ui.units !== "machines" && headers?.map(header => {
                    if (header.colspan !== 0) return <td
                        key={`table-header-category-${header.id}`}
                        categoryvariablewidth="true"
                        className={["text-center bold m-0 p-2", classes.td].join(" ")}
                        colSpan={header.colspan}
                        title={`${header.name} (${header.colspan})`}
                    >

                        <div
                            className={[classes.categoryValue, "row d-flex text-center m-0 w-100 px-2 py-2 text-ellipsis col-auto lead align-items-center"].join(" ")}
                            onClick={() => {
                                toggleCategory(header)
                            }}>
                            <div className="col-1 p-0 ps-1 m-0 d-flex justify-content-start">
                                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} color={"#ffffff"}
                                                 style={{fontSize: "0.9rem"}}></FontAwesomeIcon>
                            </div>
                            <div className="col">{header.name} ({header.colspan})</div>
                        </div>
                    </td>
                })
            }
            <td style={{
                background: "linear-gradient(90deg, #2A2F3500, #2A2F35)",
                position: "sticky",
                right: 0
            }}></td>
        </tr>

        {/*Поиск по филиалу, сотруднику, названия модулей*/}
        <tr className={classes.tr}>

            {/*Сортировка по филиалу*/}
            <th className={[classes.branchValue, classes.th].join(" ")}>
                <div className={"d-flex align-items-center p-0"}>
                    <FontAwesomeIcon icon={faLocationDot} className={"me-3"}/>
                    <input onChange={(e) => {
                        onBranchSearch(e.target.value)
                    }} className={classes.tableInput} type="text" placeholder="Поиск по филиалу..."/>
                    {/*<button*/}
                    {/*    onClick={() => {*/}
                    {/*        setFilters({...filters, branchesSort: !filters.branchesSort, sortBy: "branches"})*/}
                    {/*    }}*/}
                    {/*    className={"btn text-light"}>*/}
                    {/*    <img src={!filters.branchesSort ? AZ : ZA} alt=""/>*/}
                    {/*</button>*/}
                </div>
            </th>

            {/*Сортировка по сотруднику*/}
            <th className={[classes.nameValue, classes.th].join(" ")}>
                <div className={"d-flex align-items-center"}>
                    <FontAwesomeIcon icon={faUser} className={"me-3"}/>
                    <input onChange={(e) => {
                        onEmployeeSearch(e.target.value)
                    }} className={classes.tableInput} type="text" placeholder="Поиск по ФИО..."/>
                    {/*<button*/}
                    {/*    className={"btn text-light"}*/}
                    {/*    onClick={() => {*/}
                    {/*        setFilters({...filters, employeesSort: !filters.employeesSort, sortBy: "employees"})*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <img src={!filters.employeesSort ? AZ : ZA} alt=""/>*/}
                    {/*</button>*/}
                </div>
            </th>


            {/*Подзаголовки*/}
            {
                headers !== null && headers.map((header) => {

                    return ui.units === "courses" ?
                        header.courses?.map((course) => {
                            sum += 1;
                            return <th style={ui.hoveredTd?.x === sum ? {background: "var(--secondary-light)"} : {}}
                                       key={`table-header-module-${course.id}`} className={["lead", classes.th].join(" ")}
                                       moduleid={course.id}>
                                {course.name}
                            </th>
                        })
                        :
                        ui.units === "modules" ?
                            header.modules?.map(module => {
                                sum += 1
                                return <th style={ui.hoveredTd?.x === sum ? {background: "var(--secondary-light)"} : {}}
                                           key={`table-header-module-${module.id}`}
                                           className={["lead", classes.th].join(" ")} moduleid={module.id}>
                                    {module.name}
                                </th>
                            })
                            :
                            ui.units === "machines" &&
                            header.machines?.map(machine => {
                                sum += 1
                                return <th style={ui.hoveredTd?.x === sum ? {background: "var(--secondary-light)"} : {}}
                                           key={`table-header-module-${machine.id}`}
                                           className={["lead", classes.th].join(" ")}
                                           moduleid={machine.id}>
                                    {machine.name}
                                </th>
                            })
                })

            }
            {ui.units === "courses" && <th className={[classes.th, "bg-success text-center h4"].join(" ")}
                                           style={{/*position: "sticky", right: 0*/}}>Сумма:</th>}
        </tr>
        </thead>
    )
}