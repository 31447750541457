import React, {useEffect, useRef, useState} from 'react';
import ForwardButton from "./UI/button/ForwardButton";
import ExpandCategoryCard from "./UI/dashboard/employee/ExpandCategoryCard";
import './../App.css'
import {useSelector} from "react-redux";
import {selectCoursesCompletions} from "../features/completions/completionsSlice";
import {selectCourses} from "../features/educationUnits/unitsSlice";
import {isArray} from "@apollo/client/utilities";
import {motion} from "framer-motion";


const CategoryItem = (props) => {

    const coursesCompletions = useSelector(selectCoursesCompletions)

    const [length, setLength] = useState(0);

    useEffect(() => {
        if (isArray(coursesCompletions)) setLength(coursesCompletions.filter(cc => cc.category === props.category.id).length)
    }, [coursesCompletions]);

    function expandCard() {

        collapsedCardDiv.current.style.opacity = 0

        setTimeout(() => {
            collapsedCardDiv.current.style.display = 'none'
            cardBorder.current.classList.remove('col-lg-6')
            expandedCardDiv.current.style.display = 'flex'
            collapsedCardDiv.current.classList.remove("d-flex")
        }, 150)

        setTimeout(() => {
            expandedCardDiv.current.style.opacity = 1
        }, 300)

    }

    function collapseCard() {
        expandedCardDiv.current.style.opacity = 0

        setTimeout(() => {
            collapsedCardDiv.current.style.display = 'flex'
            collapsedCardDiv.current.classList.remove("d-flex")
            cardBorder.current.classList.add('col-lg-6')
            expandedCardDiv.current.style.display = 'none'
        }, 300)

        setTimeout(() => {
            collapsedCardDiv.current.style.opacity = 1
        }, 350)

    }

    const cardBorder = useRef()
    const collapsedCardDiv = useRef()
    const expandedCardDiv = useRef()


    return (
        <motion.div layout ref={cardBorder}
             style={{
                 opacity: length !== 0 ? 1 : 0.5,
                 filter: length !== 0 ? "unset" : "grayscale(50%)"
             }}
             className='col-lg-6 col-12 p-3 categoryItem d-flex justify-content-center align-items-center'>
            <div ref={collapsedCardDiv} className='collapsedCard d-flex flex-column justify-content-end p-3' style={{
                background: 'linear-gradient(90deg, #4F5760 0%, rgba(0, 0, 0, 0) 100%),' +
                    'url(' + props.category.image + ')',
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right"
            }}>
                <h2 className='col-6 categoryTitle m-0'>{props.category.name}</h2>
                <div className={" col-lg-2 col-12 mt-3"}>
                    <ForwardButton
                        onClick={expandCard}
                        style={{backgroundColor: length !== 0 ? "#FDAE47" : "#7A8490"}}
                        title={`Курсы (${length})`}/>
                </div>
            </div>

            <ExpandCategoryCard
                divRef={expandedCardDiv}
                collapseCard={collapseCard}
                category={props.category}
            />
            {/*<div ref={expandedCardDiv} className='expandedCard'>*/}
            {/*    <BackButton onClick={collapseCard}/>*/}
            {/*</div>*/}


        </motion.div>
    );
};

export default CategoryItem;