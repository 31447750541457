import {API_URL, REFRESH_TOKEN_KEY} from '../_helpers_/constants'
import {fetchWithAuth} from "../_helpers_/commands";


export default class AuthService {

    static async login(user, password) {
        return fetch(API_URL + '/token/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: user,
                password: password,
            }),
        })
            .then(async (response) => {
                const data = await response.json();
                if (response.status === 200) {
                    return Promise.resolve(data);
                } else {
                    const errorData = {
                        status: response.status,
                        message: data.error || 'Неизвестная ошибка',
                    };
                    return Promise.reject(errorData);
                }
            })
            .catch(error => {
                throw new Error('Сетевая ошибка: ' + error.message);
            });
    }



    static async logout() {
        return fetchWithAuth(API_URL + '/logout/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh: localStorage.getItem(REFRESH_TOKEN_KEY),
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.clear()
                    return Promise.resolve()
                }
                return Promise.reject();
            });
    }
}