import React, {useContext, useEffect, useState} from 'react';
import classes from "../SupervisorDashboards.module.css"
import buttons from "./../../../button/Buttons.module.css"
import DashboardTypeSelect from "../../../select/DashboardTypeSelect";
import {FilterContext, UiContext, UserContext} from "../../../../../context";
import AttemptsFilters from "./AttemptsFilters";
import RatingFilters from "./RatingFilters";
import DynamicFilters from "./DynamicFilters";
import {useSelector} from "react-redux";
import {selectUserData} from "../../../../../features/auth/authSlice";
import ListSelect from "../../../select/ListSelect";
import {selectBranches} from "../../../../../features/users/usersSlice";

const Filter = ({parentFilters, onChange, hasChanges, setHasChanges}) => {

    //context
    const {ui} = useContext(UiContext);

    //selectors
    const {user} = useContext(UserContext)
    const branches = useSelector(selectBranches)

    //state
    const {filters, setFilters} = useContext(FilterContext);
    const [filterState, setFilterState] = useState(filters);
    // const [hasChanges, setHasChanges] = useState(false)

    const handleFiltersChange = newFiltersState => {
        setFilterState(prevFiltersState => ({...prevFiltersState, ...newFiltersState}))
        setHasChanges(true)
    }

    const handleBranchesChange = (selectedBranches) => {
        if (selectedBranches.length === 0) {
            setFilterState(prev => ({...prev, branches: user.groups?.includes("admin") ? branches : user.branches}));
        } else {
            setFilterState(prev => ({...prev, branches: selectedBranches}));
        }
        setHasChanges(true)
    }

    //тут обновляются только выбранные типы и производители, т.к. они выбираются в модалке на уровень выше
    useEffect(() => {
        setFilterState(prev => ({
            ...prev,
            selectedTypes: parentFilters.selectedTypes,
            // selectedBrands: parentFilters.selectedBrands
        }))
    }, [parentFilters.selectedTypes]);

    return (
        <div className={classes.filters}>

            <DashboardTypeSelect/>

            <button
                disabled={!hasChanges}
                className={!hasChanges
                    ? buttons.applyFiltersBtn
                    : [buttons.applyFiltersBtn, buttons.active].join(" ")}
                onClick={() => {
                    setFilters(prev => ({...filterState}));
                    setHasChanges(false);
                }}
            >
                Применить фильтры
            < /button>

            {ui.dashboardType === 'attempts' && ui.units === 'modules' &&
                <AttemptsFilters onChange={handleFiltersChange}/>}

            {ui.dashboardType === 'rating' &&
                <RatingFilters onChange={handleFiltersChange} parenFilters={parentFilters}/>}

            {ui.dashboardType === 'dynamic' &&
                <DynamicFilters onChange={handleFiltersChange} parenFilters={parentFilters}/>}


            <div className={classes.opacityContainer} style={{height: "50%"}}>
                <ListSelect
                    onItemsChange={handleBranchesChange}
                    searchPlaceholderText={"Поиск по филиалу..."}
                    items={user.groups?.includes("admin") ? branches : user.branches}/>
            </div>


        </div>)
}

export default Filter;