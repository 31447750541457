import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import classes from './Navbar.module.css'
import logo from './../../../images/logo-full.svg'
import excavator from './../../../images/Excavator.svg'
import NavButton from "../button/NavButton";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser, selectUserData} from "../../../features/auth/authSlice";
import AuthService from "../../../features/auth/auth.service";

import {faChartLine, faList, faWrench} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Offcanvas} from "react-bootstrap";
import {EmployeeNavbarCard} from "../cards/EmployeeNavbarCard";
import {UserContext} from "../../../context";
import DropdownButton from "../button/DropdownButton";
import logoutImg from "../../../images/logout.svg";
import HoverMenu from "../modal/HoverMenu";
import {supervisorUnitsOptions} from "../../../utils/constants";

const Navbar = () => {

    const {user} = useContext(UserContext)

    const buttons = [
        {id: 0, title: 'Модули', path: '/categories', icon: faChartLine},
        {id: 1, title: 'Техника', path: '/machines', icon: faWrench}
    ]

    const [modalActive, setModalActive] = useState(false);
    const [selectedButton, setSelectedButton] = useState(0);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);

    const dispatch = useDispatch()

    const logout = async event => {
        AuthService.logout()
            .then(data => {
                dispatch(logoutUser())
            })
    }

    if (user.role === "engineer" || user.role === "manager")
        return (
            <div className={["d-flex flex-row align-items-center justify-content-between w-100 sticky-top pe-lg-0 pe-2", classes.navbar].join(" ")}>
                <div className={"col-3 d-flex flex-row"}>
                    <img className={classes.logo} src={logo}/>
                </div>

                <div className={[classes.navbar__links, "col d-none d-lg-flex justify-content-center"].join(" ")}>
                    {buttons.map(button =>
                        <Link to={button.path} key={button.title} onClick={() => setSelectedButton(buttons[button.id])}>
                            <NavButton button={button} icon={button.icon} active={button.id === selectedButton.id}/>
                        </Link>
                    )}
                </div>

                <div className={"d-flex bg-dark d-lg-none text-light align-items-center justify-content-center bold border border-white border-3 rounded-pill pe-3"}  onClick={handleShow}>
                    <button className={"btn text-light"}><FontAwesomeIcon icon={faList}/></button>
                    Меню
                </div>

                <div className={"col-auto d-flex justify-content-end align-items-center gap-3"}>
                    <div className="d-none w-100 d-lg-flex">
                        <EmployeeNavbarCard user={user}/>
                    </div>
                    <button onClick={logout} className={classes.logout__btn}/>
                </div>

                <Offcanvas show={showOffcanvas} onHide={handleClose} placement="top" className={"bg-dark"} style={{height: "75px"}} backdrop={false}>
                    <Offcanvas.Header closeButton closeVariant={"white"}>
                        <Offcanvas.Title>
                            <div className={"d-flex"}>{buttons.map(button =>
                                <Link to={button.path} key={button.title}>
                                    <NavButton button={button} icon={button.icon} action={handleClose}/>
                                </Link>
                            )}</div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                </Offcanvas>
            </div>
        );
    else return (
        <div
            className={["d-flex flex-row align-items-center justify-content-between w-100", classes.navbar].join(" ")}
            // style={{backdropFilter: "blur(2px)"}}
        >

            <div className={"col-3"}>
                <img
                    src={logo}
                    alt={"tmbk logo"}
                    className={classes.logo}
                    onMouseEnter={() => setModalActive(true)}
                />
            </div>

            <div className={[classes.navbar__links, "col d-flex justify-content-center"].join(" ")}>
                <div className="col-3">
                    <DropdownButton
                        options={supervisorUnitsOptions}
                    />
                </div>

                {/*<Link to={"/units"}>*/}
                {/*    <NavButton button={{title: 'По технике'}} icon={faTruck} onClick={() => {*/}
                {/*        dispatch(changeSupervisorUnitsShowing("machines"))*/}
                {/*        setShowing("machines")}}/>*/}
                {/*</Link>*/}
            </div>

            <div className={"col-auto d-flex justify-content-end align-items-center pe-2 gap-3"}>
                <EmployeeNavbarCard user={user}/>
                <button onClick={logout} className={classes.logout__btn}>
                    <img className={classes.logout__img} src={logoutImg}/>
                </button>
            </div>


            <HoverMenu
                active={modalActive}
                setActive={setModalActive}
                title={<span className={"h4"}>Фильтр по технике</span>}>
            </HoverMenu>

        </div>
    );
};

export default Navbar;