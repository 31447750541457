import React, {useEffect, useState} from 'react';
import Accordion from "./Accordion";
import {faCalendarDay, faClock, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SmallProgressbar} from "../progressbars/SmallProgressbar";
import {formatAttemptDate} from "../../../utils/helpers";

const calculateAverageDuration = attempts => {
    if (attempts.length !== 0) {
        const totalDuration = attempts.reduce((acc, attempt) => acc + attempt.duration, 0);
        return totalDuration / attempts.length;
    } else {
        return 0;
    }
};

const formatDuration = duration => {
    const days = Math.floor(duration / (3600 * 24));
    const hours = Math.floor((duration - days * 3600 * 24) / 3600);
    const minutes = Math.floor((duration - days * 3600 * 24 - hours * 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const formattedDays = days > 0 ? `${days} д. ` : '';
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    if (days > 0) {
        return `${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else if (hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
        return `${formattedMinutes}:${formattedSeconds}`;
    }
};


const getMaxResult = attempts => {
    let maxResult = 0;
    attempts.forEach(attempt => {
        const result = parseFloat(attempt.grade)
        if (result > maxResult) {
            maxResult = result;
        }
    })
    return maxResult;
};


const iconStyle = {fontSize: "1.5em", color: "#FFFFFF33"}

const Attempt = ({employeeAttempt, isExpanded, delay}) => {

    const [shouldAnimate, setShouldAnimate] = useState(true)

    const maxResult = getMaxResult(employeeAttempt.attempts)

    function getDurationPercentage(oneAttempt) {
        const durations = employeeAttempt.attempts.map((a) => a.duration);
        const validDurations = durations.filter((duration) => typeof duration === 'number' && !isNaN(duration));

        const maxDuration = Math.max(...validDurations);
        return (oneAttempt.duration / maxDuration) * 100;
        // return  0
    }

    const [opacity, setOpacity] = useState(0)
    const [transform, setTransform] = useState("-1rem")

    useEffect(()=> {
        setTimeout(()=> {
            setOpacity(employeeAttempt.attempts.length !== 0 ? 1 : 0.3)
            setTransform("0")
        }, delay)
    }, [])

    return (<Accordion
        className={"mb-4"}
        style={{transition: ".3s ease-in-out", opacity: opacity, cursor: employeeAttempt.attempts.length !== 0 ? "pointer" : "auto", transform: `translateX(${transform})`, borderRadius: "1rem"}}
        isExpandable={employeeAttempt.attempts.length !== 0}
        isExpanded={isExpanded}
        handleExpand={() => {
            setShouldAnimate(!shouldAnimate)
        }}
        header={<div className="d-flex justify-content-between align-items-center w-100 h-100">
            {/*Количество попыток*/}
            <div className={"text-center h3 bold mb-0"} style={{width: "60px"}}><span>{employeeAttempt.attempts.length}</span></div>
            {/*Сотрудник*/}
            <div className="d-flex col-2 text-center align-items-center">
                <FontAwesomeIcon className={"me-2"} icon={faUser} style={iconStyle}/>
                <div className="d-flex flex-column ms-4 text-center">
                    <div className="row text-center w-100 justify-content-center h5 mb-0" style={{minWidth: "200px"}}>{employeeAttempt.employee.firstname}&nbsp;{employeeAttempt.employee.lastname}</div>
                    <div className="row text-center w-100 justify-content-center" style={{color: "#FFFFFF33", maxLines: 1, whiteSpace: 'nowrap'}}>{employeeAttempt.employee.branches.map(b => b.name).join(', ')}</div>
                </div>
            </div>
            {/*Название модуля*/}
            <div className={"col-3 text-center h5 mb-0"}><span>{employeeAttempt.module.name}</span></div>
            {/*Средняя продолжительность попыток*/}
            <div className={"d-flex col-2 text-center justify-content-center"}>
                <FontAwesomeIcon icon={faClock} style={iconStyle}/>
                <span className={"h5 ms-2 mb-0"} style={{color: "#FFFFFF33"}}>{formatDuration(calculateAverageDuration(employeeAttempt.attempts))}</span>
            </div>
            {/*Максимальный результат*/}
            <div className={"text-center h3 bold d-flex justify-content-center align-items-center mb-0 h-100"} style={{backgroundColor: employeeAttempt.completed ? "#8CC06D" : "#DD5757", width: "60px"}}>
                {maxResult.toFixed(0)}
            </div>
        </div>}
        body={employeeAttempt.attempts.map((a, index) => {
            return <div key={`attempt-${index}`} className="d-flex justify-content-between align-items-center w-100" style={{height: "60px"}}>
                {/*Номер попытки*/}
                <div className={"text-center bold"} style={{width: "60px"}}><span>{index + 1}.</span></div>
                {/*Дата попытки*/}
                <div className="d-flex col-2 text-center justify-content-center align-items-center" style={{color: "#FFFFFF33"}}>
                    <FontAwesomeIcon icon={faCalendarDay} style={iconStyle} className={"me-3"}/>
                    {formatAttemptDate(a.startTime)}
                </div>
                {/*Прогрессбар попытки*/}
                <div className={"col-3 px-5 d-flex justify-content-center"}>
                    <SmallProgressbar delay={index * 100 + 100} color={a.completed ? "#8CC06D80" : "#DD575780"} value={getDurationPercentage(a)} shouldAnimate={shouldAnimate}/>
                </div>
                {/*Длительность попытки*/}
                <div className={"col-2 d-flex align-items-center justify-content-center"} style={{color: "#FFFFFF33"}}>
                    <FontAwesomeIcon icon={faClock} style={iconStyle} className={"me-3"}/>
                    {formatDuration(a.duration)}
                </div>
                {/*Результат попытки*/}
                <div className={"text-center h3 bold d-flex justify-content-center align-items-center mb-0 h-100"} style={{width: "60px", position: "relative"}}>
                    <div style={{position: "absolute", width: "100%", height: parseFloat(a.grade) + "%", backgroundColor: a.completed ? "#8CC06D66" : "#DD575766", zIndex: "2", left: 0, bottom: 0}}></div>
                    <span style={{zIndex: "3"}}>
                        {parseFloat(a.grade).toFixed(0)}
                    </span>
                </div>
            </div>
        })}
    />);
};

export default Attempt;