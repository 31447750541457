import {useContext, useEffect, useState} from "react";
import {FilterContext, UiContext} from "../../../context";
import {useQuery} from "@apollo/client";
import Loader from "../loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {ListRatingEmployeeUnits} from "../lists/ListRatingEmployeeUnits";
import {GET_BRANCH_EMPLOYEES_MODULES_COMPLETIONS, GET_EMPLOYEE_MODULES_COMPLETIONS} from "../../../graphql/modules";
import {GET_BRANCH_EMPLOYEES_COURSES_COMPLETIONS, GET_EMPLOYEE_COURSES_COMPLETIONS} from "../../../graphql/courses";
import {GET_BRANCH_EMPLOYEES_MACHINES_COMPLETIONS, GET_EMPLOYEE_MACHINES_COMPLETIONS} from "../../../graphql/machines";
import {ListRatingBranchUnits} from "../lists/ListRatingBranchUnits";
import {Center} from "../Center";

export const BranchRatingDetails = () => {
    const {filters} = useContext(FilterContext);
    const {ui} = useContext(UiContext)

    const [data, setData] = useState({"modules": null, "courses": null, "machines": null});

    const employeeModulesCompletions = useQuery(GET_BRANCH_EMPLOYEES_MODULES_COMPLETIONS, {
        variables: {
            branches: filters.selectedBranch && [parseInt(filters.selectedBranch.id)],
            courses: filters.courses && filters.courses.map(course => course.id)
        },
    })

    const employeeCoursesCompletions = useQuery(GET_BRANCH_EMPLOYEES_COURSES_COMPLETIONS, {
        variables: {
            branches: filters.selectedBranch && [parseInt(filters.selectedBranch.id)],
            courses: filters.courses && filters.courses.map(course => course.id)
        },
    })

    const employeeMachinesCompletions = useQuery(GET_BRANCH_EMPLOYEES_MACHINES_COMPLETIONS, {
        variables: {
            branches: filters.selectedBranch && [parseInt(filters.selectedBranch.id)],
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
            // producers: filters.selectedBrands?.map(brand => parseInt(brand.id)),
        },
    })

    const isLoading = () => {
      return (ui.units === "courses" && employeeCoursesCompletions.loading ||
          ui.units === "modules" && employeeModulesCompletions.loading ||
          ui.units === "machines" && employeeMachinesCompletions.loading)
    }

    useEffect(() => {
        if (!employeeModulesCompletions.loading && employeeModulesCompletions.data) {
            setData(prevData => ({...prevData, "modules": employeeModulesCompletions.data.branchEmployeesModulesCompletions}))
        }
    }, [employeeModulesCompletions.data])

    useEffect(() => {
        if (!employeeCoursesCompletions.loading && employeeCoursesCompletions.data) {
            setData(prevData => ({...prevData, "courses": employeeCoursesCompletions.data.branchEmployeesCoursesCompletions}))
        }
    }, [employeeCoursesCompletions.data])

    useEffect(() => {
        if (!employeeMachinesCompletions.loading && employeeMachinesCompletions.data) {
            setData(prevData => ({...prevData, "machines": employeeMachinesCompletions.data.branchEmployeesMachinesCompletions}))
        }
    }, [employeeMachinesCompletions.data])

    return (
        !filters.selectedBranch ? <div>Выберите филиал</div> :
            <div className={"d-flex flex-column w-100 h-100 py-4"}>
                <div className={"position-sticky top-0"}>
                    <div className="text-center justify-content-center w-100 h4 bold text-white m-0 p-0"><FontAwesomeIcon className={"me-2"} icon={faLocationDot}/> {filters.selectedBranch.name}</div>
                </div>
                <div className={"row w-100"}  style={{height: "95%"}}>
                    {isLoading() ? <Center><Loader/></Center>: data[ui.units] && <ListRatingBranchUnits units={data[ui.units]}/>}
                </div>
            </div>
    )
}