import React from 'react';
import classes from "./MyModal.module.css";
import {faChartBar, faTableList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {locationIs} from "../../../utils/helpers";

const HoverMenu = ({active, setActive}) => {

    const navigate = useNavigate()

    return (
        <div className={active ? [classes.hoverMenuWrapper, classes.active].join(" ") : classes.hoverMenuWrapper} onClick={() => setActive(false)}>
            <div className={active ? [classes.hoverMenu, classes.active].join(" ") : classes.hoverMenu} onClick={e => e.stopPropagation()}
                 onMouseLeave={() => setActive(false)}
            >
                <button className={[classes.button1, classes.button].join(" ")} onClick={() => navigate("/dashboards")}>
                    <FontAwesomeIcon icon={faChartBar} className={locationIs("dashboards") ? classes.activeIcon : classes.icon}/>
                    <span>Дашборд</span>
                </button>
                <button className={[classes.button2, classes.button].join(" ")} onClick={() => navigate("/units")}>
                    <FontAwesomeIcon icon={faTableList} className={locationIs("units") ? classes.activeIcon : classes.icon}/>
                    <span>Таблица</span>
                </button>
            </div>
        </div>
    );
};

export default HoverMenu;