import React, {useContext, useEffect, useState} from "react";
import {FilterContext, UiContext} from "../../../context";
import MyModal from "./MyModal";
import ListSelect from "../select/ListSelect";
import {useQuery} from "@apollo/client";
import {GET_MACHINE_TYPES} from "../../../graphql/machines";
import Loader from "../loader/Loader";
import {Center} from "../Center";
import {getTypes} from "../../../utils/helpers";
import classes from "./MyModal.module.css"
import {Button, ConfigProvider, Modal} from "antd";
import {logDOM} from "@testing-library/react";

export const MachinesModal = ({modalActive, setModalActive, onChange, onConfirm}) => {

    const [filtersState, setFiltersState] = useState({selectedTypes: []});

    // const [brands, setBrands] = useState([])
    const [types, setTypes] = useState([])

    const data = useQuery(GET_MACHINE_TYPES, {
        fetchPolicy: 'network-only',
        onCompleted: (d) => {
            setTypes(d.machineSummary)
        }
    })

    // useEffect(() => {
    //     if (filtersState.selectedTypes?.length === 0) {
    //         // setBrands(getProducers(pairs))
    //     } else {
    //         const selectedIds = filtersState.selectedTypes?.map(t => t.id)
    //         // setBrands(getProducers(pairs.filter(pair => selectedIds.includes(pair.machineType.id))))
    //     }
    // }, [filtersState.selectedTypes]);

    // useEffect(() => {
    //     if (filtersState.selectedBrands?.length === 0) {
    //         setTypes(getTypes(pairs))
    //     } else {
    //         const selectedIds = filtersState.selectedBrands?.map(b => b.id)
    //         setTypes(getTypes(pairs.filter(pair => selectedIds.includes(pair.producer.id))))
    //     }
    // }, [filtersState.selectedBrands]);

    useEffect(() => {
        onChange(filtersState)
    }, [filtersState]);

    return (
            <Modal
                open={modalActive}
                onCancel={() => setModalActive(false)}
                footer={onConfirm ? <Button type="primary" block onClick={() => onConfirm(filtersState)}>Применить</Button>: null}
                title={<span className={"h4 text-light"}>Выбор типа техники</span>}
            >
                <div className={classes.machinesSelects} style={{height: "500px"}}>
                    <div className={classes.machinesSelectContainer}>
                        {data.loading || data.data === undefined ? <Center><Loader/></Center> :
                            <ListSelect
                                onItemsChange={types => setFiltersState(prevState => ({...prevState, selectedTypes: types}))}
                                searchPlaceholderText={"Поиск по типу техники..."}
                                items={types === undefined ? [] : types}/>
                        }
                    </div>
                    {/*<div className={classes.machinesSelectContainer}>*/}
                    {/*    {data.loading || data.data === undefined ? <Center><Loader/></Center> :*/}
                    {/*        <ListSelect*/}
                    {/*            onItemsChange={brands => setFiltersState(prevState => ({...prevState, selectedBrands: brands}))}*/}
                    {/*            searchPlaceholderText={"Поиск по производителю..."}*/}
                    {/*            items={brands === undefined ? [] : brands}/>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </Modal>
    )
}