import React, {useContext, useState} from 'react';
import classes from "./Select.module.css"
import {FilterContext, UiContext} from "../../../context";
import {useAnimation} from "../../../hooks/useAnimation";

const DashboardTypeSelect = () => {

    const {ui, setUi} = useContext(UiContext)
    const {scale} = useAnimation(0);

    const dashboardTypes = [
        {key: "attempts", title: "Попытки", viewIn: ["modules"]},
        {key: "rating", title: "Рейтинг", viewIn: ["courses", "modules", "machines"]},
        {key: "dynamic", title: "Динамика", viewIn: ["courses", "modules", "machines"]},
    ];

    return (
        <div className={classes.dashboardTypesWrapper} style={{transform: `scale(${scale})`}}>
            {dashboardTypes.map(item =>
                item.viewIn.includes(ui.units) &&
                <div
                    key={item.key}
                    onClick={() => {
                        setUi({...ui, dashboardType: item.key})
                    }}
                    className={item.key === ui.dashboardType ? [classes.dashboardTypeItem, classes.active].join(" ") : classes.dashboardTypeItem}>
                    {item.title}
                </div>
            )}
        </div>
    );
};

export default DashboardTypeSelect;
