import React, {useContext, useEffect, useState} from 'react';
import classes from "./SupervisorDashboards.module.css"
import {FilterContext, UiContext} from "../../../../context";
import {useQuery} from "@apollo/client";
import {CORE_GET_MONTH_PROGRESS_BY_COURSE} from "../../../../graphql/courses";
import {convertArray, formatDate, formatSelectedDate, sortArrayByMonth} from "../../../../utils/helpers";
import ListVerticalProgressbars from "../../lists/ListVerticalProgressbars";
import Loader from "../../loader/Loader";
import EmployeesList from "../../lists/EmployeesList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarWeek} from "@fortawesome/free-solid-svg-icons";
import {CORE_GET_MONTH_PROGRESS_BY_MODULE} from "../../../../graphql/modules";
import {CORE_GET_MONTH_PROGRESS_BY_MACHINE} from "../../../../graphql/machines";
import FoldButton from "../../button/FoldButton";
import {Center} from "../../Center";
import {EmployeeRatingDetails} from "../../table/EmployeeRatingDetails";
import {BranchRatingDetails} from "../../table/BranchRatingDetails";
import {EmployeeListCard} from "../../cards/EmployeeListCard";

const BottomInfo = () => {

    const {filters, setFilters} = useContext(FilterContext);
    const {ui, setUi} = useContext(UiContext)
    const [data, setData] = useState({"courses": [], "modules": [], "machines": []});
    const [viewing, setViewing] = useState({monthlyProgress: [], uncompletedEmployees: []});

    const [opacity, setOpacity] = useState(0);

    const courses = useQuery(CORE_GET_MONTH_PROGRESS_BY_COURSE, {
        variables: {
            courses: filters.selectedCourse && [parseInt(filters.selectedCourse.id)],
            dateBegin: formatDate(filters.dateRange.beginDate),
            dateEnd: formatDate(filters.dateRange.endDate),
            branches: filters.branches.map(branch => branch.id)
        },
        skip: ui.units !== "courses",
        fetchPolicy: "no-cache",
        onCompleted: (d) => {
            if (d !== undefined) setData(prevState => ({...prevState, "courses": d.courseSummary[0]}))
        },
    });

    const modules = useQuery(CORE_GET_MONTH_PROGRESS_BY_MODULE, {
        variables: {
            module: filters.selectedModule && parseInt(filters.selectedModule.id),
            dateBegin: formatDate(filters.dateRange.beginDate),
            dateEnd: formatDate(filters.dateRange.endDate),
            branches: filters.branches.map(branch => branch.id)
        },
        skip: ui.units !== "modules",
        fetchPolicy: "no-cache",
        onCompleted: (d) => {
            if (d !== undefined) setData(prevState => ({...prevState, "modules": d.moduleSummary[0]}))
        },
    });

    const machines = useQuery(CORE_GET_MONTH_PROGRESS_BY_MACHINE, {
        variables: {
            types: [parseInt(filters.selectedMachine?.id)],
            dateBegin: formatDate(filters.dateRange.beginDate),
            dateEnd: formatDate(filters.dateRange.endDate),
            branches: filters.branches.map(branch => branch.id)
        },
        fetchPolicy: "no-cache",
        skip: ui.units !== "machines",
        onCompleted: (d) => {
            if (d !== undefined) setData(prevState => ({...prevState, "machines": d.machineSummary[0]}))
        },
    })

    useEffect(() => {
        setUi({...ui, bottomInfoFolded: true})
    }, [ui.dashboardType]);

    useEffect(() => {
        if (ui.bottomInfoFolded) setOpacity(0)
        else setTimeout(() => setOpacity(1), 300)
    }, [ui.bottomInfoFolded]);

    useEffect(() => {
        if (ui.units === "courses") {
            setViewing({
                monthlyProgress: data["courses"].monthlyProgress,
                uncompletedEmployees: data["courses"].uncompletedEmployees
            })
        } else if (ui.units === "modules") {
            setViewing({
                monthlyProgress: data["modules"].monthlyProgress,
                uncompletedEmployees: data["modules"].uncompletedEmployees
            })
        } else if (ui.units === "machines") {
            setViewing({
                monthlyProgress: data["machines"]?.monthlyProgress,
                uncompletedEmployees: data["machines"]?.uncompletedEmployees
            })
        }
    }, [data, ui.units]);

    return (
        <div className={classes.infoContainer} style={{height: ui.bottomInfoFolded ? "3%" : `calc(50% - 6px)`}}>
            <div className={classes.foldContainer}>
                <FoldButton
                    direction={ui.bottomInfoFolded}
                    onClick={() => setUi({...ui, bottomInfoFolded: !ui.bottomInfoFolded})}
                />
            </div>
            {!ui.bottomInfoFolded &&
                <div className={classes.opacityContainer} style={{width: "100%", height: "97%"}}>
                    {ui.dashboardType === "dynamic"
                        ? <div className={"row p-3 h-100 flex-nowrap"} style={{opacity: opacity, transition: ".5s"}}>
                            {(filters.selectedCourse !== null || filters.selectedModule !== null || filters.selectedMachine !== null) &&
                                <div className="col-8 h-100 p-0 d-flex flex-column justify-content-between">
                                    <div className="w-100 d-flex justify-content-center flex-grow-1">
                                        {
                                            (ui.units === "courses" && (courses === undefined || courses.loading) || ui.units === "modules" && (modules === undefined || modules.loading) || ui.units === "machines" && (machines === undefined || machines.loading)) ?
                                                <Center><Loader/></Center> :
                                                // (modules.error) ? `Modules error! ${modules.error}` :
                                                //     (courses.error) ? `Courses error! ${modules.error}` :
                                                //         (machines.error) ? `Machines error! ${machines.error}` :
                                                (ui.units === "courses" && filters.selectedCourse || ui.units === "modules" && filters.selectedModule || ui.units === "machines" && filters.selectedMachine) ?
                                                    <div className={classes.verticalProgressbarsContainer}>
                                                        {viewing.monthlyProgress !== undefined &&
                                                            <ListVerticalProgressbars
                                                                bars={convertArray(viewing.monthlyProgress)}/>
                                                        }
                                                    </div> :
                                                    <Center>Выберите {ui.units === "courses" ? "курс" : ui.units === "modules" ? "модуль" : "машину"}</Center>
                                        }
                                    </div>
                                </div>}
                            {
                                ((ui.units === "courses" && filters.selectedCourse || ui.units === "modules" && filters.selectedModule || ui.units === "machines" && filters.selectedMachine) && filters.selectedBar) &&
                                <div className="col h-100">
                                    {filters.showGreen && <div
                                        className="w-100 d-flex justify-content-center align-items-center flex-grow-2">
                                        <FontAwesomeIcon className={"me-2"} icon={faCalendarWeek}/>
                                        {formatSelectedDate(filters.selectedBar.date)}
                                    </div>}
                                    <div className="w-100 d-flex justify-content-center h-100 flex-grow-1 py-2 pb-4">
                                        <EmployeesList employees={!filters.showGreen && viewing.uncompletedEmployees}
                                                       showCustomEmployees={!filters.showGreen}/>
                                    </div>
                                </div>}</div>
                        : <div className={"w-100 h-100 d-flex justify-content-center align-items-center text-secondary"}
                               style={{opacity: opacity, transition: ".5s"}}>
                            {ui.mode === "employees" ?
                                <EmployeeRatingDetails/> :
                                <BranchRatingDetails/>}
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default BottomInfo;