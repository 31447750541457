import classes from "./Tables.module.css";
import {getPercent} from "../../../utils/helpers";
import {RatingTableRowBranch} from "./RatingTableRowBranch";

export const BranchesLeaderboardTable = ({branches}) => {
    return (
        <div className={classes.ratingTable}>
            <div className={classes.ratingTableHeader}>
                <div className={classes.ratingTablePlace}>Место</div>
                <div className={classes.ratingTableBranchName}>Филиал</div>
                <div className={classes.ratingTableLongTitle}>Сотрудники</div>
                <div className={classes.ratingTableValue}>Пройдено</div>
                <div className={classes.ratingTableValue}>Не пройдено</div>
                <div className={classes.ratingTablePercents}>Прогресс</div>
            </div>
            <div className={classes.ratingTableBody}>
                {branches.map((branch, index) => {
                    return <RatingTableRowBranch key={`${index}-RatingTableRowBranch-${branch.id}`} branch={branch} place={index + 1} delay={index * 20} width={getPercent(branch.value, branch.totalValue)}/>
                })}
            </div>
        </div>
    )
}