import {useContext} from "react";
import {UiContext} from "../../../context";
import classes from "./Lists.module.css";
import {RatingUnitEntry} from "../cards/RatingUnitEntry"

export const ListRatingEmployeeUnits = ({units, completed}) => {


    const {ui} = useContext(UiContext)

    return (
        <>
            <span className={"mb-2"}>
                {completed ? "Пройденные" : "Не пройденные"}
                {ui.units === "modules" ? " модули: " : ui.units === "courses" ? " курсы: " : " машины: "}
                <span className={"bold"}>{units.length}</span>
            </span>
            <div className={classes.listRatingUnitsContainer}>
                {units.map((unitEntry, index) => (
                    <RatingUnitEntry
                        key={`${index}-ratingUnitEntry-${unitEntry.id}`}
                        unitEntry={unitEntry}
                        delay={50 + index * 50}
                    />
                ))}
            </div>
        </>
    );
}