import classes from "./Tables.module.css"
import {useContext} from "react";
import {UiContext} from "../../../context";
import ModuleProgress from "./ModuleProgress";
import CourseProgress from "./CourseProgress";
import MachineCoefficientProgress from "./MachineCoefficientProgress";
import useDebounce from "../../../hooks/useDebounce";

export const ListTableRows = ({data}) => {

    const {ui, setUi} = useContext(UiContext)

    const setHoveredTd = (indexes) => {
        setUi(prev => ({...ui, hoveredTd: indexes}))
    }

    const debouncedHighlight = useDebounce(setHoveredTd, 200)

    const highlightTd = (indexes) => {
        debouncedHighlight(indexes)
    }

    return (
        data.map((tableRow, rowIndex) => {
            let sum
            if (ui.units === "courses") {
                sum = tableRow.completions.reduce((accumulator = 0, completion) => {
                    if (completion.completed === true) return accumulator + parseInt(completion.detail); else return accumulator
                }, 0);
            }
            return <tr className={classes.tr} key={`table-body-row-${rowIndex}`}>
                <td className={[classes.branchValue, classes.td].join(" ")} branchname={tableRow.branch} style={{background: rowIndex === ui.hoveredTd?.y ?  "var(--secondary-light)" :  "var(--dark-bg)"}}>{tableRow.branch}</td>
                <td className={[classes.nameValue, classes.td].join(" ")} employeeid={tableRow.employee} style={{background: rowIndex === ui.hoveredTd?.y ?  "var(--secondary-light)" : "var(--dark-bg)"}}>{tableRow.employee}</td>
                {tableRow.completions.map((completion, compIndex) => {
                    return <td
                        style={{background: ui.hoveredTd?.x === compIndex ? "#ffffff22" : "transparent", transition: ".12s"}}
                        key={`table-row-${rowIndex}-completion-${compIndex}`}
                        onMouseEnter={() => {
                            highlightTd({x: compIndex, y: rowIndex})
                        }}
                        onMouseLeave={() => {
                            setUi({...ui, hoveredTd: null})
                        }}
                    >
                        {ui.units === "modules"
                            ? <ModuleProgress shouldAnimate={rowIndex < 30 && compIndex < 15} completion={completion}/>
                            : ui.units === "courses"
                                ? <CourseProgress courseCompletion={completion}/>
                                : ui.units === "machines"
                                && <MachineCoefficientProgress progress={completion}/>}</td>
                })}
                {ui.units === "courses" && <td className={classes.td} style={{/*position: "sticky", right: 0*/}}><CourseProgress courseCompletion={{detail: sum, completed: sum !== 0}}/></td>}
            </tr>
        }))
}