import {gql} from '@apollo/client'

export const GET_ATTEMPTS = gql`
    query ($employeeNameFilter: String = null, $branches: [Int] = null, $lessTime: Int = null, $moreCount: Int = null, $moreTime: Int = null, $lessCount: Int = null, $limit: Int = null, $page: Int = null) {
    attempts (employeeNameFilter: $employeeNameFilter, branches: $branches, lessTime: $lessTime, moreCount: $moreCount, moreTime: $moreTime, lessCount: $lessCount, limit: $limit, page: $page) {
        totalCount,
        data {
            module {
                id,
                name
            },
            employee {
                id,
                firstname,
                lastname,
                branches {
                    name
                },
            },
            grade,
            completed,
            attempts {
                number,
                duration,
                grade,
                completed,
                startTime
            }
        }
    }
}
`