import classes from "./Cards.module.css"
import {ReactComponent as TrophySvg} from "../../../images/trophy.svg";
import React, {useEffect, useState} from "react";
import {pedestalColors} from "../../../utils/constants";

export const LeaderboardItem = ({place, delay, value, totalValue, title, item}) => {


    const colors = pedestalColors
    const heights = [0, 268, 208, 148,]
    const [height, setHeight] = useState(0);
    const [hovered, setHovered] = useState(false);

    const TrophySvg = () => (
        <svg width="40" height="40" viewBox="0 0 40 40" fill={colors[place]} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38 4H32V2C32 1.46957 31.7893 0.960859 31.4142 0.585786C31.0391 0.210714 30.5304 0 30 0H10C9.46957 0 8.96086 0.210714 8.58579 0.585786C8.21071 0.960859 8 1.46957 8 2V4H2C1.46957 4 0.960859 4.21071 0.585786 4.58579C0.210714 4.96086 0 5.46957 0 6V12C0 20.62 3.6 25.82 9.64 26C10.5213 27.5239 11.7271 28.8353 13.1718 29.8411C14.6166 30.8469 16.2649 31.5225 18 31.82V36H14V40H26V36H22V31.82C23.7351 31.5225 25.3834 30.8469 26.8282 29.8411C28.2729 28.8353 29.4787 27.5239 30.36 26C36.36 25.8 40 20.6 40 12V6C40 5.46957 39.7893 4.96086 39.4142 4.58579C39.0391 4.21071 38.5304 4 38 4ZM4 12V8H8V21.66C4.44 20.16 4 14.6 4 12ZM32 21.66V8H36V12C36 14.6 35.56 20.16 32 21.66Z"
                fill={colors[place]}/>
        </svg>
    );


    useEffect(() => {
        setTimeout(() => {
            setHeight(heights[place])
        }, delay)
    }, []);

    // style={{boxShadow: hovered ? `0px 0px 100px ${colors[place]}` : "none"}}

    return (
        <div className={classes.leaderboardContainer} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <div className={classes.pedestal}
                 style={{
                     borderColor: colors[place],
                     height: height,
                     zIndex: place,
                     boxShadow:
                         hovered ?
                             `0px 0px 100px ${`rgba(
                     ${parseInt(colors[place]?.substr(1, 2), 16)}, 
                     ${parseInt(colors[place]?.substr(3, 2), 16)}, 
                     ${parseInt(colors[place]?.substr(5, 2), 16)}, ${0.2})`}`
                             : "none"
                 }}>
                <div className={classes.trophyContainer} style={{opacity: height === 0 ? "0" : "1"}}>
                    <TrophySvg/>
                    <span>{place}</span>
                </div>
                <span className={classes.title} style={{opacity: height === 0 ? "0" : "1"}}>{title}</span>
                <span className={classes.value} style={{color: colors[place], opacity: height === 0 ? "0" : "1"}}>{value}/{totalValue}</span>
                {/*{children && children}*/}
                {item}
            </div>
        </div>
    )
}