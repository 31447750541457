import {createSlice} from '@reduxjs/toolkit'


export const machinesSlice = createSlice({
    name: 'units',
    initialState: {
        // producers: [],
        types: [],
        models: [],
    },
    reducers: {
        // set_producers: (state, action) => {
        //     state.producers = action.payload
        // },

        set_types: (state, action) => {
            state.types = action.payload
        },

        set_models: (state, action) => {
            state.models = action.payload
        }
    },
})

export const {set_types, set_models} = machinesSlice.actions

// export const selectProducers = (state) => state.units.producers
export const selectTypes = (state) => state.units.types
export const selectModels = (state) => state.units.models


export default machinesSlice.reducer
