import React, {useEffect, useRef, useState} from 'react';
import classes from "./DatePickers.module.css"
import {faCaretRight, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {capitalizeFirst} from "../../../utils/helpers";
import button from "bootstrap/js/src/button";

const monthNames = [
    "январь", "февраль", "март", "апрель", "май", "июнь",
    "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"
];
const date = new Date();
date.getMonth();
const changeMonth = (date, months) => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + months);
    return newDate;
};

const changeYear = (date, years) => {
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() + years);
    return newDate;
};
const daysOfWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];


const DatePicker = (props) => {

    const [viewingDate, setViewingDate] = useState(props.date === null ? new Date() : props.date);
    const [selectedDate, setSelectedDate] = useState(props.date);


    const [isMonthListVisible, setIsMonthListVisible] = useState(false);


    const handleMonthChange = (month) => {
        const newDate = new Date(viewingDate); // Создаем новый объект Date на основе текущей даты в состоянии
        newDate.setMonth(month); // Изменяем месяц в новом объекте Date
        setViewingDate(prev => newDate); // Обновляем состояние viewingDate новой датой
        setIsMonthListVisible(false)
    }
    const [monthTable, setMonthTable] = useState([])

    const createMonthTable = () => {
        let monthTable = [] //таблица на месяц, состоящая из массива недель
        const startDate = new Date(viewingDate.getFullYear(), viewingDate.getMonth(), 1); //первый день отображаемого месяца
        const endDate = new Date(viewingDate.getFullYear(), viewingDate.getMonth() + 1, 0); //последний день отображаемого месяца
        let date = startDate //итерируемая дата
        const emptyDate = {
            date: null,
            selected: false,
            onClick: () => {
            }
        }
        monthTable.push(Array.from({length: 7}, (_, i) => {
            if (i + 1 < startDate.getDay())
                return emptyDate;
            else {
                const tmpDate = date.setDate(date.getDate())
                const day = {
                    date: tmpDate,
                    selected: false,
                    onClick: () => setSelectedDate(prev => tmpDate)
                };
                date.setDate(date.getDate() + 1)
                return day;
            }
        }))
        const monthDuration = 30;
        // const monthDuration = getMonthDuration(startDate) //длительность месяца в днях
        while (monthTable.length * 7 <= monthDuration) {
            let week = []
            for (let i = 0; i < 7; i++) {
                const tmpDate = date.setDate(date.getDate())
                if (tmpDate <= endDate)
                    week.push({
                        date: tmpDate,
                        selected: false,
                        onClick: () => setSelectedDate(prev => tmpDate)
                    })
                else
                    week.push(emptyDate)
                date.setDate(date.getDate() + 1)
            }
            monthTable.push(week)
        }
        setMonthTable(monthTable)
    };


    useEffect(() => {
        createMonthTable()
    }, [viewingDate]);

    useEffect(() => {
        props.handleDateChange(selectedDate)
    }, [selectedDate]);


    const getClassName = day => {
        const styles = [classes.calendarDay]
        //если селектор даты является выбором промежутка
        if (props.dateType) {
            //если выбран текущий день
            if (day.date === selectedDate) styles.push(classes.selected)
            else if (props.subDate !== null) {
                //если селектор для начальной даты
                if (props.dateType === "start") {
                    if (day.date < props.subDate && day.date >= selectedDate) {
                        styles.push(classes.active)
                    } else if (day.date === props.subDate) styles.push(classes.subDay)
                }
                //если селектор для конечной даты
                else {
                    if (day.date > props.subDate && day.date <= selectedDate) {
                        styles.push(classes.active)
                    } else if (day.date === props.subDate) styles.push(classes.subDay)
                }
            }
        }
        //если селектор даты является выбором одного значения
        else {
            if (day.date === selectedDate) styles.push(classes.selected)
        }
        return styles.join(" ")
    };

    return (
        <div className={"w-100 rounded-pill m-2 p-3"} style={{background:' #00000022'}}>
            <div className={classes.selectorsContainer}> {/*Селектор месяца и года*/}
                <div className="row">{props.dateType === 'start' ? 'Дата начала' : 'Дата окончания'}</div>
                <div className={classes.yearSelectorContainer}> {/*Селектор года*/}
                    <button
                        className={classes.selectorButton}
                        onClick={() => {
                            const newDate = changeYear(viewingDate, -1); // изменить дату на 1 месяц вперед
                            setViewingDate(prev => newDate); // обновить состояние с новой датой
                        }}><FontAwesomeIcon icon={faCaretLeft}/></button>
                    <span className={classes.monthButton} style={{width: "100px", textAlign: "center"}}>{viewingDate.getFullYear()}</span>

                    <button
                        className={classes.selectorButton}
                        style={{opacity: viewingDate.getFullYear() >= new Date().getFullYear() ? .3 : 1}}
                        disabled={viewingDate.getFullYear() >= new Date().getFullYear()}
                        onClick={() => {
                            const newDate = changeYear(viewingDate, +1); // изменить дату на 1 месяц вперед
                            setViewingDate(prev => newDate); // обновить состояние с новой датой

                        }}><FontAwesomeIcon icon={faCaretRight}/></button>
                </div>
            </div>
            <div className={classes.monthSelectorContainer}> {/*Селектор месяца*/}
                <button
                    className={classes.selectorButton}
                    onClick={() => {
                        const newDate = changeMonth(viewingDate, -1); // изменить дату на 1 месяц вперед
                        setViewingDate(prev => newDate); // обновить состояние с новой датой
                    }}><FontAwesomeIcon icon={faCaretLeft}/></button>
                <button className={classes.monthButton} onClick={() => setIsMonthListVisible(true)}>
                    {capitalizeFirst(monthNames[viewingDate.getMonth()])}
                </button>
                <div
                    onMouseLeave={() => setIsMonthListVisible(false)}
                    className={isMonthListVisible ? [classes.monthList, classes.visible].join(" ") : classes.monthList}>
                    {monthNames.map((month, index) =>
                        <button
                            onClick={() => handleMonthChange(index)}
                            className={viewingDate.getMonth() === index ? [classes.monthListBtn, classes.active].join(" ") : classes.monthListBtn}
                            key={`month-button-${index}`}>
                            {(index + 1)}.&nbsp;{capitalizeFirst(month)}
                        </button>
                    )}
                </div>
                <button
                    className={classes.selectorButton}
                    style={{opacity: changeMonth(viewingDate, 1) < new Date() ? 1 : .3}}
                    onClick={() => {
                        const newDate = changeMonth(viewingDate, 1);// изменить дату на 1 месяц вперед
                        if (newDate < new Date()) setViewingDate(newDate); // обновить состояние с новой датой
                    }}>
                    <FontAwesomeIcon icon={faCaretRight}/>
                </button>
            </div>
            {/*Таблица с датами*/}
            <div className={classes.datePickerTableContainer}>
                <table className={classes.datePickerTable}>
                    <thead>
                    <tr>
                        {daysOfWeek.map(day => <td key={`weekday-${day}`}
                                                   className={classes.calendarDayCell}>{day}</td>)}
                    </tr>
                    </thead>
                    <tbody>{monthTable.map((week, index) =>
                        <tr key={`week-${index}`}
                            className={classes.week}>
                            {week.map((day, idx) => {
                                if (day.date !== null) {
                                    const timestamp = day.date
                                    const date = new Date(timestamp);
                                    return <td
                                        key={`day-${idx}`}
                                        className={classes.calendarDayCell} onClick={day.onClick}>
                                        <div className={getClassName(day)}>
                                            <span>
                                                {date.getDate()}
                                            </span>
                                        </div>
                                    </td>
                                } else return <td key={`day-${idx}`}></td>
                            })}
                        </tr>
                    )}</tbody>
                </table>
            </div>

        </div>
    );
};

export default DatePicker;