import {createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {ACCESS_TOKEN_KEY} from "../features/_helpers_/constants";
import {checkToken} from "../features/_helpers_/commands";
import env from "react-dotenv";


export const httpLink = createHttpLink({
    uri: env.API_URL + '/graphql/',
});

let isTokenRefreshing = false;
const refreshQueue = [];

async function refreshPromise() {
  return new Promise(async (resolve, reject) => {
    if (isTokenRefreshing) {
      refreshQueue.push({ resolve, reject });
    } else {
      isTokenRefreshing = true;
      try {
        await checkToken();
        resolve();
      } catch (error) {
        reject(error);
      } finally {
        isTokenRefreshing = false;
        const nextRequest = refreshQueue.shift();
        if (nextRequest) {
          refreshPromise().then(nextRequest.resolve).catch(nextRequest.reject);
        }
      }
    }
  });
}

export const authLink = setContext(async (_, { headers }) => {
  await refreshPromise();

  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});






