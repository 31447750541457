import React, {useEffect, useState} from 'react';
import classes from "./Select.module.css"
import '../../../../src/Fonts.css';
import Loader from "../loader/Loader";

import {faCheck, faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTypewriter} from "../../../hooks/useTypewritter";
import {Center} from "../Center";

const ListSelect = (props) => {

    const [selectedItems, setSelectedItems] = useState([])
    const [searchedItems, setSearchedItems] = useState([])
    const [searchQuery, setSearchQuery] = useState("")

    const typingText = useTypewriter(props.searchPlaceholderText, 200);

    const searchItems = (query) => {
        setSearchedItems(props.items?.filter(item => item.name.toLowerCase().includes(query.toLowerCase())))
    }

    const selectItem = item => {
        if (selectedItems.find(it => it.id === item.id)) {
            const temp = selectedItems.filter(i => i.id !== item.id)
            setSelectedItems(prev => temp);
            props.onItemsChange(temp);
        } else {
            const temp = [...selectedItems, item]
            setSelectedItems(prev => temp);
            props.onItemsChange(temp);
        }
    };

    useEffect(() => {
        searchItems(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        props.onItemsChange(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        setSearchedItems(prev => [...props.items].sort((a, b) => a.name.localeCompare(b.name)))
    }, [props.items]);

    if (props.items === null || props.items === undefined) return <Center><Loader/></Center>

    return (
        <div className={classes.listSelect__wrapper}>
            <div className={classes.listSelectSearch__container}>
                <input
                    onChange={(e) =>
                        setSearchQuery(e.target.value)
                    }
                    className={classes.listSelect__input}
                    type="text"
                    placeholder={typingText}
                    value={searchQuery}
                />
                <button className={classes.listSelect__inputClearBtn} onClick={() => setSearchQuery("")} style={{
                    opacity: searchQuery.length === 0 ? "0" : "1",
                    pointerEvents: searchQuery.length === 0 ? "none" : "all"
                }}><FontAwesomeIcon icon={faClose}/></button>
            </div>
            <div className={classes.listSelectItems__container}>
                {
                    searchedItems.length === 0 ? <div
                            className={"h-100 w-100 d-flex align-items-center justify-content-center h6 m-0 p-0 text-secondary"}>Ничего
                            не найдено</div> :
                        searchedItems.map(item =>
                            <div
                                key={item.id + "-" + item.name}
                                className={classes.listSelectItem}
                                onClick={() => selectItem(item)}
                                style={{
                                    backgroundColor: selectedItems.find(it => it.id === item.id) ? "#FDAE47" : "#505760",
                                    color: selectedItems.find(it => it.id === item.id) ? "#2A2F35" : "#FFFFFF",
                                }}
                            >
                                <div>
                                    {selectedItems.find(it => it.id === item.id) ?
                                        <FontAwesomeIcon icon={faCheck} className={"me-2"}/> : ""}
                                </div>
                                {item.name}
                            </div>
                        )}
            </div>
            <button
                style={{opacity: selectedItems.length === 0 ? "0" : "1", transition: ".3s"}}
                disabled={selectedItems.length === 0}
                onClick={() => {
                    setSelectedItems(prev => [])
                    setSearchQuery(prev => "")
                    props.onItemsChange([])
                }}
                className={classes.listSelectResetBtn}>
                Сбросить ({selectedItems.length})
            </button>

        </div>
    );
};

export default ListSelect;