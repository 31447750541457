import React, {memo, useContext, useEffect, useState} from 'react';
import classes from "./Tables.module.css"
import Loader from "../loader/Loader";
import {levenshteinDistance} from "../../../utils/helpers";
import {LabeledSwitch} from "../switch/LabeledSwitch";
import {FilterContext, UiContext} from "../../../context";
import {useQuery} from "@apollo/client";
import {CORE_GET_MACHINES_TABLE_CONTENT} from "../../../graphql/machines";
import {MachinesModal} from "../modal/MachinesModal";
import {CORE_GET_COURSES_TABLE_CONTENT} from "../../../graphql/courses";
import {CORE_GET_MODULES_TABLE_CONTENT} from "../../../graphql/modules";
import {ListTableRows} from "./ListTableRows";
import {UnitsTableHeader} from "./UnitsTableHeader";
import {Button, Modal, Space} from "antd";

const SupervisorTable = ({data}) => {
    const {ui} = useContext(UiContext)
    const {filters, setFilters} = useContext(FilterContext);
    const [filtersState, setFiltersState] = useState(filters);
    // модальное окно
    const [modalActive, setModalActive] = useState(false);


    const searchBranches = (query) => {
        document.querySelectorAll(`[branchname]`).forEach(element => {
            if (element.textContent.toLowerCase().includes(query.toLowerCase()) || levenshteinDistance(element.textContent.toLowerCase(), query.toLowerCase()) < 3) {
                element.parentElement.style.opacity = 1;
                setTimeout(() => {
                    element.parentElement.style.display = "table-row";
                }, 300)
            } else {
                element.parentElement.style.opacity = 0;
                setTimeout(() => {
                    element.parentElement.style.display = "none";
                }, 300)
            }
        });
    }

    const searchEmployees = (query) => {
        document.querySelectorAll(`[employeeId]`).forEach(element => {
            if (element.textContent.toLowerCase().includes(query.toLowerCase()) || levenshteinDistance(element.textContent.toLowerCase(), query.toLowerCase()) < 3) {
                element.parentElement.style.opacity = 1;
                setTimeout(() => {
                    element.parentElement.style.display = "table-row";
                }, 300)
            } else {
                element.parentElement.style.opacity = 0;
                setTimeout(() => {
                    element.parentElement.style.display = "none";
                }, 300)
            }
        });
    }

    const isCategoryEnabled = (category) => {
        return filtersState.selectedCategories.map(sc => sc.id).includes(parseInt(category.id))
    }

    const toggleCategory = (category) => {
        if (isCategoryEnabled(category)) {
            setFiltersState(prevState => ({...prevState, selectedCategories: filtersState.selectedCategories.filter(c => c.id !== parseInt(category.id))}))
        } else {
            setFiltersState(prevState => ({...prevState, selectedCategories: [...filtersState.selectedCategories, category]}))
        }
    }

    //тело таблицы с курсами
    const coursesTableBody = useQuery(CORE_GET_COURSES_TABLE_CONTENT, {
        variables: {
            categories: filters.selectedCategories && filters.selectedCategories.map(sc => sc.id),
        }, fetchPolicy: 'network-only',
    })

    //тело таблицы с модулями
    const modulesTableBody = useQuery(CORE_GET_MODULES_TABLE_CONTENT, {
        variables: {
            categories: filters.selectedCategories && filters.selectedCategories.map(sc => sc.id),
        }, fetchPolicy: 'network-only',
    })

    //тело таблицы с машинами
    const machinesTableBody = useQuery(CORE_GET_MACHINES_TABLE_CONTENT, {
        variables: {
            types: filters.selectedTypes?.map(type => parseInt(type.id)),
        }, fetchPolicy: 'network-only',
    });

    return (<div className={classes.tableFixHead}>
        <table className={classes.table}>

            <UnitsTableHeader
                onModalActiveChange={setModalActive}
                onEmployeeSearch={searchEmployees}
                onBranchSearch={searchBranches}
            />

            <tbody>
            { /*Тело таблицы*/
                ui.units === "machines"
                    ? machinesTableBody.loading
                        ? <tr>
                            <td><Loader/></td>
                        </tr>
                        : <ListTableRows data={machinesTableBody.data.machinesTable}/>
                    : ui.units === "modules"
                        ? modulesTableBody.loading
                            ? <tr>
                                <td><Loader/></td>
                            </tr>
                            : <ListTableRows data={modulesTableBody.data.modulesTable}/>
                        :
                        ui.units === "courses"
                        && coursesTableBody.loading
                            ? <tr>
                                <td><Loader/></td>
                            </tr>
                            : <ListTableRows data={coursesTableBody.data.coursesTable}/>
            }
            </tbody>
        </table>


        {/*Модалки*/
            ui.units === "machines" ?
                <MachinesModal
                    modalActive={modalActive}
                    onChange={() => {}}
                    setModalActive={setModalActive}
                    onConfirm={(val) => {
                        setFilters(prev => val)
                        setModalActive(false)
                    }}
                />
                :
                <Modal
                    footer={
                        <Space justify={"center"}>
                            <Button block ghost type={"dashed"} onClick={() => setFiltersState({...filtersState, selectedCategories: data.units.categories})}>Выделить все</Button>
                            <Button block type={"default"} ghost onClick={() => setFiltersState({...filtersState, selectedCategories: []})}>Снять выделение</Button>
                            <Button block type={"primary"} onClick={() => {
                                setFilters(prev => filtersState)
                                setModalActive(false)
                                }}>
                                Применить
                            </Button></Space>
                    }
                    open={modalActive}
                    onCancel={() => setModalActive(false)}
                    title={<span className={"h4"}>{`Выбранные категории: ${filtersState.selectedCategories.length}`}</span>}
                >
                    <div>
                        {data.units.categories.map(category => <div key={`modal-category-${category.id}`} className="col-12 p-2">
                            <LabeledSwitch title={category.name} isChecked={isCategoryEnabled(category)}
                                           onChange={() => toggleCategory(category)}/>
                        </div>)}
                    </div>
                </Modal>

        }


    </div>);
};

export default memo(SupervisorTable);